import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropertyImages from "../../dashboard/propperty-datails/component/PropertyImages/PropertyImages";
import Mapview from "../../PropertyDetails/componants/map-view/Mapview";
import "./virtualOfficePropertyModal.scss";
import { AiFillCar, AiFillDislike, AiFillLike } from "react-icons/ai";
import { IoIosCar } from "react-icons/io";
import KeyFeature from "../../dashboard/propperty-datails/component/key-feature/KeyFeature";
import MortgageCalculaterPop from "../../PropertyDetails/componants/MortgageCalculaterPop";
import AddNoteAndPropertyLikeDislike from "./AddNoteAndPropertyLikeDislike";
import TravelCard from "../../PropertyDetails/componants/TravelCard";
import { updatePropertyDatail } from "../../dashboard/propperty-datails/PropertySlice";
import { useDispatch } from "react-redux";

const VirtualOfficePropertyModal = (props) => {
  const dispatch = useDispatch();
  const hidepopup = () => {
    dispatch(
      updatePropertyDatail({
        setshowPropertyPopup: false,
        setSavePropertyPopup: false,
        propertyId: "",
        PropertyDetailInfo: {},
      })
    );
    props.setShowProperty(false);
  };
  return (
    <>
      <Modal
        // dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={props.ShowProperty}
        onHide={() => hidepopup()}
      >
        <Modal.Header closeButton>
          <p className="proprty-head">Property Details</p>
        </Modal.Header>
        <Modal.Body className="modal-body-property-detail">
          <PropertyImages
            propertDetails={props.propertyDetailInfo}
            setPropertyDetailInfo={{}}
          />
          {props.propertyDetailInfo?.propertDetails?.latitude &&
            props.propertyDetailInfo?.propertDetails?.longitude && (
              <Mapview
                // if property is open listing then dont show loaction icon
                marker={
                  props?.propertyDetailInfo.propertDetails?.is_property_open
                }
                propertyData={props?.propertyDetailInfo}
                latitude={props.propertyDetailInfo?.propertDetails?.latitude}
                longitude={props.propertyDetailInfo?.propertDetails?.longitude}
              />
            )}
          {console.log("props", props)}
          <AddNoteAndPropertyLikeDislike info={props} />
          <TravelCard />
          <KeyFeature
            propertDetails={props.propertyDetailInfo}
            showLocationButton={true}
          />
          {/* for commertial peroperty */}
          {props.propertyDetailInfo?.propertDetails?.propertylisting_type
            ?.user_listing_type === "Buy" &&
            props.propertyDetailInfo?.propertDetails?.property_listing_type
              ?.property_listing_name === "Commercial" && (
              <MortgageCalculaterPop
                propertDetails={props.propertyDetailInfo}
              />
            )}
          {/* for residential peroperty */}
          {props.propertyDetailInfo?.propertDetails?.propertylisting_type
            ?.user_listing_type === "Buy" &&
            props.propertyDetailInfo?.propertDetails?.property_listing_type
              ?.property_listing_name === "Residential" && (
              <MortgageCalculaterPop
                propertDetails={props.propertyDetailInfo}
              />
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VirtualOfficePropertyModal;
