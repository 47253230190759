import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import Stunning from '../../ImgAdv/Stunning.png'
import { Button } from 'react-bootstrap'
import "./Adv_StunningDesign.scss"


const Adv_StunningDesign = (props) => {
  const { title, description, buttonTitle } = props
  return (
    <>
      <section className="mt-4 stuning_content" style={{ marginRight: "4%" }} >
        <h3 className="stu-Lorem">
          Lorem Ipsum is simply dummy text of
          the printing and typesetting industry. Lore
        </h3>
        <div className='Adv_dummy_content'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 stu_text-content'>
              <h2 className='fw-bold'>{title}</h2>
              <p className="mt-5 ">{description}</p>
              <Button size="lg" className="mt-5 app-white-btn">{buttonTitle}<FaLongArrowAltRight className='mx-3' /></Button>
            </div>
            <img src={Stunning} className='my-5 dummy_img ' alt="#"  />
          </div>
        </div>
      </section>
    </>
  )
}

export default Adv_StunningDesign
