import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

const CommertialFilter = () => {
    const [bedrooms, setBedrooms] = useState(2)
    const [bathrooms, setBathrooms] = useState(3)
    return (
        <>
            <div className=" d-flex flex-column filter-body" style={{ width: '200px' }}>
                <div className="filter-by filter-form-row">
                    <p className='filter-heading'>Filter By :</p>
                </div>

                <div className="filter-form d-flex flex-column">
                    <div className="type filter-form-row">
                        <p className='small-heading'>Fee Type</p>
                        <Form.Select aria-label="Default select example">
                            <option>No Fee</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </div>

                    <div className="aminity-type filter-form-row  ">
                        <p className='small-heading'>Type</p>
                        <div className="d-flex justify-content-between">
                            <div className="checkbox-column d-flex flex-column">

                                <div className='aminity-radio'>
                                    <input type="checkbox" id="Office" name="aminity" value="Office" />
                                    <label htmlFor="Office" className='aminity-radio-label'>Office</label>
                                </div>

                                <div className='aminity-radio'>
                                    <input type="checkbox" id="Mixed" name="aminity" value="Mixed" />
                                    <label htmlFor="Mixed" className='aminity-radio-label'>Mixed Use</label>
                                </div>
                                <div className='aminity-radio'>
                                    <input type="checkbox" id="Industrial" name="aminity" value="Industrial" />
                                    <label htmlFor="Industrial" className='aminity-radio-label'>Industrial</label>
                                </div>
                            </div>

                            <div className="checkbox-column d-flex flex-column  ">

                                <div className='aminity-radio'>
                                    <input type="checkbox" id="Retail" name="aminity" value="Retail" />
                                    <label htmlFor="Retail" className='aminity-radio-label'>Retail</label>
                                </div>

                                <div className='aminity-radio'>
                                    <input type="checkbox" id="Leisure" name="aminity" value="Leisure" />
                                    <label htmlFor="Leisure" className='aminity-radio-label'>Leisure</label>
                                </div>

                                <div className='aminity-radio'>
                                    <input type="checkbox" id="Specialty" name="aminity" value="Specialty" />
                                    <label htmlFor="Specialty" className='aminity-radio-label'>Specialty</label>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="type filter-form-row">
                        <Form.Select aria-label="Default select example">
                            <option className=' d-none'> </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </div>

                    <div className="bedroom-bathroom">

                        <div className=' small-in-box-div'>

                            <div className='filter-form-row '>
                                <p className="small-heading">Bedrooms</p>
                                <div className="beadroom small-in-box d-flex justify-content-around">
                                    <p onClick={() => setBedrooms(bedrooms + 1)} className='pointer'>+</p>
                                    <p>{bedrooms}</p>
                                    <p onClick={() => setBedrooms(bedrooms - 1)} className='pointer'>-</p>
                                </div>
                            </div>
                            <div className='filter-form-row'>
                                <p className="small-heading">Bathrooms</p>
                                <div className="beadroom small-in-box d-flex justify-content-around">
                                    <p onClick={() => setBathrooms(bathrooms + 1)} className='pointer'>+</p>
                                    <p>{bathrooms}</p>
                                    <p onClick={() => setBathrooms(bathrooms - 1)} className='pointer'>-</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sqft-price filter-form-row">
                        <p className="small-heading">Sqft</p>
                        <div className=' d-flex small-in-box-div'>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Sqft Min' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Sqft Max' />
                            </div>
                        </div>
                    </div>
                    <div className="sqft-price  filter-form-row">
                        <p className="small-heading">Price</p>
                        <div className="d-flex gap-2">
                            <div className='aminity-radio'>
                                <input type="radio" id="monthcost" name="cost" value="monthcost" />
                                <label htmlFor="monthcost" className='cost-radio-label'>Total Monthly Cost</label>
                            </div>

                            <div className='aminity-radio'>
                                <input type="radio" id="costper" name="cost" value="costper" />
                                <label htmlFor="costper" className='cost-radio-label'>Cost Per Sqft</label>
                            </div>
                        </div>

                        <div className=' d-flex small-in-box-div'>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Price Min' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Price Max' />
                            </div>
                        </div>
                    </div>

                    <div className="amenity-filter filter-form-row">
                        <div className=' d-flex small-in-box-div lite-text  flex-wrap justify-content-between gap-2'>
                            <div className="input-sec small-in-box ">
                                <input type="text" placeholder='Block' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='lot' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Zone' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Lot Dimensions' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Building Dimensions' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Stories' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='FAR' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Assessment' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Annual Taxes' />
                            </div>
                            <div className="input-sec small-in-box">
                                <input type="text" placeholder='Available Air Rights' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CommertialFilter
