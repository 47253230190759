import { Outlet } from "react-router-dom";
import Topbar from "../dashboard/components/topbar/Topbar";
import {  useState } from "react";
import icons from "../dashboard/components/sidebar/images/SidebarIcon";
import OfficeSidebar from "./OfficeSidebar";

const OfficeDashboard = () => {
    const [sidebar, setsidebar] = useState(true);
    const [theme, settheme] = useState(true);

    const sidebardata = [
        // { icon: icons.virtualOffice, link: 'home', title: 'VIRTUAL OFFICE', },
        { icon: icons.Dashboard, link: 'dashboard', title: 'Dashboard', },
        { icon: icons.Contact, link: 'contact', title: 'Contact', },
        { icon: icons.CustomerInfo, link: 'customer-info', title: 'Customer Info', },
        { icon: icons.documents, link: 'document', title: 'Document', },
        { icon: icons.message, link: 'message', title: 'My Message', },
        { icon: icons.like, link: 'interested-property', title: 'Interested', },
        { icon: icons.dislike, link: 'dislike-property', title: 'Don’t like', },
    ]

    return (
        <>
            <div className="main-dashboard">
                <div className={`dashboard-inner ${theme ? "white-theme" : "blue-theme"}`}>
                    <Topbar
                        sidebar={sidebar}
                        setsidebar={setsidebar}
                        theme={theme}
                        settheme={settheme}
                    />
                    <div className="dashboard-middle d-flex h-100">
                        <OfficeSidebar sidebar={sidebar} sidebardata={sidebardata} />
                        {/* <Sidebar sidebar={sidebar} sidebardata={sidebardata} pointerEvent={true} /> */}
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default OfficeDashboard
