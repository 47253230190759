import React from 'react'

const NotFound = () => {
    return (
        <div className=' center gap-2 flex-column  position-absolute top-0 start-0 w-100 h-100' style={{ background: 'white' }}>
            <h1><strong>404 page Not Found</strong></h1>
            <a href='/' >Go to Home page</a>
        </div>
    )
}

export default NotFound
