import React from 'react'
import search from "../../images/search.svg"
import filter from "../../images/filter.svg"
import { Form } from 'react-bootstrap'
const FilterTop = () => {
    return (
        <>
            <div className="main-sec flex-grow-1">
                <div className="filter-sec  d-flex justify-content-between">
                    {/* chech box */}
                    <div className="buy-rent gap-3 d-flex align-items-center">
                        <div className="d-flex">
                            <input type="checkbox" name="PropType" className="Prop" id="Buy" value="Buy" />
                            <label htmlFor="Buy" className="pointer">
                                Buy
                            </label>
                        </div>
                        <div className="d-flex">
                            <input type="checkbox" name="PropType" className="Prop" id="Rent" value="Rent" />
                            <label htmlFor="Rent" className="pointer">
                                Rent
                            </label>
                        </div>
                    </div>

                    {/* Search box */}
                    <div className="search-box d-flex flex-grow-1">
                        <div className="search d-flex align-items-center justify-content-between flex-grow-1">
                            <input type="text" className="trans-input" placeholder="Search Town or City" name="" id="" />
                            <div className="search-icon pointer">
                                <img src={search} alt="" />
                            </div>
                        </div>
                        <div className="filter-icon pointer d-flex align-items-center justify-content-center">
                            <img src={filter} alt="" />
                        </div>
                    </div>

                    {/* quik-view map-view */}
                    <div className="quik-map center gap-3">
                        <p>Quick View</p>
                        <Form.Check className="pointer" type="switch" id="custom-switch" />
                        <p>Map View</p>
                    </div>
                </div>

                
            </div>
        </>
    )
}

export default FilterTop
