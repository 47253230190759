import React from 'react'

const InputBar = (props) => {
    const { label, type, name, onChange, value } = props
    return (
        <>
            <div className="custume-inputbar d-flex align-items-center">
                <div className="input-label d-flex pe-2 justify-content-end ">
                    <p className=' text-capitalize'>{label}</p>
                </div>
                <div className=" ps-1 flex-grow-1">
                    <input type={type} className='w-100' id=""
                        name={name}
                        onChange={onChange}
                        value={value}
                    />
                </div>
            </div>
        </>
    )
}

export default InputBar
