import React, { useEffect, useState } from 'react'
import { getToken } from '../../../../../../../appVariable/variable';
import { MonthStarWatch } from '../../../../server';

const Monthly = () => {
    let token = getToken()
    let userId = token?.userId;
    const [monthStartWatch, setMonthStartWatch] = useState([{}])

    useEffect(() => {
        let watchobj = {
            "user_id": userId,
            "date": new Date().toJSON().slice(0, 10)
        }
        MonthStarWatch(watchobj, (success) => {
            if (success.data.code === 200 && success.data.status === "success") {
                setMonthStartWatch(success.data.data)
            }
        });
    }, [])
    return (
        <>
            {Object.keys(monthStartWatch[0]).length !== 0?
                <div div className='weekly_tab_section'>
                    <div className='daily_tab_section w-100'>
                        <div className='row_heading'>
                            <div className="col_width"><p>Month</p></div>
                            <div className="col_width"><p className=' text-center'>Total Number </p> </div>
                        </div>
                        <div className="scrolls_stopwatch">
                            <div className='WEElks_outer_border'>
                                {monthStartWatch?.map((watch, index) =>
                                    <div className='row_headings pad_top_bottom' key={`month${index}`}>
                                        <div className="col_width"><p>{watch.month}</p></div>
                                        <div className="col_width text-center"><p>{watch.count}</p></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <p className='data-not-found'>No Data Found</p>

            }
        </>
    )
}

export default Monthly
