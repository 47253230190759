import React, { useState } from 'react'
import "./plancard.scss"
import yes from "./image/yes.svg"
import no from "./image/no.svg"
// import { handleClickScroll } from '../table/FeatureTable'
// import { selectedplan } from '../../server'
// import { getToken } from '../../../../../appVariable/variable'

// export const features = [
//     { title: '300 ACTIVE LISTINGS', available: true },
//     { title: 'RESIDENTIAL LISTINGS SALES & RENTALS OPEN & EXCLUSIVE LISTINGS', available: false },
//     { title: 'COMMERCIAL LISTINGS SALES & LEASING OPEN & EXCLUSIVE LISTINGS', available: true },
//     { title: 'BOOST MARKETING', available: false },
//     { title: 'PROFILE PROMOTED RECOGNIZED AS SPECIALIST 1-6 SPECIFIC TOWNS', available: true },
//     { title: 'FSBHO & PRIVATE RENTER LEADS PROVIDED', available: false },
//     { title: 'PROFILE PROMOTED TO PROPERTY MANAGERS & LANDLORDS', available: true },
//     { title: 'PROFILE PROMOTED TO PROPERTY INVESTORS & DEVELOPERS', available: false },
//     { title: '30 MINUTE SHOWINGS ASSIGN LISTINGS FOR IMMEDIATE SHOWING CUSTOMERS EXPECTING SHOWING WITHIN 30 -45 MINUTES', available: true },
//     { title: 'PERSONAL WEBPAGE - BIO PAGE', available: false },
//     { title: 'STAT-WATCH MONITORING # OF LISTINGS DAILY/ WEEKLY /MONTHLY /QUARTERLY', available: true },
//     { title: "PERSONALIZED URL'S", available: false },
//     { title: 'TEAMS', available: true },
//     { title: 'CUSTOMIZE TEAM MEMBERS & SPECIFIC LISTINGS', available: false },
//     { title: 'SUPPORT TICKETS PERSONAL HELP WITH ISSUES ON SITE YOU NEED ADDRESSED', available: true },
//     { title: 'Reposting one, some or all listings with one click. Save Time Dates are adjusted to current date and if there is an end date it is adjusted forward for the same amount of time on original listing.', available: false },
//     { title: 'DOCUMENT SHARING SHARE YOUR DISCLOSURES AGREEMENTS FAIR HOUSING AGREEMENTS BUYER OR SELLER CONTRACTS TO REMAIN COMPLIANT AND NOT MISS ANY OPPORTUNITY TO TURN CUSTOMER INTO A CLIENT', available: true },
//     { title: 'Syndication Company listings or personal listings can be syndicated to your account without having to re-post listings', available: false },
//     { title: 'Analytic Information View Count /Contact Click though rate – engagements Heat map of listings – are ppl looking or skipping listings same for pictures of listing', available: false },
// ]

const Smallcard = (props) => {
    // let token = getToken()
    // let userId = token?.userId;
    const [showfeature, setShowfeature] = useState(false);
    const { //description,  bottomBtn, planchoices,
        setPlanid, data } = props
    // selectedplan(plandetail, success)



    return (
        <>
            <div className='center flex-column'>
                <div className="Plan-Card small-card d-flex align-items-center pointer" onClick={() => { props.setAddonplan(true); setPlanid(data.id) }}>
                    <p className="card-title">{data?.heading}</p>
                    <span className='card-description'
                        style={{ marginTop: (data?.heading)?.length > 15 ? "9px" : "32px" }}
                    >{data?.title}</span>
                    <p className="crad-price mt-3" >${data?.price}</p>
                    {/* Showing card features  */}
                    <div className="d-flex flex-column" style={{ gap: '15px', marginTop: '28px' }}>

                        {/* showing some features */}
                        {(data?.services?.slice(0, 3))?.map((item, index) =>
                            <div className="d-flex" style={{ gap: '17px' }} key={index} >
                                <img className="featureIcon" src={item?.status ? yes : no} alt="" srcSet="" />
                                <p className="features" style={{ color: item?.status ? null : '#8C98CA' }}>{item?.lable}</p>
                            </div>
                        )}

                        {/* showing All features */}
                        {showfeature && (data?.services?.slice(3))?.map((item, index) =>
                            <div className="d-flex" style={{ gap: '17px' }} key={index} >
                                <img className="featureIcon" src={item?.status ? yes : no} alt="" srcSet="" />
                                <p className="features" style={{ color: item?.status ? null : '#8C98CA' }}>{item?.lable}</p>
                            </div>
                        )}
                    </div>

                    {/* see all plan btn */}
                    <a href='#PlanTable' className="d-none d-lg-flex center see-all-btn "
                        onClick={(e) => { e.stopPropagation(); }}><p>SEE ALL FEATURES</p></a>

                    <div className="d-flex d-lg-none center see-all-btn" >
                        <p onClick={() => setShowfeature(!showfeature)}> {showfeature ? "HIDE" : "SEE"} ALL FEATURES</p>
                    </div>
                </div>
                {props.bottomBtn ?
                    <div className="center card-bottom-btn"
                        onClick={() => { props.setAddonplan(true); setPlanid(data.id) }}
                    ><p >Choose Plan</p></div> : null}

            </div>
        </>
    )
}

export default Smallcard
