import React, { useEffect, useState } from 'react'
import TopSec from '../sms-marketing/top-sec/TopSec'
import { Button } from '@mui/material'
import { Hosturl, getToken } from '../../../../../appVariable/variable'
import { AgentMarketingUrl } from '../../server'

const PaidMediaForm = () => {
    let token = getToken()
    let agentSlug = token?.profile.slug;
    const [successsuccess, setMarketingUrls] = useState([])


    useEffect(() => {
        AgentMarketingUrl(success => { setMarketingUrls(success) })
    }, [])

    return (
        <div className="marketing-page d-flex flex-column flex-grow-1 hide-scrollbar gap-4" >
            <TopSec heading={`PAID MEDIA GOOGLE ADWORD`} title={`Per Unit`}
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum . Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only ."
            />
            {/* <SmallMarketingForm /> */}

            <div className='marketing-top-sec d-flex flex-column'>
                <div className='sms_marketing_form'>
                    <div className='card_border pad_cards d-flex flex-column gap-4'>
                        <h6 className='mb-1'>Campaign Name</h6>

                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Enter Campaign Name</p></div>
                            <div className='text-input flex-grow-1'>
                                <input type="text" className=' w-100' />
                            </div>
                        </div>
                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Maximum Of 5 Social Media Account</p></div>
                            <div className='text-input flex-grow-1'>
                                <input type="text" className=' w-100' />
                            </div>
                        </div>

                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Select a Listing Or Bio Page<span className="red-star" >*</span></p></div>
                            <div className='text-input flex-grow-1'>
                                <select aria-label="Default select example" className=" ps-2 w-100"
                                onChange={(e) => { console.log("bioPage", `${Hosturl}${e.target.value}`); }}
                                // onChange={(e) => { formik.setFieldValue("bioPage", `${Hosturl}${e.target.value}`); }}
                                >
                                    <option className=" d-none">Select Page</option>
                                    <option className="" value={`agentprofile/${agentSlug}`}>My Public Website</option>
                                    {successsuccess?.map((url) =>
                                        <option key={`offers${url.id}`} value={`property/${url.slug}`}
                                        >{url.slug}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Enter Keywords</p></div>
                            <div className='text-input flex-grow-1'>
                                <input type="text" className=' w-100' />
                            </div>
                        </div>

                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Message</p></div>
                            <div className='text-input flex-grow-1'>
                                <textarea className=' w-100' name="" id="" placeholder='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur amet, illum qui delectus harum dolore ex dignissimos fugiat. Quis neque corporis beatae possimus dolore itaque?'></textarea>
                            </div>
                        </div>
                        <div className=" d-flex justify-content-end marketing-form-btn">
                            <Button variant="primary" className='center'>Start Now</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaidMediaForm
