import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import CancelIcon from '@mui/icons-material/Cancel';
import "./Fsbho_card.scss"

const Fsbho_card = ({ subscriptionInfo, monthly, allDetails }) => {
    return (
        <div className='sub_card'>
            <Card className="sub_p_card" >
                <Card.Body className='pt-4 px-3 plan_card justify-content-center text-center align-item-center '>
                    <Card.Title className='sub_heading pb-3 px-5'>{subscriptionInfo?.heading}</Card.Title>
                    <Card.Text className='text-dark subscrib pb-3 ' >{subscriptionInfo?.title}</Card.Text>
                    <Card.Title className='text-dark plan_rate pb-4'>${monthly ? subscriptionInfo?.monthly_price : subscriptionInfo?.yearly_price}</Card.Title>

                    {/* showing some features */}
                    {(allDetails?.slice(0, 4))?.map((item, index) =>
                        <div className='justify-content-start d-flex text-start pb-3' key={`plan${index}`}>
                            <div className='me-2'>
                                {item?.is_active ?
                                    <IoIosCheckmarkCircle style={{ fontSize: "16px", color: 'green' }} /> :
                                    <CancelIcon style={{ fontSize: "16px", color: 'red' }} />
                                }
                            </div>
                            <p>{item.service_name}</p>
                            {/* <p className="features" style={{ color: item?.status ? null : '#8C98CA' }}>{item?.lable}</p> */}
                        </div>
                    )}
                </Card.Body>
                <Button variant="" size="lg" className='sub_btn'>Choose Plan</Button>

            </Card>
        </div>
    )
}

export default Fsbho_card
