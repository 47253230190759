import React from 'react'
import "./Adv_Lead_Page.scss"
const Adv_Lead_Page = () => {
  return (
    <div className='main_lead_container'>
      <table class="table" >
        <thead>
          <tr>
          <th className='ps-3'>First Name</th>
          <th>Last Name</th>
          <th>Phone Number</th>
          <th className=''>Email Address</th>
          <th className=''>Note</th>
          </tr>
        </thead>
      </table>

      <div class="lead_table_container" style={{ color: "#585656" }}>
        <table class="table" >
          <thead >  
            <tr >
              <th>Payment Data</th>
              <th>Campaign Name</th>
              <th>Total Impression</th>
              <th> Amount</th>
              <th >Status</th>
            </tr>
          </thead>
          <tbody className='border-top-0'>
            <tr className=''>
              <td className='ps-3'>Rupesh</td>
              <td className='wank_td'>Wankhede</td>
              <td className='numb_td'>9988778866</td>
              <td className='emai_td'>rupesh@gmail.com</td>
              <td className='lorem_td'>Lorem Ipsum is simply dummy </td>
            </tr>
            <tr className=''>
              <td className='ps-3'>Rupesh</td>
              <td className='wank_td'>Wankhede</td>
              <td className='numb_td'>9988778866</td>
              <td className='emai_td'>rupesh@gmail.com</td>
              <td className='lorem_td'>Lorem Ipsum is simply dummy </td>
            </tr>
            <tr className=''>
              <td className='ps-3'>Rupesh</td>
              <td className='wank_td'>Wankhede</td>
              <td className='numb_td'>9988778866</td>
              <td className='emai_td'>rupesh@gmail.com</td>
              <td className='lorem_td'>Lorem Ipsum is simply dummy </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Adv_Lead_Page
