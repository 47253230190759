import React from 'react'
import filter from "../dashboard/components/mainSection/images/filter.svg";
import search from "../dashboard/components/mainSection/images/search.svg";
import PropertyCard from "../dashboard/components/mainSection/PropertyCard"
import "./Analytics.scss"
let anacard = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 11, 11, 1, 11, 11, 11, 1, 11, 1, 1, 11, 1, 1, 11, 11, 11, 1, 1, 11, 1, 1, 1, 1, 11, 1, 1, 1, 11, 11, 11, 11, 111, 11, 1, 1, 11, 1, 11, 11, 111, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 11, 1, 1, 1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1, 1, 1, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 111, 11, 11, 111, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]

const Analytics = () => {
    return (
        <>
            {/* main-top-section */}
            <div className="main-sec flex-grow-1 gap-4 d-flex flex-column" >
                <div className="filter-sec  d-flex justify-content-between">
                    {/* chech box */}
                    <div className="buy-rent gap-3 d-flex align-items-center">
                        <div className="d-flex">
                            <input type="checkbox" name="PropType" className="Prop" id="Buy" value="Buy" />
                            <label htmlFor="Buy" className="pointer">
                                Buy
                            </label>
                        </div>
                        <div className="d-flex">
                            <input type="checkbox" name="PropType" className="Prop" id="Rent" value="Rent" />
                            <label htmlFor="Rent" className="pointer">
                                Rent
                            </label>
                        </div>
                    </div>

                    {/* Search box */}
                    <div className="search-box d-flex flex-grow-1">
                        <div className="search d-flex align-items-center justify-content-between flex-grow-1">
                            <input type="text" className="trans-input" placeholder="Search Town or City" name="" id="" />
                            <div className="search-icon pointer">
                                <img src={search} alt="" />
                            </div>
                        </div>
                        <div className="filter-icon pointer d-flex align-items-center justify-content-center"
                        // onClick={() => setResfiltermodal(true)}
                        >
                            <img src={filter} alt="" />
                        </div>
                    </div>

                </div>


                <div className="analytics-feed-section scrollS d-flex justify-content-around">
                    {anacard.map((card, i) => <PropertyCard key={`anacardsd${i}`} />)}

                </div>
            </div>
        </>
    )
}

export default Analytics
