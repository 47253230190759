import moment from "moment/moment";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import swal from 'sweetalert'
import { getToken } from "../../../../appVariable/variable";
import { selectedplan } from "../server";
import Activecard from "./cards/Activecard";
import Smallcard from "./cards/Smallcard";

const Planbox = (props) => {
  let token = getToken();
  let userId = token?.userId;
  const { SubscriptionData, plandetails, tab, emptycolshow } = props;
  const navigate = useNavigate();
  const [addonplan, setAddonplan] = useState(false);
  const [planid, setPlanid] = useState();

  let sendselectplan = (addonId) => {
    let plandetail = {
      user_id: userId,
      subscription_plan_id: [
        planid,
        addonId && SubscriptionData[SubscriptionData.length - 1]?.id,
      ],
      requested_date: moment().format("YYYY-MM-DD"),
      plan_choices: tab ? "Monthly" : "Yearly",
    };
    selectedplan(plandetail, (success) => {
      setAddonplan(false);
      navigate("/check-out");
    });
  };
  return (
    <div className="w-100">
      <Row className=" w-100 plancard-container d-flex justify-content-between px-2">
        {/* {emptycolshow && <Col md={4} className="empty-col"></Col>} */}
        <Col className=" card-coloumn">
          {" "}
          <Smallcard
            className=" card-coloumn"
            data={SubscriptionData[0]}
            description={plandetails}
            bottomBtn={true}
            setAddonplan={setAddonplan}
            setPlanid={setPlanid}
          />
        </Col>
        <Col className=" card-coloumn">
          {" "}
          <Activecard
            className=" card-coloumn"
            data={SubscriptionData[1]}
            description={plandetails}
            setAddonplan={setAddonplan}
            setPlanid={setPlanid}
          />
        </Col>
        <Col className=" card-coloumn">
          {" "}
          <Smallcard
            className=" card-coloumn"
            data={SubscriptionData[2]}
            description={plandetails}
            bottomBtn={true}
            setAddonplan={setAddonplan}
            setPlanid={setPlanid}
          />
        </Col>
        {/* <div className='center addon-plus'><h1>+</h1></div> */}
        <Col className=" card-coloumn">
          {" "}
          <Smallcard
            className=" card-coloumn"
            data={SubscriptionData[3]}
            description={plandetails}
            bottomBtn={false}
            setPlanid={setPlanid}
          />
        </Col>
      </Row>

      <div className="">
        <Modal
          show={addonplan}
          size="lg"
          centered
          aria-labelledby="contained-modal-title-vcenter "
          className="nbsp-modal"
          onHide={() => setAddonplan(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="security_body">
            <div className="addon-plan-modal center gap-3 flex-column ">
              <h4>Do you want Neighborhood Specialist Plan</h4>
              <p className=" text-center">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Ducimus fugiat ipsa molestias veritatis quos non quia laudantium
                vitae ut sint animi, praesentium libero modi distinctio!
              </p>

              <div className="d-flex w-100 gap-3 justify-content-center">
                <button
                  type="submit"
                  className="suspend_btn"
                  style={{ minWidth: "150px" }}
                  onClick={() => sendselectplan(1)}
                >
                  Continue
                </button>
                <button
                  type="submit"
                  className="suspend_btn"
                  style={{ minWidth: "150px" }}
                  onClick={() => sendselectplan()}
                >
                  Skip
                </button>
              </div>
            </div>
            <div className="addon-plan-modal  mt-2 text-end">
              {/* <button type="submit" className="suspend_btn" style={{ minWidth: '150px' }}
                            onClick={() =>
                                sendselectplan()
                                // setAddonplan(false)
                            }
                        >Skip</button> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Planbox;
