import React from 'react'
import "./Create_ADS_page.scss"
import { TbInnerShadowTopFilled } from 'react-icons/tb';
import { CgMoreVertical } from 'react-icons/cg';
import { BsEyeFill } from 'react-icons/bs';
import AdvUserImg from '../../ImgAdv/AdvUserImg.png'
import Table from 'react-bootstrap/Table';
// import { Card } from '@mui/material';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';



const Create_ADS_page = () => {
    return (


        <div className='main_ADS_conatiner'>
            <div class="btn_content">
                <button className="btn btn-lg  btn-outline-primary new_btn" size='lg'>New..</button>
            </div>
            <table class="table table_heading" >
                <thead className=" text-center">
                    <tr>
                        <th>Campaign Name</th>
                        <th>Image </th>
                        <th> Target Market</th>
                        <th> City Selection</th>
                        <th> Start Date</th>
                        <th> End Date</th>
                        <th> Total Impression</th>
                        <th> status </th>
                    </tr>
                </thead>
            </table>

            <div class="ads_table-container" style={{ color: "#585656" }}>
                <table class="table " >
                    <thead >
                        <tr>
                            <th>Payment Data</th>
                            <th>Campaign Name</th>
                            <th>Image </th>
                            <th>City Selection</th>
                            <th>Status</th>
                            <th> Invoices</th>
                        </tr>
                    </thead>
                    <tbody className='border-top-0'>
                        <tr className='t_row'>
                            <td>Lorem Lorem</td>
                            <td><img src={AdvUserImg} className='w-50' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", border: "1px solid #ffffff", borderRadius: "5px" }} alt="#" /></td>
                            <td><span class="badge bg-primary span">Property Shoppers</span>.. </td>
                            <td><span class="badge bg-primary span1">Brooklyn</span> <span class="badge bg-primary span2">Bronx</span> </td>
                            <td>11/28/2023 </td>
                            <td>07/27/2023 </td>                             <td>700/10000 </td>
                            <td><TbInnerShadowTopFilled style={{ color: "#0CC908" }} className='dowtLogo'/></td>
                            <td><CgMoreVertical /></td>
                        </tr>
                        <tr className='t_row'>
                            <td>Lorem Lorem</td>
                            <td><img src={AdvUserImg} className='w-50' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", border: "1px solid #ffffff", borderRadius: "5px" }} alt="#" /></td>
                            <td><span class="badge bg-primary span">Property Shoppers</span>.. </td>
                            <td><span class="badge bg-primary span1">Brooklyn</span> <span class="badge bg-primary span2">Bronx</span> </td>
                            <td>11/28/2023 </td>
                            <td>07/27/2023 </td>
                            <td>700/10000 </td>
                            <td><TbInnerShadowTopFilled style={{ color: "#FF3838" }} className='dowtLogo'/></td>
                            <td><CgMoreVertical /></td>
                        </tr>
                        <tr className='t_row'>
                            <td>Lorem Lorem</td>
                            <td><img src={AdvUserImg} className='w-50' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", border: "1px solid #ffffff", borderRadius: "5px" }} alt="#" /></td>
                            <td><span class="badge bg-primary span">Property Shoppers</span>.. </td>
                            <td><span class="badge bg-primary span1">Brooklyn</span> <span class="badge bg-primary span2">Bronx</span> </td>
                            <td>11/28/2023 </td>
                            <td>07/27/2023 </td>
                            <td>700/10000 </td>
                            <td><TbInnerShadowTopFilled style={{ color: "#FFCF3D" }} className='dowtLogo'/></td>
                            <td><CgMoreVertical /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        // <div className='main_ADS_conatiner w-100 ' >

        //     <div class="btn_content">
        //         <button className="btn btn-lg  btn-outline-primary new_btn" size='lg'>New..</button>
        //     </div>
        //     {/* --------------------------------------------------------------------------------------------------- */}

        //     {/* --------------------------------------------------------------------------------------------------- */}

        //     {/* <div className='Table_Content '>
        //         <header class='Table_Heading mb-5'>
        //             <div className='w-100 text-center d-flex text-primary justify-content-between align-items-center'>
        //                 <a href='' className='text-decoration-none style-none' style={{}}>Campaign Name</a>
        //                 <a href='' className='text-decoration-none style-none'>Image</a>
        //                 <a href='' className='text-decoration-none style-none'>Target Market</a>
        //                 <a href='' className='text-decoration-none style-none'>City Selection</a>
        //                 <a href='' className='text-decoration-none style-none'>Start Date</a>
        //                 <a href='' className='text-decoration-none style-none'>End Date</a>
        //                 <a href='' className='text-decoration-none style-none'>Total Impression</a>
        //                 <a href='' className='text-decoration-none style-none'>Status</a>
        //             </div>
        //         </header>
        //          <div className='card border-primary'>
        //             <div className='card-body border-primary'>

        //                 <table id="example" class="table border-primary">

        //                     <tbody className='tbody'>
        //                         <tr className='t_row'>
        //                             <td>Lorem Lorem</td>
        //                             <td><img src={AdvUserImg} className='w-50' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", border: "1px solid #ffffff", borderRadius: "5px" }} alt="#" /></td>
        //                             <td><span class="badge bg-primary">Property Shoppers</span>.. </td>
        //                             <td><span class="badge bg-primary">Brooklyn</span> <span class="badge bg-primary">Bronx</span> </td>
        //                             <td>11/28/2023 </td>
        //                             <td>07/27/2023 </td>
        //                             <td>700/10000 </td>
        //                             <td><TbInnerShadowTopFilled style={{ color: "#0CC908" }} /></td>
        //                             <td><CgMoreVertical /></td>
        //                         </tr>
        // <tr className='t_row'>
        //     <td>Lorem Lorem</td>
        //     <td><img src={AdvUserImg} className='w-50' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", border: "1px solid #ffffff", borderRadius: "5px" }} alt="#" /></td>
        //     <td><span class="badge bg-primary">Property Shoppers</span>.. </td>
        //     <td><span class="badge bg-primary">Brooklyn</span> <span class="badge bg-primary">Bronx</span> </td>
        //     <td>11/28/2023 </td>
        //     <td>07/27/2023 </td>
        //     <td>700/10000 </td>
        //     <td><TbInnerShadowTopFilled style={{ color: "#FF3838" }} /></td>
        //     <td><CgMoreVertical /></td>
        // </tr>
        // <tr className='t_row'>
        //     <td>Lorem Lorem</td>
        //     <td><img src={AdvUserImg} className='w-50' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", border: "1px solid #ffffff", borderRadius: "5px" }} alt="#" /></td>
        //     <td><span class="badge bg-primary">Property Shoppers</span>.. </td>
        //     <td><span class="badge bg-primary">Brooklyn</span> <span class="badge bg-primary">Bronx</span> </td>
        //     <td>11/28/2023 </td>
        //     <td>07/27/2023 </td>
        //     <td>700/10000 </td>
        //     <td><TbInnerShadowTopFilled style={{ color: "#FFCF3D" }} /></td>
        //     <td><CgMoreVertical /></td>
        // </tr>
        //                     </tbody>
        //                 </table>
        //             </div>
        //         </div>
        //     </div> */}




        // </div>






    )
}

export default Create_ADS_page
{/* <td className='py-3'></td> */ }
                            // <td><CgMoreVertical /></td>
                        //     <Table striped bordered hover>
                        //     <thead>
                        //       <tr>
                        //         <th>#</th>
                        //         <th>First Name</th>
                        //         <th>Last Name</th>
                        //         <th>Username</th>
                        //       </tr>
                        //     </thead>
                        //     <tbody>
                        //       <tr>
                        //         <td>1</td>
                        //         <td>Mark</td>
                        //         <td>Otto</td>
                        //         <td>@mdo</td>
                        //       </tr>
                        //       <tr>
                        //         <td>2</td>
                        //         <td>Jacob</td>
                        //         <td>Thornton</td>
                        //         <td>@fat</td>
                        //       </tr>
                        //       <tr>
                        //         <td>3</td>
                        //         <td colSpan={2}>Larry the Bird</td>
                        //         <td>@twitter</td>
                        //       </tr>
                        //     </tbody>
                        //   </Table>

