import Savesearchmain from "./Savesearchmain";
import "../dashboard/dashboard.scss";
export default function SaveSearch() {
  return (
    <>
      <div className=" flex-grow-1">
        <Savesearchmain />
      </div>
    </>
  );
}
