import React, { useState, useEffect } from "react";
// import StarIcon from '@mui/icons-material/Star';
// import { Rating } from '@mui/material';
import TeamCard from "./TeamCard";
import { getTeamMemberProfile } from "../../profile/components/teams/server";
import { useParams } from "react-router-dom";
import { getToken } from "../../../../appVariable/variable";

const AgentTeams = (props) => {
  const { getAgentDetails } = props;
  const [teamDetail, setTeamDetail] = useState([]);
  let token = getToken();
  const params = useParams();
  const paramObj = getAgentDetails[0].slug;
  // const paramObj = agentNameFromUrl ? agentNameFromUrl : agentSlug;
  useEffect(() => {
    console.log(getAgentDetails[0].slug);
    getTeamMemberProfile(paramObj, (success) => {
      console.log("success", success);
      setTeamDetail(success);
    });
  }, []);
  return (
    <>
      <div className=" agent-team-section">
        <p className="leo-team mb-2">{getAgentDetails[0]?.team_name}</p>

        <div className="agent-team d-flex gap-3 flex-wrap ">
          {teamDetail?.data?.code === 200
            ? teamDetail?.data?.data.map((teamData, id) => {
                return <TeamCard teamData={teamData} key={`agentteam${id}`} />;
              })
            : null}
        </div>
      </div>
    </>
  );
};

export default AgentTeams;
