import React from 'react'
import { Modal } from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners'; // FadeLoader, GridLoader, HashLoader, MoonLoader
const Loader = () => {
  return (
    <>
      <Modal show={true} size='sx' centered className='loader-popup' aria-labelledby="contained-modal-title-vcenter" >
        <Modal.Body className='center ' >
          <ScaleLoader color="#315efb" height={100} margin={6} radius={50} width={10} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Loader