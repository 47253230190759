import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { getToken } from "../../../../../appVariable/variable";
import { saveDefaultPropertyShow } from "./server";
import { useDispatch, useSelector } from "react-redux";
import { updatePropertyDatail } from "../../propperty-datails/PropertySlice";
import { toast } from "react-toastify";
import { updateFilterData } from "../../../homepage/components/hero/FilterSlice";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  character: Yup.string()
    .max(150, "Input character must contain max 180 characters")
    .required("Required"),
});

const PropertySavePopUp = (props) => {
  const formik = useFormik({
    initialValues: {
      character: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const FilterDataInStore = useSelector((state) => state.FilterDataInStore);
  let token = getToken();
  let userId = token?.userId;
  const [rating, setRating] = useState(0);
  const [noteValue, setNoteValue] = useState("");
  const [noteError, setNoteError] = useState("");
  const dispatch = useDispatch();

  const handleClickRating = (value) => {
    setRating(value);
  };

  const handelSaveProperty = () => {
    let savepayload = {
      user_id: userId,
      property_details_id: [propertyDetailInStore.propertyId],
      notes: noteValue,
      rating: rating,
    };
    // if (noteValue === "") {
    //   setNoteError("Please enter save note");
    // } else {
    if (noteValue.length < 180) {
      props.setDetailShowProperty && props.setDetailShowProperty(false);
      saveDefaultPropertyShow(savepayload, (success) => {
        if (success.code === 200) {
          toast.success(success.data);
          hidepopup();
          updadateProprtyListInStore(true);
        }
      });
    } else {
      setNoteError("Must Contain max 180 characters");
    }
    // }
  };
  const updadateProprtyListInStore = (value) => {
    let updatedData = FilterDataInStore.Filter_responce.map((property) =>
      property.id === propertyDetailInStore.propertyId
        ? { ...property, guest_users_save_listing: value }
        : property
    );
    dispatch(updateFilterData({ Filter_responce: updatedData }));
  };

  const hidepopup = () => {
    dispatch(updatePropertyDatail({ setSavePropertyPopup: false }));
  };

  return (
    <div>
      <Modal
        className="dashboard-filter-popup"
        size="lg"
        centered
        show={propertyDetailInStore.setSavePropertyPopup}
        onHide={() => hidepopup()}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          <formik>
            <div className="dashborad-filter-body d-flex flex-column gap-4">
              <div className="price-sec  filter-row ">
                <div className="d-flex flex-row">
                  <div className="filter-heading">Note & Rate Listing:</div>
                  <div style={{ marginLeft: "10px" }}>
                    {[1, 2, 3, 4, 5].map((value) => (
                      <span
                        key={value}
                        style={{
                          color: value <= rating ? "gold" : "gray",
                          fontSize: "36px",
                          cursor: "pointer",
                          width: "16.325977325439453px",
                          height: "15.392753601074219px",
                          padding:
                            "1.3327734470367432px 1.3528780937194824px 1.3364131450653076px 1.3666167259216309px",
                          borderRadius: "5.198505401611328px",
                        }}
                        onClick={() => handleClickRating(value)}
                      >
                        {" "}
                        ★
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="price-sec  filter-row ">
                <div className="d-flex justify-content-between">
                  {noteError && (
                    <div className="validation-text text-capitalize">
                      {noteError}
                    </div>
                  )}
                </div>
                <div className="price-input-box justify-content-between">
                  <div
                    className="enter-input inner-shadow center flex-grow-1"
                    style={{ maxWidth: "none" }}
                  >
                    <input
                      type="textarea"
                      id=""
                      placeholder="Write Here..."
                      name="filtername"
                      onChange={(e) => {
                        formik.setFieldValue("character", e.target.value);
                        setNoteValue(e.target.value);
                        setNoteError("");
                      }}
                      value={noteValue}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-end "
                    style={{ marginTop: "12px" }}
                  >
                    <p>{formik.values.character.length}/180charater</p>
                  </div>
                </div>
              </div>

              <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                <button
                  className="btn"
                  style={{ width: "135px", borderRadius: "20px" }}
                  onClick={() => hidepopup()}
                >
                  Cancel
                </button>
                {userId ? (
                  <button
                    className="btn"
                    style={{ width: "135px", borderRadius: "20px" }}
                    onClick={() => handelSaveProperty()}
                  >
                    Submit
                  </button>
                ) : null}
              </div>
            </div>
          </formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PropertySavePopUp;
