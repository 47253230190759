import { Slider } from '@mui/material';
import React, { useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { Modal } from 'react-bootstrap'

const ProfileEdit = ({ src, modalOpen, setModalOpen, setPreview, formik }) => {
    const cropRef = useRef(null);
    const [slideValue, setSlideValue] = useState(10);
    const handleSave = async (e) => {
        e.preventDefault()
        if (cropRef) {
            setModalOpen(false);
            const dataUrl = cropRef.current.getImage().toDataURL();
            const result = await fetch(dataUrl);
            const blob = await result.blob();
            setPreview(URL.createObjectURL(blob));
            const canvasScaled = cropRef.current.getImageScaledToCanvas();
            canvasScaled.toBlob((blob) => {
                const editedFile = new File([blob], 'edited_image.png', { type: 'image/png', lastModified: Date.now(), });
                formik.setFieldValue("profileImg", editedFile);
            });

        }
    };

    return (
        <>
            <Modal show={modalOpen} size="md" centered
                onHide={() => setModalOpen(false)}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className=' w-100'>
                        <p className=' text-center' style={{ fontSize: "24px" }}> Set Up Your Profile Image</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="profile-editor d-flex flex-column gap-3">
                        <div className="img-editor mx-auto" style={{ width: "400px", height: "400px" }}>
                            <AvatarEditor
                                ref={cropRef}
                                style={{ width: "100%", height: "100%" }}
                                image={src}
                                border={50}
                                borderRadius={0}
                                color={[0, 0, 0, 0.72]}
                                scale={slideValue / 10}
                                rotate={0}
                            />

                        </div>
                        <Slider min={10} max={50} size="medium" sx={{ margin: "0 auto", width: "80%", color: "cyan" }}
                            defaultValue={slideValue}
                            value={slideValue}
                            onChange={(e) => setSlideValue(e.target.value)}
                        />
                        <div className="d-flex gap-4 justify-content-center">
                            <button className='red__btn py-2 px-4' onClick={(e) => setModalOpen(false)} >Cancel</button>
                            <button className='blue__btn py-2 px-4' onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProfileEdit