import React from 'react'
import "./Adv_Dream_Home.scss"
import Stunning from '../../ImgAdv/Stunning.png'
import dowt from '../../ImgAdv/dowt.png'
import number3 from '../../ImgAdv/number3.png'

const Adv_Dream_Home = () => {
  return (
    <section className='Advertise6'>

      <div className='dream_content d-flex'>
        <div className='number_3Img'>
          <img src={number3} alt="#" className='number_3IMg' />
        </div>
        <div className='dreamHeading'>
          <h2>Sit back & relax whilstyour new dream home is built</h2>
        </div>
      </div>
      <div className='descrip_dream_text'>
        <p className='mb-3 dream_text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
      </div>
      <div className='Img_Content3'>
        <div className='dreamImg1'>
          <img src={Stunning}/>
        </div>
        <div className='dreamImg2'>
          <img src={Stunning} className='respo_img'/>
        </div>
      </div>
      {/* ----------------dowtimg--------------------------------------- */}
      <img src={dowt} className='dowtImg4 border-none' alt="#" />
    </section>
  )
}

export default Adv_Dream_Home
