import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

const SmallMarketingForm = () => {
    return (
        <>
            <div className='marketing-top-sec d-flex flex-column'>
                <div className='sms_marketing_form'>
                    <div className='card_border pad_cards d-flex flex-column gap-4'>
                        <h6 className='mb-1'>Contact</h6>

                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Social Media ID name</p></div>
                            <div className='text-input flex-grow-1'>
                                <input type="text" className=' w-100' />
                            </div>
                        </div>
                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Maximum Of 5 Social Media Account</p></div>
                            <div className='text-input flex-grow-1'>
                                <input type="text" className=' w-100' />
                            </div>
                        </div>
                        <div className="d-flex input-row pb-1">
                            <div className="input-title"><p>Message</p></div>
                            <div className='text-input flex-grow-1'>
                                <textarea className=' w-100' name="" id="" placeholder='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur amet, illum qui delectus harum dolore ex dignissimos fugiat. Quis neque corporis beatae possimus dolore itaque?'></textarea>
                            </div>
                        </div>
                        <div className=" d-flex justify-content-end marketing-form-btn">
                            <Button variant="primary" className='center'>Start Now</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmallMarketingForm
