import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import homes from './image/home.svg'
// import deleteimg from "./images/delete.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "react-bootstrap/Modal";
import "./savedListings.scss";
import removeimg from "./images/remove.svg";
import {
  RemoveAllSaveListing,
  RemoveNoLongerAvaliable,
  removeDefaultPropertyShow,
} from "../dashboard/components/mainSection/server";
import { EditSaveListing, getSaveListing } from "./server";
import { useSelector } from "react-redux";
import SaveListingCard from "./components/SaveListingCard";
import { toast } from "react-toastify";
import { Menu, MenuItem, Rating } from "@mui/material";
import Loader from "../Loader/Loader";
import { getToken } from "../../../appVariable/variable";
import { SyncLoader } from "react-spinners";
import PropertyDetailsPopUp from "../dashboard/propperty-datails/PropertyDetailsPopUp";
// import { ScaleLoader } from "react-spinners";
import { numberWithCommas } from "../homepage/components/hero/Hero";
import { BsTrash } from "react-icons/bs";
import { Table } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { updatePropertyDatail } from "../dashboard/propperty-datails/PropertySlice";
import { getAllpropertyInfo } from "../dashboard/components/mainSection/server";
// import editimg from "./images/edit.svg"
// import { getAccounttype } from '../personal/typeOfAccount/server';
export const MAX_NOTE_CHARACTERS = 120;

const SavedListings = () => {
  let token = getToken();
  let userId = token?.userId;
  const dispatch = useDispatch();
  const [savePopup, setSavePopup] = useState(false);
  const [deletepopup, setDeletepopup] = useState(false);
  const [getSaveListings, setGetSaveListings] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [loading, setLoading] = useState(false);
  const [checkCurrentList, setCheckCurrentList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedClosedId, setSelectedClosedId] = useState([]);
  const [selectedOpenedId, setSelectedOpenedId] = useState([]);
  const [individualPropertyDelete, setIndividualPropertyDelete] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [openedListing, setOpenListing] = useState([]);
  const [closedListing, setCloseListing] = useState([]);
  const [indexnum, setIndexNum] = useState(0);
  const [editSchema, setEditSchema] = useState({
    notes: "",
    rating: 0,
    id: indexnum,
  });
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  let remainingCharacters = MAX_NOTE_CHARACTERS - editSchema?.notes?.length;
  let propertDetails = async (id) => {
    setLoading(true);
    dispatch(
      updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
    );
    await getAllpropertyInfo(id, (success) => {
      dispatch(updatePropertyDatail({ PropertyDetailInfo: success }));
      setPropertyDetailInfo(success);
      setLoading(false);
    });
  };
  const handleEdit = (e, details) => {
    console.log("details");
    console.log("detail", details?.id);
    setAnchorE2(null);
    setSavePopup(true);
  };
  const sortData = (e) => {
    let sortOption = e;
    const [key, order] = sortOption.split("_");
    const sortedData1 = [...closedListing].sort((a, b) => {
      let valueA;
      let valueB;
      switch (key) {
        case "price":
          valueA = a.property_details.property_listing_amount;
          valueB = b.property_details.property_listing_amount;
          break;
        case "rating":
          valueA = a.rating;
          valueB = b.rating;
          break;
        // case 'rooms':
        //     valueA = a.nested.rooms;
        //     valueB = b.nested.rooms;
        //     break;
        case "bedrooms":
          valueA = a.property_details.Bedrooms;
          valueB = b.property_details.Bedrooms;
          break;
        case "date":
          valueA = new Date(a.property_details.created_date);
          valueB = new Date(b.property_details.created_date);
          break;
        default:
          valueA = 0;
          valueB = 0;
          break;
      }

      if (order === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else if (order === "desc") {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
      return 0;
    });
    const sortedData2 = [...openedListing].sort((a, b) => {
      let valueA;
      let valueB;
      switch (key) {
        case "price":
          valueA = a.property_details.property_listing_amount;
          valueB = b.property_details.property_listing_amount;
          break;
        case "rating":
          valueA = a.rating;
          valueB = b.rating;
          break;
        // case 'rooms':
        //     valueA = a.nested.rooms;
        //     valueB = b.nested.rooms;
        //     break;
        case "bedrooms":
          valueA = a.property_details.Bedrooms;
          valueB = b.property_details.Bedrooms;
          break;
        case "date":
          valueA = new Date(a.property_details.created_date);
          valueB = new Date(b.property_details.created_date);
          break;
        default:
          valueA = 0;
          valueB = 0;
          break;
      }

      if (order === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else if (order === "desc") {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
      return 0;
    });
    setCloseListing(sortedData1);
    setOpenListing(sortedData2);
    // setGetSaveListings(sortedData);
  };
  const EditListing = () => {
    console.log("indexnum", indexnum);
    console.log("editSchema", editSchema);
    setEditSchema((prevState) => {
      const newState = {
        ...prevState,
        id: indexnum,
      };
      return newState;
    });
    setEditSchema((state) => {
      return state;
    });
    EditSaveListing(editSchema, (success) => {
      if (success.code === 200) {
        setSavePopup(false);
        toast.success(success.data);
        getSaveListingData();
      } else {
        setSavePopup(false);
        toast.error("Sorry but submit failed");
      }
    });
  };

  const DeleteSaveProerty = async () => {
    if (individualPropertyDelete) {
      // if comming from three dot option delete single proprty
      // setLoading(true)
      console.log("+++++++++");
      await removeDefaultPropertyShow(selectedProperty, (sucess) => {
        if (sucess.code === 200) {
          getSaveListingData();
          toast.success(sucess.data);
          setDeletepopup(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      // if comming from remove lidting delete selected proprty
      let deleteObj = {
        user_id: userId,
        property_details_id: selectedOpenedId,
      };
      if (selectedOpenedId.length) {
        // setLoading(true)
        console.log("-----------");
        await RemoveAllSaveListing(deleteObj, (sucess) => {
          if (sucess.code === 200) {
            getSaveListingData();
            setDeletepopup(false);
            setSelectedId([]);
            toast.success(sucess.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    }
  };

  const RemoveNoLonger = async () => {
    if (individualPropertyDelete === false && selectedClosedId.length) {
      setLoading(true);
      let deleteObj = {
        user_id: userId,
        property_details_id: selectedClosedId,
      };
      if (selectedClosedId.length) {
        // setLoading(true)
        await RemoveAllSaveListing(deleteObj, (sucess) => {
          if (sucess.code === 200) {
            getSaveListingData();
            setDeletepopup(false);
            setSelectedId([]);
            toast.success(sucess.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    } else {
      toast.error("Please Select No available List");
    }
  };

  const handleChecked = (e, isOpen) => {
    const { name, id, checked } = e.target;
    console.log(name);
    if (name === "selectAll" && isOpen === undefined) {
      if (checked) {
        let setallCheck = [];
        let setallOpenedCheck = [];
        for (let list of checkCurrentList) {
          setallCheck = [...setallCheck, list.property_details?.id];
        }
        setSelectedId(setallCheck);
        for (let openedList of openedListing) {
          setallOpenedCheck = [
            ...setallOpenedCheck,
            openedList.property_details?.id,
          ];
        }
        setSelectedOpenedId(setallOpenedCheck);
      } else {
        setSelectedId([]);
        setSelectedOpenedId([]);
        setSelectedClosedId([]);
      }
    } else {
      let setcheckItems = [];
      let setClosedItems = [];
      let setOpenedItems = [];
      if (checked) {
        setcheckItems = [...selectedId, parseInt(id)];
        isOpen
          ? (setOpenedItems = [...selectedOpenedId, parseInt(id)])
          : (setClosedItems = [...selectedClosedId, parseInt(id)]);
      } else {
        setcheckItems = selectedId.filter((v) => v !== parseInt(id));
      }
      setSelectedId(setcheckItems);
      setSelectedOpenedId(setOpenedItems);
      setSelectedClosedId(setClosedItems);
    }
  };

  const getSaveListingData = () => {
    getSaveListing((success) => {
      console.log("code", success.data.code);
      if (success.data.code === 200) {
        console.log("length", success.data.data.length);
        success.data.data.length < 1 && setGetSaveListings([]);
        setCheckCurrentList(success.data.data);
        let sortlowToHigh = success.data.data;
        sortlowToHigh.sort(
          (a, b) =>
            a.property_details.property_listing_amount -
            b.property_details.property_listing_amount
        );
        setGetSaveListings(sortlowToHigh);
        const opend_lists = sortlowToHigh.filter((ele) => {
          return (
            ele?.property_details?.is_property_open === true &&
            ele?.property_details?.is_property_expired === false
          );
        });
        const closed_lists = sortlowToHigh.filter((ele) => {
          return (
            ele?.property_details?.is_property_open === false ||
            ele?.property_details?.is_property_expired === true
          );
        });
        setOpenListing(opend_lists);
        console.log("opend_lists;", opend_lists);
        setCloseListing(closed_lists);
        console.log("closed_lists;", closed_lists);
        setLoading(false);
      } else {
        setGetSaveListings([]);
        setOpenListing([]);
        setCloseListing([]);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    getSaveListingData();
  }, []);
  useEffect(() => {
    setEditSchema((prevState) => {
      const newState = {
        ...prevState,
        id: indexnum,
      };
      return newState;
    });
  }, [indexnum]);
  return (
    <>
      {loading ? <Loader /> : null}
      <div className=" flex-grow-1 savelisting_main">
        <div className="col-sm-12  pb-4 mb-1 save_listingP">
          {" "}
          Saved Listing{" "}
        </div>

        <div className=" d-flex justify-content-between mb-4">
          <div className=" d-flex">
            <button
              type="submit"
              className="me-3 btn__red remove_btn"
              onClick={() => {
                if (selectedOpenedId.length) {
                  setDeletepopup(true);
                } else {
                  toast.error("Please select available list");
                }
                setIndividualPropertyDelete(false);
              }}
            >
              <BsTrash /> {"  "}Remove Listing
            </button>
            <button
              type="submit"
              className="longer_btn blue__btNN"
              onClick={() => RemoveNoLonger()}
            >
              <BsTrash /> {"  "} Remove No Longer Available
            </button>
          </div>
          <div className=" button-row  text-end">
            <div className="sort-by center gap-2 justify-content-end align-items-center">
              <p className=" text-nowrap">Sort by :</p>
              <select
                aria-label="Default select example"
                className="box-shodow "
                onChange={(e) => {
                  // setSortOption(e.target.value)
                  sortData(e.target.value); // handleDropdownChange(e)
                }}
              >
                <option value="price_asc">Price: Low to High</option>
                <option value="price_desc">Price: High to Low</option>
                <option value="rating_asc">Rating: Low to High</option>
                <option value="rating_desc">Rating: High to Low</option>
                {/* <option value="rooms_asc">Rooms: Low to High</option>
                                <option value="rooms_desc">Rooms: High to Low</option> */}

                {/* if user is residential then only show Bedroom */}
                {!token.user_info.type_of_listing && (
                  <option value="bedrooms_asc">Bedrooms: Low to High</option>
                )}
                {!token.user_info.type_of_listing && (
                  <option value="bedrooms_desc">Bedrooms: High to Low</option>
                )}

                {/* <option value="date_asc">Created Date: Low to High</option>
                                <option value="date_desc">Created Date: High to Low</option> */}
              </select>
            </div>
          </div>
        </div>
        <div className="main_listing_table h-100 mt-1">
          <div className="main_save_listingcard h-100">
            <div className="row listing-header">
              <div
                className="col card_head_text select_group_head"
                style={{ minWidth: "200px" }}
              >
                <div className="input_check d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="selectAll"
                    value=""
                    id="all"
                    className="pointer"
                    onChange={(e) => handleChecked(e)}
                    checked={selectedId.length === getSaveListings.length}
                  />
                  <span className="">Listing</span>
                </div>
              </div>
              <div className="col Pricing card_head_text">Town</div>
              {!token.user_info.type_of_listing ? (
                <div className="col Pricing card_head_text">Bedroom</div>
              ) : (
                <div className="col Pricing card_head_text">Type </div>
              )}
              {!token.user_info.type_of_listing ? (
                <div className="col Pricing card_head_text">Bathroom </div>
              ) : (
                <div className="col Pricing card_head_text">Sub-Type </div>
              )}
              <div className="col Pricing card_head_text">Price</div>

              <div className="col card_head_text p-0 me-2">Category</div>

              <div className="col card_head_text select_group_head">Note </div>
              <div className="col rating card_head_text select_group_head ps-0">
                Rating{" "}
              </div>
            </div>

            <div className="listing-body scrollS h-100">
              <div className="closed-listing">
                {closedListing.length > 0 ? (
                  closedListing.map((detail, i) => {
                    return (
                      <div
                        className="row save_card_border mb-3 position-relative d-flex"
                        key={`detailclose${i}`}
                      >
                        <div className="col pe-0 card_inner_text_select closed-list">
                          <div className="card_inner_text">
                            <div className="d-flex flex-row check_box">
                              <div className="py-2 select_item">
                                <input
                                  type="checkbox"
                                  name=""
                                  value=""
                                  className="pointer"
                                  id={detail.property_details?.id}
                                  onChange={(e) => handleChecked(e, false)}
                                  checked={selectedId.includes(
                                    detail.property_details?.id
                                  )}
                                />
                              </div>
                              <div
                                className="py-2 ps-2"
                                // onClick={(e) => {
                                //   propertDetails(
                                //     detail?.property_details?.slug
                                //   );
                                // }}
                              >
                                <SaveListingCard
                                  handelOpenPopUp={setIndexNum}
                                  setPopup={setSavePopup}
                                  key={`savepropcard${i}`}
                                  agentListingData={detail}
                                  index={i}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col Pricing card_inner_text">
                          {detail?.property_details?.property_city?.city_name}{" "}
                        </div>
                        {/* <div className="col Pricing card_inner_text">
                        {detail?.property_details?.property_city?.city_name}{" "}
                      </div> */}
                        {/* if user is residential then only show Bedroom */}
                        {!token.user_info.type_of_listing ? (
                          <div className="col Pricing card_inner_text">
                            {detail?.property_details?.Bedrooms === ""
                              ? 0
                              : detail?.property_details?.Bedrooms}
                          </div>
                        ) : (
                          <div className="col Pricing card_inner_text ">
                            {
                              detail?.property_details?.propertylisting_type
                                ?.listing_type
                            }
                          </div>
                        )}
                        {/* if user is residential then only show Bedroom */}
                        {!token.user_info.type_of_listing ? (
                          <div className="col Pricing card_inner_text ">
                            {detail?.property_details?.Bathrooms === ""
                              ? 0
                              : detail?.property_details?.Bathrooms}
                          </div>
                        ) : (
                          <div className="col Pricing card_inner_text ">
                            {
                              detail?.property_details?.property_main_category
                                ?.property_sub_category[0]
                            }
                          </div>
                        )}
                        <div className="col Pricing card_inner_text">
                          {numberWithCommas(
                            detail?.property_details?.property_listing_amount
                          )}{" "}
                        </div>
                        <div className="col Pricing card_inner_text">
                          {
                            detail?.property_details?.propertylisting_type
                              ?.listing_type
                          }{" "}
                        </div>
                        {/* <div className="col card_inner_text ps-0">
                        {`${detail?.property_details?.property_address_1} , 
                                            ${
                                              detail?.property_details
                                                ?.property_area
                                                ? `${detail?.property_details?.property_area?.area_name} ,`
                                                : ""
                                            }   
                                            ${
                                              detail?.property_details
                                                ?.property_city?.city_name
                                            } ,  
                                            ${
                                              detail?.property_details
                                                ?.property_state?.state_name
                                            } ,  
                                            ${
                                              detail?.property_details
                                                ?.property_zip
                                            } `}
                      </div> */}
                        {/* if user is residential then only show Bedroom */}
                        {/* {!token.user_info.type_of_listing && (
                        <div className="col Pricing card_inner_text ">
                          {detail?.property_details?.Bedrooms === ""
                            ? 0
                            : detail?.property_details?.Bedrooms}
                        </div>
                      )} */}
                        <div
                          className="col card_inner_text_select"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          <Tooltip title={detail?.notes} placement="bottom">
                            <p
                              style={{
                                textOverflow: "ellipsis",
                                width: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {detail?.notes}
                            </p>
                          </Tooltip>
                        </div>
                        <div className="col d-flex align-content-center position-relative ps-0">
                          <div className="col d-flex ps-0 rating-list">
                            {/* if property expired show no available */}
                            {(!detail?.property_details?.is_property_open ||
                              detail?.property_details?.is_property_expired ===
                                true) && (
                              <button className="bg-blue_btn position-absolute top-0 end-0 m_longer_btn">
                                {" "}
                                No Longer Available{" "}
                              </button>
                            )}
                            <div className="d-flex  w-100 justify-content-between">
                              <div className="icon_colour d-flex ">
                                <Rating
                                  name="read-only"
                                  value={detail?.rating}
                                  readOnly
                                />
                              </div>
                            </div>
                            <MoreVertIcon
                              className="pointer"
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                // setSelectedProperty(detail?.id)
                                setSelectedProperty(
                                  detail.property_details?.id
                                );
                                setEditSchema((prevState) => {
                                  const newState = {
                                    ...prevState,
                                    id: detail?.id,
                                    rating: detail?.rating,
                                    notes: detail?.notes,
                                  };
                                  return newState;
                                });
                                setIndividualPropertyDelete(true);
                              }}
                            />
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => setAnchorEl(null)}
                              className="list-dropdoen"
                            >
                              <MenuItem
                                onClick={() => {
                                  setAnchorEl(null);
                                  setDeletepopup(true);
                                }}
                              >
                                <DeleteIcon style={{ color: "red" }} /> Delete
                              </MenuItem>
                              {}
                              {detail?.property_details?.is_property_open && (
                                <MenuItem
                                  onClick={(e) => {
                                    console.log("++++++++++");
                                    setAnchorEl(null);
                                    setSavePopup(true);
                                  }}
                                >
                                  <EditIcon /> Edit
                                </MenuItem>
                              )}
                            </Menu>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  // loading bar while api featch
                  <div className="center h-100">
                    {/* <Loader color="#315efb" /> */}
                  </div>
                )}
              </div>
              <div className="opened-listing">
                {openedListing.length > 0 ? (
                  openedListing.map((details, i) => {
                    return (
                      <div
                        className="row save_card_border mb-3 position-relative d-flex"
                        key={`details${i}`}
                      >
                        <div
                          className="col pe-0 card_inner_text_select"

                          // style={{ maxWidth: "235px", minWidth: "230px" }}
                        >
                          <div className="card_inner_text">
                            <div className="d-flex flex-row">
                              <div className="py-2">
                                <input
                                  type="checkbox"
                                  name=""
                                  value=""
                                  className="pointer"
                                  id={details.property_details?.id}
                                  onChange={(e) => handleChecked(e, true)}
                                  checked={selectedId.includes(
                                    details.property_details?.id
                                  )}
                                />
                              </div>
                              <div
                                className="py-2 ps-2"
                                onClick={(e) => {
                                  propertDetails(
                                    details?.property_details?.slug
                                  );
                                }}
                              >
                                <SaveListingCard
                                  handelOpenPopUp={setIndexNum}
                                  setPopup={setSavePopup}
                                  key={`savepropcard${i}`}
                                  agentListingData={details}
                                  index={i}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col Pricing card_inner_text">
                          {details?.property_details?.property_city?.city_name}{" "}
                        </div>
                        {/* <div className="col Pricing card_inner_text">
                        {detail?.property_details?.property_city?.city_name}{" "}
                      </div> */}
                        {/* if user is residential then only show Bedroom */}
                        {!token.user_info.type_of_listing ? (
                          <div className="col Pricing card_inner_text">
                            {details?.property_details?.Bedrooms === ""
                              ? 0
                              : details?.property_details?.Bedrooms}
                          </div>
                        ) : (
                          <div className="col Pricing card_inner_text ">
                            {
                              details?.property_details?.propertylisting_type
                                ?.listing_type
                            }
                          </div>
                        )}
                        {/* if user is residential then only show Bedroom */}
                        {!token.user_info.type_of_listing ? (
                          <div className="col Pricing card_inner_text ">
                            {details?.property_details?.Bathrooms === ""
                              ? 0
                              : details?.property_details?.Bathrooms}
                          </div>
                        ) : (
                          <div className="col Pricing card_inner_text ">
                            {
                              details?.property_details?.property_main_category
                                ?.property_sub_category[0]
                            }
                          </div>
                        )}
                        <div className="col Pricing card_inner_text">
                          {numberWithCommas(
                            details?.property_details?.property_listing_amount
                          )}{" "}
                        </div>
                        <div className="col Pricing card_inner_text">
                          {
                            details?.property_details?.propertylisting_type
                              ?.listing_type
                          }{" "}
                        </div>
                        {/* <div className="col card_inner_text ps-0">
                        {`${detail?.property_details?.property_address_1} , 
                                            ${
                                              detail?.property_details
                                                ?.property_area
                                                ? `${detail?.property_details?.property_area?.area_name} ,`
                                                : ""
                                            }   
                                            ${
                                              detail?.property_details
                                                ?.property_city?.city_name
                                            } ,  
                                            ${
                                              detail?.property_details
                                                ?.property_state?.state_name
                                            } ,  
                                            ${
                                              detail?.property_details
                                                ?.property_zip
                                            } `}
                      </div> */}
                        {/* if user is residential then only show Bedroom */}
                        {/* {!token.user_info.type_of_listing && (
                        <div className="col Pricing card_inner_text ">
                          {detail?.property_details?.Bedrooms === ""
                            ? 0
                            : detail?.property_details?.Bedrooms}
                        </div>
                      )} */}
                        <div
                          className="col card_inner_text_select"
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          <Tooltip title={details?.notes} placement="bottom">
                            <p
                              style={{
                                textOverflow: "ellipsis",
                                width: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {details?.notes}
                            </p>
                          </Tooltip>
                        </div>
                        <div className="col d-flex align-content-center position-relative ps-0">
                          <div className="col d-flex ps-0 rating-list">
                            {/* if property expired show no available */}
                            {!details?.property_details?.is_property_open &&
                              details?.property_details?.isExpired ===
                                false && (
                                <button className="bg-blue_btn position-absolute top-0 end-0 m_longer_btn">
                                  {" "}
                                  No Longer Available{" "}
                                </button>
                              )}

                            <div className="d-flex  w-100 justify-content-between">
                              <div className="icon_colour d-flex ">
                                <Rating
                                  name="read-only"
                                  value={details?.rating}
                                  readOnly
                                />
                              </div>
                            </div>

                            <MoreVertIcon
                              className="pointer"
                              onClick={(event) => {
                                setAnchorE2(event.currentTarget);
                                // setSelectedProperty(detail?.id)
                                setSelectedProperty(
                                  details.property_details?.id
                                );
                                setEditSchema((prevState) => {
                                  const newState = {
                                    ...prevState,
                                    id: details?.id,
                                    rating: details?.rating,
                                    notes: details?.notes,
                                  };
                                  return newState;
                                });
                                console.log("id", details?.id);
                                setIndexNum(details?.id);
                                setIndividualPropertyDelete(true);
                              }}
                            />
                            <Menu
                              anchorEl={anchorE2}
                              open={Boolean(anchorE2)}
                              onClose={() => setAnchorE2(null)}
                              className="list-dropdoen"
                            >
                              <MenuItem
                                onClick={() => {
                                  setAnchorE2(null);
                                  setDeletepopup(true);
                                }}
                              >
                                <DeleteIcon style={{ color: "red" }} /> Delete
                              </MenuItem>

                              <MenuItem
                                onClick={(e) => {
                                  handleEdit(e, details);
                                }}
                              >
                                <EditIcon /> Edit
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  // loading bar while api featch
                  <div className="center h-100">
                    {/* <Loader color="#315efb" /> */}
                  </div>
                )}
              </div>
            </div>
            <div className="listing-body scrollS h-100"></div>
          </div>
        </div>
      </div>

      {/*start modal You want to delete the saved listing! */}
      <div className="delete_save_listing">
        <Modal
          className="delete_save_listing"
          size="lg"
          centered
          show={deletepopup}
          onHide={() => setDeletepopup(false)}
          ria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header className=" pb-0" closeButton></Modal.Header>
          <Modal.Body className="px-5 mt-4   save_listing_body">
            <div className="text-center">
              <img src={removeimg} alt="" />
            </div>
            <h3 className="pb-3 mb-1 text-center">Are you sure?</h3>
            <p className="text-center pb-4 mb-1">
              You want to delete the saved listing!
            </p>
            <div className="row pb-5">
              <div className="col-sm-6 d-grid">
                <button
                  type="submit"
                  className="cancel_btn btn-block"
                  onClick={() => setDeletepopup(false)}
                  style={{ borderRadius: "20px" }}
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-6 d-grid">
                <button
                  type="submit"
                  className="save__btns btn-block"
                  onClick={() => {
                    DeleteSaveProerty();
                  }}
                  style={{ borderRadius: "20px" }}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* end You want to delete the saved listing! */}

      {/* save notes and rating pop-up  start*/}
      <div>
        <Modal
          className="dashboard-filter-popup"
          size="lg"
          centered
          show={savePopup}
          onHide={() => setSavePopup(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header className=" pb-0" closeButton>
            {" "}
          </Modal.Header>
          <Modal.Body>
            <formik>
              <div className="dashborad-filter-body d-flex flex-column gap-4">
                <div className="price-sec  filter-row ">
                  <div className="d-flex flex-row">
                    <div className="filter-heading">Note & Rate Listing:</div>
                    <div style={{ marginLeft: "10px" }}>
                      <Rating
                        name="simple-controlled"
                        value={editSchema.rating}
                        onChange={(event, newValue) => {
                          setEditSchema((prevState) => {
                            const newState = {
                              ...prevState,
                              rating: newValue,
                              // id: indexnum,
                            };
                            return newState;
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="price-sec  filter-row ">
                  <div className="d-flex justify-content-between">
                    {/* {noteError && (
                      <div className="validation-text text-capitalize">
                        {noteError}
                      </div>
                    )} */}
                  </div>
                  <div className="price-input-box justify-content-between">
                    <div
                      className="enter-input inner-shadow center flex-grow-1"
                      style={{ maxWidth: "none" }}
                    >
                      <input
                        type="textarea"
                        id=""
                        placeholder="Write Here..."
                        name="filtername"
                        onChange={(e) => {
                          setEditSchema((prevState) => {
                            const newState = {
                              ...prevState,
                              // id: indexnum,
                              notes: e.target.value.slice(
                                0,
                                MAX_NOTE_CHARACTERS
                              ),
                            };
                            return newState;
                          });
                        }}
                        value={editSchema.notes}
                      />
                    </div>
                    <div
                      className="d-flex justify-content-end "
                      style={{ marginTop: "12px" }}
                    >
                      <p>{editSchema?.notes?.length}/180charater</p>
                    </div>
                  </div>
                </div>

                <div className="filter-btn-grp d-flex gap-4 justify-content-end">
                  <button
                    className="btn"
                    style={{ width: "135px", borderRadius: "20px" }}
                    onClick={() => setSavePopup(false)}
                  >
                    Cancel
                  </button>
                  {userId ? (
                    <button
                      className="btn"
                      style={{ width: "135px", borderRadius: "20px" }}
                      onClick={() => EditListing()}
                    >
                      Submit
                    </button>
                  ) : null}
                </div>
              </div>
            </formik>
          </Modal.Body>
        </Modal>
      </div>
      {/* property detail pop up  */}
      {propertyDetailInStore.setshowPropertyPopup && !loading && (
        <PropertyDetailsPopUp
          setPropertyDetailInfo={setPropertyDetailInfo}
          propertyDetailInfo={propertyDetailInfo} // passing info about property
        />
      )}
    </>
  );
};

export default SavedListings;
