import React from 'react'
import Filterbar from '../editContact/Filterbar'
import VirtualOfficeCard from '../../../main-virtual-office/components/virtual-office-card/VirtualOfficeCard'
import Mapview from '../../../PropertyDetails/componants/map-view/Mapview'
let filterParameter = ["N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

const OfficeMapView = () => {
    return (
        <>
            <div className="d-flex flex-column w-100 office-mapView">
                <Filterbar />
                <div className='grid-two h-100'>
                    <div className='left_side d-flex flex-column gap-2 scrollS me-1'>
                        {filterParameter.map((para) =>
                            <VirtualOfficeCard key={`njdcj${para}`} />
                        )}
                    </div>
                    <div className='right_side'>
                        <Mapview />
                    </div>

                </div>
            </div>
        </>
    )
}

export default OfficeMapView
