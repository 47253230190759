import React from 'react'
import { NavLink } from 'react-router-dom'


const Sidelink = (props) => {

    return (
        <>
            <div className="one-link d-flex">
                <NavLink to={props.link} 
                    style={props.sidebar ? { width:'181px' } : { maxWidth:'50px' }}
                    className={({ isActive }) => (isActive ? 'active-link d-flex gap-2' : 'inactive d-flex gap-2')}
                >
                    <img className="link-icon pointer" src={props.icon} alt="" />
                    {/* {(props.title.toString().length > 3) && props.title} */}
                    {props.sidebar && props.title}
                </NavLink>
            </div>
        </>
    )
}

export default Sidelink
