import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import icons from "../components/sidebar/images/SidebarIcon"
import { getToken, sidebarValue } from "../../../../appVariable/variable";
import { useEffect } from "react";
import OfficeSidebar from "../../agentVirtualOffice/OfficeSidebar";
// import CommonState from "../../../../appVariable/CommonState";

const sidebardata = [
    { icon: icons.Dashboard, link: 'dashboard', title: 'dashboard', },
    { icon: icons.current, link: 'agents', title: 'AGENT', },
    { icon: icons.documents, link: 'document', title: 'Document', },
    { icon: icons.message, link: 'my-message', title: 'My Message', },
    { icon: icons.like, link: 'interested-property', title: 'Interested', },
    { icon: icons.dislike, link: 'dislike-property', title: 'Don’t like', },
]

const GuestVirtualDashboard = () => {
    let userDetails = getToken();
    const [sidebar, setsidebar] = useState(true);
    const [theme, settheme] = useState(true);
    const [sidebarStatus, setSidebarStatus] = useState(false)
    const [updatesidebar, setUpdatesidebar] = useState(true)
    const [agentTotaListing, setAgentTotaListing] = useState(userDetails?.profile?.listing_count)
    // let sidebaractive = JSON.parse(localStorage.getItem('sidebar'));

    useEffect(() => {
        // let token = getToken()
        // let sidebarCondition = token?.user_info.agent_profile;
        // console.log("sidebarCondition")
        // setSidebarStatus(sidebarCondition)
        let sidebarIs = sidebarValue()
        setSidebarStatus(sidebarIs.sidebar)
    }, [updatesidebar])

    // console.log(updatesidebar)

    return (
        <>
            <div className="main-dashboard">
                <div className={`dashboard-inner ${theme ? "white-theme" : "blue-theme"}`}>
                    <Topbar
                        sidebar={sidebar}
                        setsidebar={setsidebar}
                        theme={theme}
                        settheme={settheme}
                        totalListing={50}
                        agentTotaListing={agentTotaListing}
                    />
                    {/* <CommonState setUpdatesidebar={setUpdatesidebar} updatesidebar={updatesidebar} /> */}
                    <div className="dashboard-middle d-flex h-100">
                        <OfficeSidebar sidebar={sidebar} sidebardata={sidebardata} />
                        <Outlet context={{ updatesidebar: updatesidebar, setUpdatesidebar: setUpdatesidebar, setAgentTotaListing: setAgentTotaListing }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuestVirtualDashboard
