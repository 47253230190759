import { createSlice } from '@reduxjs/toolkit';
// import { getToken } from '../../../../../appVariable/variable';
// let userlogin = getToken();

export const FilterOptionSlice = createSlice({
    name: 'filterOptions',
    initialState: {
        categotyOptions: [],
        typeOptions: [],
        SubTypeOptions: [],
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateFilterOptions } = FilterOptionSlice.actions

export default FilterOptionSlice.reducer
