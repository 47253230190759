import React, { useState, useRef } from 'react'
import "./CustumeFileInput.scss"

const CustumeFileInput = (props) => {
    const { multiple, accept, id, onChange } = props
    const [fileName, setFileName] = useState("Select File")
    const fileInputRef = useRef(null);

    const handleFileInputChange = (event) => {
        onChange(event); // Propagate the event to the parent component
        const files = event.target.files;
        if (files.length === 1) {
            setFileName(files[0]?.name);
        }
        if (files.length > 1) {
            setFileName(files?.length + "  files");
        }
    };
    return (
        <>
            <input type="file" name="" id={id} hidden ref={fileInputRef}
                multiple={multiple}
                accept={`${accept}`}
                onChange={handleFileInputChange}
            />
            <label htmlFor={id} className='custumeFileLabel d-flex pointer'>
                <div className="file-btn center">Choose Files</div>
                <div className="select d-flex align-items-center ps-2">{fileName}</div>
            </label>
        </>
    )
}

export default CustumeFileInput