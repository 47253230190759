import React from 'react';
import './AdvMainSecontionOne.scss';
import { Button } from 'react-bootstrap';
const AdvMainSecontionOne = () => {
    return (
        <div className='position-relative d-flex div-main'>
            <div className=' w-50 div-left'>
                test1
            </div>
            <div className=' w-50 div-right'>
                test2
            </div>
            <div className='w-100 position-absolute div-text h-100 d-flex  gap-5 flex-column'>
                <div className='leftpara'>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                    </p>
                </div>
                <div className='d-flex'>
                    <div className='leftparatwo'>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.

                        </p>
                        <Button>Get An Estimate</Button>

                    </div>
                    <div className=' dotted-img'>
eddueduhduh
                    </div>
                </div>

            </div>

        </div>
    )
}

export default AdvMainSecontionOne
