import React, { useState, useEffect } from "react";
import PropertyCard from "../../dashboard/components/mainSection/PropertyCard";
import Mapview from "../../PropertyDetails/componants/map-view/Mapview";
import "./filter.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getDefaultPropertyShow } from "../../dashboard/components/mainSection/server";

const FilterResultMap = ({ filteredlisting }) => {
  const [filtercard, setFiltercard] = useState(true);
  const [guestDefaultPropertyShow, setGuestDefaultPropertyShow] = useState([]);

  useEffect(() => {
    getDefaultPropertyShow((success) => {
      setGuestDefaultPropertyShow(success);
    });
  }, [filteredlisting]);
  return (
    <>
      <div className=" d-flex h-100">
        <div className=" " style={{ display: filtercard ? "flex" : "none" }}>
          <div className="filter-card-result  flex-column  scrollS gap-4">
            {guestDefaultPropertyShow &&
              guestDefaultPropertyShow?.property?.map((card, i) => (
                <div className=" mb-2">
                  {" "}
                  <PropertyCard
                    key={`resultcard${card + i}`}
                    agentListingData={card}
                  />{" "}
                </div>
              ))}
          </div>
        </div>
        <div className="  filter-map-result position-relative flex-grow-1">
          <div
            className="filter-card-toggle  center  position-absolute pointer"
            onClick={() => {
              setFiltercard(!filtercard);
            }}
          >
            {filtercard ? (
              <ArrowBackIosIcon style={{ color: "black", width: "15px" }} />
            ) : (
              <ArrowForwardIosIcon style={{ color: "black", width: "15px" }} />
            )}
          </div>
          <div
            style={{ maxHeight: "75vh", overflow: "hidden", height: "100%" }}
          >
            {/* <Mapview allProperty={filteredlisting} /> */}
            {filteredlisting.length > 0 ? (
              <Mapview allProperty={filteredlisting} />
            ) : (
              <Mapview />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterResultMap;
