import React from "react";
import PropertyCard from "../../dashboard/components/mainSection/PropertyCard";
import { getAllpropertyInfo } from "../../dashboard/components/mainSection/server";
import { useDispatch } from "react-redux";
import { updatePropertyDatail } from "../../dashboard/propperty-datails/PropertySlice";
import { useRef, useState } from "react";
import PropertyDetailsPopUp from "../../dashboard/propperty-datails/PropertyDetailsPopUp";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import PropertySavePopUp from "../../dashboard/components/mainSection/PropertySavePopUp";
const AgentCurrentlisting = ({ listing }) => {
  const dispatch = useDispatch();
  const listingMapVIew = useRef();
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [loading, setloading] = useState(false);
  //   let propertDetails = async (id) => {
  //     dispatch(
  //       updatePropertyDatail({ propertyId: id, setshowPropertyPopup: true })
  //     );
  //     await getAllpropertyInfo(id, (success) => {
  //       onOpen(success);
  //     });
  //   };
  let propertDetails = async (id) => {
    setloading(true);
    await getAllpropertyInfo(id, (success) => {
      dispatch(
        updatePropertyDatail({
          PropertyDetailInfo: success,
          propertyId: id,
          setshowPropertyPopup: true,
          type: "current",
        })
      );
      setPropertyDetailInfo(success);
      setPropertyDetailInfo((state) => {
        return state;
      });
      // setType("current");
      // setType((state) => {
      //   return state;
      // });
      setloading(false);
    });
  };

  return (
    <>
      <div className="scrollS" ref={listingMapVIew}>
        {loading ? <Loader /> : null}
        <div className="d-flex flex-wrap gap-4">
          {listing?.map((agentCurrentListing, id) => (
            <PropertyCard
              handelOpenPopUp={propertDetails}
              agentListingData={agentCurrentListing}
              agentKey={id}
              key={`agentcurrent${id}`}
            />
          ))}
        </div>
      </div>
      {propertyDetailInStore.setshowPropertyPopup &&
      !loading &&
      propertyDetailInStore.type === "current" &&
      propertyDetailInfo ? (
        <PropertyDetailsPopUp
          setPropertyDetailInfo={setPropertyDetailInfo}
          propertyDetailInfo={propertyDetailInfo} // passing info about
          showTravelview={true}
        />
      ) : null}
      {propertyDetailInStore.setSavePropertyPopup && <PropertySavePopUp />}
    </>
  );
};

export default AgentCurrentlisting;
