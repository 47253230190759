import React from 'react'
import Navbar from '../../../homepage/components/navbar/Navbar'
import "./Adv_RegPage.scss"
// import "./Adv_Login.scss"
import Footer from '../../../homepage/components/footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css'
import AdvNavBar from '../../../../Advertising-Marketing-Pages/AdvComponent/AdNavBar/AdvNavBar';
import { NavLink } from 'react-router-dom';



const Adv_Reg_Page = () => {
  return (
    <>
      <AdvNavBar />
      <div className='signup template d-flex justify-content-center align-items-center vh-100 '>
        <div className='form-container rounded bg-white p-5'>
          <form className=''>
            <h2 className='text-center mb-3'>Advertising Portal</h2>
            {/* -------------------------------- */}
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='password text-end ' className=' mb-1  text-end'>Business Name</label>
              <input type='name' className='pb-1 w-100' />
            </div>
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='password' className=' mb-1  text-end'>Business Email</label>
              <input type='email' className='pb-1 w-100' />
            </div>
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='phone' className=' mb-1  text-end'>Business Phone</label>
              <input type='number' className='pb-1 w-100' />
            </div>
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='password' className=' my-1  text-end'>Password</label>
              <input type='phone' className='pb-1 w-100' />
            </div>
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='password' className=' mb-1  text-end '>Confirm-Password</label>
              <input type='number' className='pb-1 w-100' />
            </div>
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='password' className=' mb-1  text-end '>Industry</label>
              <input type='number' className='pb-1 w-100' />
            </div>


            <div className='d-grid mt-3'>
              <button type="button" className='btn btn-primary btn-lg border-0'>
                Register
              </button>
            </div>
            <p className='text-center mt-3'>Already Have An Account ?<NavLink to="/ad-login" className='ms-2 text-decoration-none'>Sign up</NavLink></p>
          </form>
        </div>
      </div>
      <Footer />

    </>
  )
}

export default Adv_Reg_Page


