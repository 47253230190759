import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signedDocBlob: "Hi",
  docId: null,
};

export const GuestVirtualOfficeDocsignSlice = createSlice({
  name: "guestSignDoc",
  initialState,
  reducers: {
    guestSignDoc: (state, action) => {
      state.signedDocBlob = action.payload[0];
      state.docId = action.payload[1];
    },
  },
});

// Action creators are generated for each case reducer function
export const { guestSignDoc } = GuestVirtualOfficeDocsignSlice.actions;

export default GuestVirtualOfficeDocsignSlice.reducer;
