import React, { useEffect } from "react";
import "./Terms.scss";
// import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../homepage/components/footer/Footer";
import logo from "./image/logosvg.svg"
import bgimg from "./image/privacybg.png"
// import bgimg from "./image/termbg.png"
export default function Terms() {
  const path = window.location.href.split("/");
  console.log("🚀 ~ file: Terms.js:9 ~ Policy ~ path", path);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  return (
    <div>
      <div className="term-privacy-container">
        <div className="terms-header position-relative">
          <div className="skyblue-bottom-line position-absolute w-100"></div>
          <div className="orange-bottom-line  position-absolute w-100"></div>
          <img src={bgimg} alt="" style={{ maxWidth:'50%', marginTop:'5px'}} />
          <div className="home-link-logo  position-absolute mx-auto">
            <Link to="/"><img src={logo} alt=""/></Link>
          </div>
          <div className="terms-heading position-absolute"><h1>Terms & Conditions</h1></div>
        </div>

        <div className="container mt-5">
          <div className="terms_container">
            <h4 className="terms_subheading">INTRODUCTION AND AGREEMENT : </h4>
            <p className="terms_paragraph">
              Hello and we appreciate your interest in AFISADO. By using our
              Website and Services any individual who visits, views, accesses or
              uses any version of our Website and Services (hereinafter referred
              to as “Site”), clicking the submit button for registration,
              creating a new account or using AFISADO website or mobile
              application or other services provided by AFISADO including
              through a bot or other automated means, are bound by these Terms
              of Service.
            </p>
            <br />
            <h4 className="terms_subheading">ELIGIBILITY & REGISTRATION : </h4>
            <p className="terms_paragraph">
              You represent and warrant that you are at least 18 years of age
              and you hereby agree to be bound by all of the following
              provisions of these Terms of Service, which form a legally binding
              contract between you and Listlinc LLC., doing business as
              <a
                href="https:// www.afisado.com"
                style={{ marginLeft: "0.3rem", marginRight: "0.3rem" }}
              >
                AFISADO,
              </a>
              (hereinafter referred to as “AFISADO”). You further represent and
              warrant that you are not a competitor of AFISADO or any of its
              affiliates. You have not previously been suspended or removed from
              our Site; and your registration and your use of our Site is in
              compliance with all applicable laws and regulations. To access
              some features of our Site, you may be required to register for an
              account. When you register for an account, you may be required to
              provide us with some information about yourself, such as an email
              address, phone number or other identifying information. You agree
              that the information you provide is accurate and that you will
              keep it accurate and up to date at all times. When you register,
              you may be asked to provide a password. You are solely responsible
              for maintaining the confidentiality of your account and password,
              and you are responsible for all actions taken via your account.
              You may not share your user account(s) with others. If you do not
              agree to these Terms of Service, you are prohibited from accessing
              our Site and must immediately discontinue such access. By
              accessing our Site, you consent to the collection and use of
              certain information, as specified in the Privacy Policy.
            </p>{" "}
            <br />
            <h4 className="terms_subheading">BUSINESS :</h4>
            <p className="terms_paragraph">
              We are considered a media content real estate listing service. Our
              Site is an online marketing of real estate and a search engine
              platform for real estate. AFISADO is funded through advertising.
              You understand and agree that our Site may include advertisements,
              and that these are necessary to support AFISADO. See our Privacy
              Policy.
              <br /> <br />
              AFISADO does not have, nor do we plan to obtain a real estate
              license, our promise to you to provide an online market for real
              estate and a search engine platform for real estate to assist you
              in the areas of services you may find helpful, and we will not
              compete with licensed real estate industry professionals. AFISADO
              does not (a) broker, lease, or sublease or offer to broker, lease
              or sublease, or own apartments directly and is not a party to any
              transaction between landlords (including, as applicable, property
              management companies and/or property managers) and renters, (b)
              guarantee or ensure any apartment or any transaction between a
              renter and landlord, (c) conduct background screening on rental
              candidates, execute any lease or sublease documentation on behalf
              of renters or landlords or collect payment on behalf of renters or
              landlords, or (d) act as a property manager, broker, payment
              processor, money transmitter, payment manager, debt collector, or
              credit reporting agency, and does not guarantee any results from
              using our Site and Service. Our Site facilitates certain services
              provided by third parties. You may be subject to additional
              third-party terms and conditions for such services, which we
              encourage you to review before acceptance, if applicable, at the
              time of use of such services
            </p>{" "}
            <br />
            <h4 className="terms_subheading">REPRESENTATION /INDEMNITY :</h4>
            <p className="terms_paragraph">
              You hereby represent, warrant and agree that (a) You have all
              necessary authority, rights and permissions to submit the Content
              to our Site, (b) the Content does not and will not infringe or
              misappropriate any copyright, trademark, trade secret, patent or
              any other intellectual property or other right of any third party,
              including any rights of privacy or publicity, (c) the Content,
              Reference Information and your use of our Site does not and will
              not violate any applicable federal, state, or local law or
              regulation including, but not limited to, any fair housing laws or
              regulations or applicable real estate licensure or brokerage
              regulations, or cause injury to any person, (d) the Content and
              Reference Information is complete, truthful, accurate and not
              misleading, (e) the Content and Reference Information has not been
              submitted in order to mislead, deceive or defraud or seek to
              mislead, deceive or defraud any other User of our Site, and (f)
              You will not mislead, deceive, defraud, seek to mislead, deceive
              or defraud, make any misrepresentations to, or seek to make
              misrepresentations to, any other User of our Site. You acknowledge
              that these representations concern facts that are material to
              AFISADO. You agree to release, defend, indemnify and hold AFISADO,
              its affiliates, Licensors, and their respective officers,
              directors, employees, agents and contractors harmless from and
              against any and all claims, costs, demands or expenses, including
              attorneys’ fees, arising from (i) any distribution, publication,
              refusal to publish, removal, deletion, movement, editing or other
              use of the Content or Reference Information You provide, (ii) your
              use of our Site and Service or any reliance on the Materials,
              (iii) your breach of these Terms of Service or (iv) any actual,
              prospective or terminated sale, lease or other transaction between
              You and a third party.
            </p>{" "}
            <br />
            <h4 className="terms_subheading">THIRD-PARTY SITES : </h4>
            <p className="terms_paragraph">
              Our Site, permit third-party products to offer their services,
              providers are only given your information when you grant your
              information to them upon requesting their service. Information
              about the third-party providers, as well as materials provided by
              third parties, may include functionality that allows for you to
              share your personal information with third-party providers to
              fulfill certain site features and functionality not under
              AFISADO’s control. If you include your name, contact information
              and other information in a request, your identity will no longer
              be anonymous to the Third-Party Provider. Third-Party Providers
              are solely responsible for their services. You are responsible for
              your use and submission of your information to any third-party,
              and your dealings or business conducted with any third party
              arising in connection with our Site are solely between you and
              such third-party. Your use of third-party sites, services, or
              products may be subject to associated third-party Terms of Service
              and privacy policies or other agreements, which you are solely
              responsible for complying with. Use of third-party products,
              services and providers, as well as materials provided by third
              parties are not under our control, and you acknowledge that we are
              not responsible or liable for the content, functions, accuracy,
              legality, appropriateness or any other aspect of such websites or
              resources. The inclusion of any such link does not imply our
              endorsement or any association between us and their operators. You
              further acknowledge and agree that we shall not be responsible or
              liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with the use of or
              reliance on any such content, goods or services available on or
              through any such website or resource. By using a tool that allows
              for your information to be transferred, you agree that AFISADO may
              transfer your information or other information to the applicable
              third parties, which are not under the control of AFISADO that you
              have chosen to submit your information over to. If you submit a
              contact form or otherwise indicate your interest in contacting a
              Third-Party Provider, you may receive telemarketing calls from the
              Third-Party Provider using the contact information you provided.
              Third-Party Providers may keep your contact information and any
              other information received by the Third-Party Provider in
              processing a contact or other request form. AFISADO is not
              responsible for any damages or costs of any type arising out of or
              in any way connected with your dealings with these third parties.
            </p>
            <br />
            <h4 className="terms_subheading">
              AFISADO INTELLECTUAL PROPERTY :{" "}
            </h4>
            <p className="terms_paragraph">
              The “AFISADO” name and logo are trademarks of Listlinc LLC. All
              software: user interfaces, designs, data, codes, products,
              graphics and all other elements not mentioned contained in our
              Site, and All Information and content available on our Site are
              owned and operated by Listlinc LLC. and protected by copyright and
              other intellectual property laws and international treaty
              provisions. Any unauthorized access to the following but not
              limited to, scraping reproduction, manipulation, redistribution,
              publication, display or other use of our Intellectual Property
              Software and or Information is expressly prohibited by law and
              will result in severe civil and/or criminal penalties. Violators
              will be prosecuted to the maximum extent possible.
              <br /> <br />
              Some content on our Site is owned by ZIP+4 data, United States
              Postal Services.
            </p>
            <br />
            <h4 className="terms_subheading">OUR TRADEMARKS</h4>
            <p className="terms_paragraph">
              Certain trademarks, service marks, and other indicia of source
              (together “Marks”) are owned by Listlinc LLC. You agree that you
              will not infringe, dilute, alter, or remove any Mark, copyright
              notice or other notice from our Site or Materials, or any copy
              thereof. Furthermore, you agree not to copy or use the Marks
              unless AFISADO explicitly notifies you or your agency in writing.
              <br /> <br />
              If you would like to use our Mark email
              <b
                style={{
                  marginLeft: "0.3rem",
                  marginRight: "0.3rem",
                }}
              >
                marketingdept@afisado.com
              </b>
              your full name (first and last name), Name of Company, your
              company email and your company phone number associated with
              Company Name provided, mention location of Mark placement, website
              Mark will be placed on, and purpose for use of our Marks. We
              reserve the right to deny any and all requests to use our Marks
              without explanation.
            </p>
            <br />
            <h4 className="terms_subheading">USER CONTENT :</h4>
            <p className="terms_paragraph">
              All Content added, created, uploaded, submitted, distributed, or
              posted on our Site by users (collectively “User Content”), whether
              publicly posted or privately transmitted, is the sole
              responsibility of the person who originated such User Content. You
              represent that all User Content provided by you is accurate,
              complete, up-to-date, and in compliance with all applicable laws,
              rules and regulations. You acknowledge that all Content, including
              User Content, accessed by you using the Services is at your own
              risk and you will be solely responsible for any damage or loss to
              you or any other party resulting therefrom. We do not guarantee
              that any Content you access on or through our Site is or will
              continue to be accurate.
            </p>
            <br />
            <h4 className="terms_subheading">LICENSE & LICENSE GRANT: </h4>
            <p className="terms_paragraph">
              By submitting ads, photos, descriptions, data, or other
              information (“Content”) to our Site in connection with a listing,
              You hereby grant to AFISADO, its affiliates, and its parent
              company a royalty-free, perpetual, irrevocable, nonexclusive,
              fully transferable, fully sublicensable right and license to copy,
              modify, display, distribute, perform, create derivative works
              from, store, and otherwise use and exploit all such Content in any
              form, media, software or technology of any kind now existing or
              developed in the future. You further grant AFISADO, its
              affiliates, and its related company, a royalty-free right and
              license to use your name, image and likeness in connection with
              the reproduction or distribution of the Content. By uploading or
              otherwise providing User Materials to our Site AFISADO will not
              pay you for your User Materials or to exercise any rights related
              to your User Materials set forth in the Terms of Service. AFISADO
              may remove or modify your User Materials at any time. You are
              solely responsible for all User Materials made through your user
              account(s) on the Services or that you otherwise make available
              through our Site. For all User Materials, you represent and
              warrant that you are the creator and owner of the User Materials,
              or have the necessary licenses, rights, consents, and permissions
              (including all permissions required under applicable privacy and
              intellectual property law) to authorize AFISADO and other users to
              access and use your User Materials as necessary to exercise the
              licenses granted by you under these Terms of Service. By
              submitting any Content through our Site, including, but not
              limited to, through any lead form, you agree that AFISADO, a
              landlord, a property manager or other similar individual or entity
              may contact you via email, text message, phone for any purpose.
              Our Site may permit you to voluntarily submit Content relating to
              you. By providing Consumer-Provided Information, you acknowledge
              that AFISADO may share such Consumer-Provided Information with
              Properties offering units for rent through our Site subject to
              these Terms of Service. Any recipient of such Consumer-Provided
              Information agrees that any Consumer-Provided Information may not
              be accurate, should not be relied on for any reason and may not be
              used to make a decision about whether to rent, lease or sell to a
              particular person. Our Site may offer opportunities for Users 18
              years of age and older to submit Content that expresses their
              opinions and share their ideas, for example in a consumer blogging
              format (“Opinion Content”). AFISADO and its affiliates do not
              endorse the accuracy or reliability of any Opinion Content. If you
              decide to submit Opinion Content, please use your best judgment
              and be respectful of other individuals. By posting or submitting
              Opinion Content, you agree to abide by these Terms of Service. You
              may not use our Site to engage in any Prohibited Activities (as
              defined below: Prohibited Activities). Uploading copyrighted or
              other proprietary material of any kind on our Site without the
              express permission of the owner of that material is prohibited and
              may result in civil and/or criminal liability.
            </p>
            <br />
            <h4 className="terms_subheading">AFISADO RIGHTS :</h4>
            <p className="terms_paragraph">
              AFISADO cannot and does not monitor all of the Content posted or
              transmitted by User and other third-party information providers,
              and under no obligation to edit or control User Content or the
              User Content of others and will not be in any way responsible or
              liable for any User Content. AFISADO reserves the right, in its
              sole discretion may, however, at any time and without prior
              notice, screen to monitor, refuse to publish, remove, delete, move
              or edit any User Content and without providing a refund. By using
              our Site, you expressly agree that AFISADO (a) will not be liable
              for any claims, actions or judgments arising out of or related to
              any Content and you further expressly agree that you are solely
              responsible for any and all Content You submit to our Site. You
              understand that when using our Site, you may be exposed to User
              Content of other users and acknowledge that User Content may be
              inaccurate, offensive, indecent, or objectionable. You agree to
              waive, and do waive, any legal or equitable right or remedy you
              may have against AFISADO with respect to User Content. AFISADO
              expressly disclaims any and all liability in connection with User
              Content. If notified by a user or content owner that User Content
              allegedly do not conform to these Terms of Service, AFISADO may
              investigate the allegation and determine in AFISADO’s sole
              discretion whether to remove the User Content, which AFISADO
              reserves the right to do at any time and without notice.
            </p>
            <br />
            <h4 className="terms_subheading">RESTRICTIONS & NOTICE :</h4>
            <p className="terms_paragraph">
              As long as you comply with these Terms of Service, AFISADO grants
              you a non-exclusive, limited, revocable, personal,
              non-transferable license to use our Site, and to download, display
              locally, and use any mobile application (See Mobile Application,
              SMS & Email Use) on your mobile device in object code form, for
              your personal use. If you are a real estate professional or
              private renter or private seller acting in your professional
              capacity, you may additionally use our Site to provide
              information, to the extent permitted by applicable law, to your
              clients and to take actions on behalf of your clients. If you use
              our Site, you represent and warrant that you have obtained all
              required authorizations and consents from your client. Except as
              expressly stated herein, these Terms of Service do not provide you
              with a license to use, reproduce, distribute, display or provide
              access to any portion of our Site on third-party web sites or
              otherwise. Our Site may include software for use in connection
              with our Site. You shall not sell, license, rent, or otherwise use
              or exploit any Content for commercial use, in connection with the
              provision of services to a third party without our prior written
              consent, or in any way that violates any third party right.
              <br /> <br />
              Mobile Application, SMS & Email Use: You agree that you are solely
              responsible for any mobile applicable charges; if any apply. You
              must see to it you have a compatible mobile device. AFISADO does
              not warrant that any mobile application will be compatible.
              AFISADO may update any mobile application and may automatically
              update a current version of any mobile application that you have
              installed on a mobile device. You consent to all automatic
              upgrades, and understand that these Terms of Service will also
              apply to all updated versions of our mobile application. Any
              third-party open source software included in a mobile application
              is subject to the applicable open source license and may be
              available directly from the creator under an open source license.
              These Terms of Service do not apply to your use of software
              obtained from a third-party source under an open source license.
              <br /> <br />
              Providing our Site phone numbers and emails, you are representing
              that you have the right and necessary permissions to use such
              phone number(s) and email(s) provided. Providing these phone
              number(s) and email(s) you are agreeing to receive SMS
              notifications and email notifications from our Site. You are also
              agreeing to indemnify and hold AFISADO harmless from and against
              any and all claims, liabilities, damages (actual and
              consequential), losses and expenses (including attorneys’ fees)
              arising from or in any way related to your breach of the foregoing
              to the extent you voluntarily opt to have SMS notifications and
              email notifications sent directly to your mobile phone, the
              following terms apply:
              <br /> <br />
              You also agree that you are solely responsible for any mobile
              charges, and messaging rates related to but not limited to our SMS
              notifications and email notifications and to all subsequent SMS
              correspondence. Under no circumstances will our Site be
              responsible for any text messaging or wireless charges incurred by
              you or by a person that has access to your wireless device or
              telephone number. If for any reason there are delays in the
              receipt of any SMS notifications and email notifications you agree
              that our Site and Service are not liable for any delays of
              delivery.
            </p>
            <br /> <h4 className="terms_subheading">AFISADO REMEDIES : </h4>
            <p className="terms_paragraph">
              You acknowledge that any breach of these binding Terms of Service,
              including without limitation the restrictions on competitor access
              and the sections “AFISADO Intellectual Property,”
              “Representations/Indemnity,” and “Prohibited Activities,” or any
              unauthorized use of our Site and Service, is a material breach of
              this agreement between you and AFISADO and will cause irreparable
              harm and injury to afisado.com, for which there is no adequate
              remedy at law. Thus, in addition to all other remedies available
              at law or in equity, you agree that in the event of such breach
              AFISADO shall be entitled to injunctive or other equitable relief,
              whether or not liquidated damages are available without the
              requirement that AFISADO post a bond.
              <br /> <br />
              Material breach of these binding Terms of Service harms the
              integrity, functionality, and reputation of AFISADO; detracts from
              Users’ trust in and use of our Site and Service; and damages the
              business of AFISADO. Accordingly, you acknowledge and agree that
              AFISADO incurs actual damages, which are extremely difficult or
              impossible to quantify, as a result of material breach of this
              these binding Terms of Service. For the avoidance of doubt, such
              damages would not constitute an adequate remedy at law or in any
              way affect your agreement that AFISADO would be entitled to
              injunctive or other equitable relief in the event of such breach.
            </p>
            <br /> <h4 className="terms_subheading">PROHIBITED ACTIVITIES: </h4>
            <p className="terms_paragraph">
              THE USE OF OUR SITE & SERVICE YOU ARE AGREEING NOT TO:
              <br /> <br />
              submit Content that is patently offensive to the online community,
              such as content that reflects or promotes racism, bigotry, hatred
              or physical harm of any kind against any group or individual;
              <br />
              engage in activity or submit Content that could be harmful to
              minors;
              <br />
              engage in activity or submit Content that harasses or advocates
              harassment of another person; <br />
              engage in activity that involves the transmission of "junk mail"
              or unsolicited mass mailing or "spam" to others; <br /> engage in
              activity, submit Content or Reference Information, or promote
              information, that is fraudulent, false, misleading, inaccurate
              and/or constitutes a misrepresentation, or promotes illegal
              activities or conduct that is abusive, threatening, obscene,
              vulgar, defamatory, libelous or otherwise objectionable; <br />
              submit Content that contains restricted or password-only access
              pages, or hidden pages or images; <br /> submit Content that
              displays pornographic or sexually explicit material of any kind;{" "}
              <br />
              submit Content that provides instructional information about
              illegal activities such as making or buying illegal weapons,
              violating someone’s privacy, or providing or creating computer
              viruses; <br /> engage in activities or submit Content that
              solicits passwords or personally identifiable information from
              others for unlawful purposes; <br />
              engage in commercial activities and/or sales without our prior
              written consent, such as contests, sweepstakes, barter,
              advertising and pyramid schemes; <br />
              use our Site to promote products and services to AFISADO.
              advertisers or to solicit AFISADO advertisers in any manner;
              <br /> conduct automated queries, use any robot, spider, scraper
              or other script code device, or a manual process, to access,
              monitor or copy web pages or the Materials contained in our Site
              or for any other unauthorized purpose; <br />
              use any device, software or routine to interfere or attempt to
              interfere with the proper working of our Site and; reproduce,
              modify, distribute, display or otherwise provide access to, create
              derivative works from; decompile, reverse engineer, disassemble or
              otherwise attempt to obtain the source code for the Software; or
              take any action that imposes an unreasonable or disproportionately
              large load on afisado.com hardware and software infrastructure.
              <br />
              upload invalid data, viruses, worms, or other software agents to
              the Services;
              <br />
              use information provided by AFISADO in making any loan-related
              decisions. <br /> remove or modify any copyright or other
              intellectual property notices that appear in the Services; <br />
              use the Services for resale, service bureau, time-sharing or other
              similar purposes; <br /> use the Services in any way that is
              unlawful, or harms AFISADO, its service providers, suppliers, or
              any other user;
              <br />
              distribute or post spam, chain letters, pyramid schemes, or
              similar communications through our Site; impersonate another
              person or misrepresent your affiliation with another person or
              entity; <br />
              reproduce, publicly display, or otherwise make accessible on or
              through any other website, application, or service any reviews,
              ratings, and/or profile information about real estate, lending, or
              other professionals, underlying images of or information about
              real estate listings, or other data or content available through
              our Site.
              <br />
              interfere with, or compromise the system integrity or security of
              our Site, or otherwise bypass any measures AFISADO may use to
              prevent or restrict access to our Site;
              <br />
              use any of AFISADO’s trademarks without approval, including but
              not limited to using them as part of your business name, screen
              name or email address on the Services; <br />
              access or use any of the Services to develop competitive products
              or services; or attempt to, or permit or encourage any third party
              to, do any of the above.
              <br />
            </p>
            <br />{" "}
            <h4 className="terms_subheading">
              SUBSCRIPTIONS, DELETE, SUSPEND & EARLY TERMINATION AND
              TERMINATION:{" "}
            </h4>
            <p className="terms_paragraph">
              Advertiser will be required to pay a subscription fee to access
              certain features of our Site and Service. If you pay any fees with
              a credit card, AFISADO may seek pre-authorization of your credit
              card account before your purchase to verify that the credit card
              is valid and has the necessary funds or credit available to cover
              your purchase. All fees are in U.S. dollars and is subject to a
              one-month Term, subscriptions will auto-renew each month until the
              subscription, is updated or terminated or suspended by Advertiser
              or AFISADO. Unless otherwise provided via Boost Marketing, after
              the initial Term and/or any renewal Term of Boost Marketing placed
              via an AFISADO business consultant, Advertiser will continue to be
              charged for the advertising set forth Term.
              <br />
              <span style={{ fontWeight: "bold" }}>
                MONTHLY SUBSCRIPTION FEE WILL NOT CONTINUE WHEN ADVERTISER
                SELECTS OPTION BUTTON TO SUSPENDED OR TERMINATE ACCOUNT
                FORTY-EIGHT (48) HOURS BEFORE RENEWAL FROM WITHIN SETTINGS OF
                ADVERTISER ACCOUNT.
              </span>
              <br />
              Likewise, Boost Marketing service(s) may cease by the actions of
              Advertiser discontinuation after the initial Term and/or renewal
              Term of Boost Marketing service(s) have completed. Any issues can
              be addressed via Support on Dashboard and creating a ticket.
              <br /> <br />
              If AFISADO changes the fees for all or part of our Site and
              Service, including by adding fees or charges, AFISADO will provide
              you advance notice of those changes. If you do not accept the
              changes, AFISADO may discontinue providing the applicable services
              to you. You authorize AFISADO to charge all fees as described in
              these Terms of Service for our Site you select to that payment
              method. <br /> <br />
              Upgrades/Downgrades. Advertisers who purchased a subscription plan
              may upgrade or downgrade their subscription at any time, subject
              to the current pricing.
            </p>
            <br /> <h4 className="terms_subheading">NONREFUNDABLE: </h4>
            <p className="terms_paragraph">
              All subscription services and Boost Marketing service(s) are
              non-refundable, and early termination of an active Term (A TERM IS
              ONE MONTH) is non-refundable. ANNUAL TERMS WILL BE PARTIALLY
              REFUNDED AFTER THE END OF A CURRENT ACTIVE MONTH TO THE REMAINING
              TERM OF THE ANNUAL PERIOD. AFISADO may, in its sole discretion,
              change the price to be paid by an Advertiser Boost Marketing
              Service, and base service effective as of the beginning of any
              monthly renewal term.
              <br />
              However: (A) prepaid amounts of an active monthly term are not
              refundable; (B) Advertiser will be billed the full amount for the
              then current Term; and (C) AFISADO may terminate Advertiser’s
              Boost Marketing service(s) at any time upon providing notice to
              Advertiser. Note that a Boost Marketing service(s) that includes
              the display of Ads on both AFISADO Sites and Licensee Sites is
              considered a single Ad and cannot be terminated separately.
            </p>
            <br /> <h4 className="terms_subheading">INDEMNIFICATION: </h4>
            <p className="terms_paragraph">
              Advertiser shall indemnify and defend AFISADO, its authorized
              business consultants, suppliers, and affiliates from any third
              party claim or liability arising out of: (i) Ad Materials, (ii)
              Advertiser’s violation of federal, state, local or any other laws
              or regulations; and (iii) Advertiser’s breach of this Agreement,
              the Boost Marketing service(s), or any other applicable terms and
              conditions, including but not limited to AFISADO Terms of Service.
            </p>
            <br />{" "}
            <h4 className="terms_subheading">
              CONSENT TO RECEIPT AND COMMUNICATIONS:{" "}
            </h4>
            <p className="terms_paragraph">
              All visitors and users of Site agrees that AFISADO, or a third
              parties may contact visitor and user after you have accepted to
              received contact for their service. Contact may come in the form
              of email, video chat, and or text message you have provided.
              Visitors and users further agree that such emails, calls and/or
              text messages may constitute advertising or telemarketing, e.g.
              AFISADO may email, call, or text with information about new
              service offerings available to Visitors and users of Site.
              Visitors and users of Site understands that agreeing to receive
              Other Messages that constitute advertising or telemarketing is not
              a condition to purchase. Visitors and users of Site further agrees
              that calls between Visitors and users of Site and consumers or
              AFISADO employees may be recorded for quality control, training,
              and other internal business purposes. For certain Services
              provided by AFISADO, Visitors and users of Site acknowledges that
              call recording may not be disabled by Visitors and users of Site.
            </p>
            <br />{" "}
            <h4 className="terms_subheading">
              COMPLIANCE WITH FAIR HOUSING LAWS:{" "}
            </h4>
            <p className="terms_paragraph">
              All Content is subject to federal fair housing laws, which make it
              illegal to indicate in any advertisement any preference,
              limitation, or discrimination because of race, color, religion,
              sex, physical or mental disability, and/or familial status. Your
              state jurisdiction may also prohibit any preferences based on
              sexual orientation, marital status, ancestry, source of income, or
              other criteria (together, applicable federal and state fair
              housing laws are referred to as “Applicable Fair Housing Laws”).
              If You have any question about the fair housing laws and housing
              discrimination in general, please call your local fair housing
              agency or the U.S. Department of Housing and Urban Development.
              For a list of all fair housing groups, go to the Housing Rights
              Center’s website at www.hud.gov. Your provision, receipt, and/or
              use of any Content offered or obtained through the Site shall be
              at all times in compliance with Applicable Fair Housing Laws.
              <br /> <br />
              Claims of Copyright Infringement: AFISADO respects the
              intellectual property rights of others, and asks Users of our Site
              to do the same. If you believe your work has been copied in a way
              that constitutes copyright infringement you may notify AFISADO
              according to the notice requirements of the Digital Millennium
              Copyright Act, 17 U.S.C. § 512(c)(2) (“DMCA”) by sending the
              following information to AFISADO copyright agent:
              <address>
                Attention : Copyright Agent,
                <br />
                1309 Coffeen Ave, Ste 1897
                <br />
                Sheridan, WY 82801
                <br />
              </address>
              <br />
              Describe with some detail the copyrighted work you are claiming
              has been infringed.
              <br />
              Identification of the material you are claiming is infringing,
              which needs removal, including a description of where the material
              is located on our Site and Service. In order that our copyright
              agent can locate it; <br />
              Your address, telephone number, and e-mail address. In order that
              our copyright agent can reach you.
              <br />A signed statement that the information from point 1-3 is
              accurate, that you have a good-faith belief that the identified
              use is not authorized by the copyright owner, its agent, or the
              law, and under penalty of perjury, that that you are the copyright
              owner or are authorized to act on the copyright owner's behalf.
              <br />
              By e-mail:
              <a href="mailto:DMCA_email@afisado.com">DMCA_email@afisado.com</a>
              <br /> <br />
              If you give notice of copyright infringement by e-mail, we may
              begin investigating the alleged copyright infringement; however,
              we must receive your signed as an attachment to your e-mail before
              we are required to take any action.
            </p>
            <br /> <h4 className="terms_subheading">Warranty Disclaimer: </h4>
            <p className="terms_paragraph">
              YOU CAPITULATE AND AGREE THAT USE OF OUR SITE IS PROVIDED “AS IS”
              AND RELIANCE ON ITS CONTENT AND CONSUMER PROVIDED INFORMATION IS
              AT YOUR OWN RISK. AFISADO, ITS AFFILIATES AND THEIR RESPECTIVE
              THIRD-PARTY LICENSORS DO NOT MAKE ANY REPRESENTATIONS OR
              WARRANTIES OF ANY KIND REGARDING OUR SITE, THE SOFTWARE, THE
              CONTENT, CONSUMER PROVIDED INFORMATION, THE MATERIALS, THE LISTING
              SERVICE, (COLLECTIVELY, THE “SERVICE”) OR THE RESULTS THAT MAY BE
              OBTAINED FROM USE OF ANY OF THE FOREGOING. THE SERVICE IS PROVIDED
              ON AN “AS IS, AS AVAILABLE” BASIS, AND AFISADO., ITS AFFILIATES,
              AND THEIR RESPECTIVE THIRD-PARTY LICENSORS SPECIFICALLY DISCLAIM
              ANY AND ALL EXPRESS OR IMPLIED WARRANTIES INCLUDING, WITHOUT
              LIMITATION, THE WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE,
              WARRANTIES OF QUIET ENJOYMENT, WARRANTIES OF TITLE, WARRANTIES OF
              ACCURACY, WARRANTIES OF NO ENCUMBRANCES, WARRANTIES OF NO LIENS,
              WARRANTIES OF MERCHANTABILITY AND WARRANTIES OF NONINFRINGEMENT.
              AFISADO, AND ITS AFFILIATES MAKE NO REPRESENTATIONS OR WARRANTIES,
              EXPRESSED OR IMPLIED, TO ANY USER OF OUR SITE AS TO THE EXISTENCE,
              OWNERSHIP OR CONDITION OF THE PROPERTIES DISPLAYED; AS TO THE
              ADVERTISED AVAILBILITIES, TERMS, OFFERING, AND FEES THAT ARE OR
              MAYBE ASSOCIATED WITH A PROPERTY DISPLAYED ON OUR SITE IF ANY; OR
              AS TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION ABOUT A
              PROPERTY APPEARING ON OUR SITE. AFISADO DOES NOT WARRANT THE
              ACCURACY OR COMPLETENESS OF SUCH INFORMATION. AFISADO RESERVES THE
              RIGHT, IN ITS SOLE DISCRETION, TO CORRECT ANY ERROR OR OMISSION ON
              OUR SITE OR IN THE CONTENT. ANY AND ALL CONCERNS, DIFFERENCES OR
              DISCREPANCIES REGARDING A PROPERTY MUST BE ADDRESSED WITH THE
              PROPERTY OWNER OR PROPERTY MANAGEMENT COMPANY. AFISADO DOES NOT
              MAKE AND EXPRESSLY DISCLAIMS ANY REPRESENTATIONS, WARRANTIES OR
              GUARANTEES TO YOU REGARDING THE LISTING SERVICE AND THE PROPERTY
              PROCUREMENT SERVICE, INCLUDING, WITHOUT LIMITATION, ANY
              REPRESENTATIONS, WARRANTIES OR GUARANTEES THAT YOU WILL RENT,
              LEASE, SELL OR BUY YOUR PROPERTY, OBTAIN ANYTHING FOR YOUR
              PROPERTY, ONLY RECEIVE LEGITIMATE INQUIRIES OR SOLICITATIONS FROM
              USERS FOR PURPOSES OF THIS WARRANTY DISCLAIMER, “THIRD-PARTY
              LICENSOR” DOES NOT INCLUDE YOU. LISTLINC LLC., ITS AFFILIATES, AND
              THEIR RESPECTIVE THIRD-PARTY LICENSORS SPECIFICALLY DISCLAIM ANY
              AND ALL EXPRESS OR IMPLIED WARRANTIES INCLUDING, WITHOUT
              LIMITATION, WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF
              DEALING OR USAGE OF TRADE; AND WARRANTIES OR CONDITIONS OF
              UNINTERRUPTED OR ERROR-FREE ACCESS OR USE. NO ADVICE OR
              INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE
              SERVICES OR ANY MATERIALS AVAILABLE THROUGH THE SERVICES WILL
              CREATE ANY WARRANTY REGARDING ANY AFISADO, ITS AFFILIATES AND
              THEIR RESPECTIVE THIRD-PARTY LICENSORS THAT IS NOT EXPRESSIVELY
              STATED IN THESE TERMS OF SERVICE. YOU ASSUME ALL RISK FOR ANY
              DAMAGE THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO THE SITE,
              YOUR DEALING WITH ANY OTHER USER, AND ANY MATERIALS, INCLUDING ALL
              USER AND AFISADO MATERIALS, AVAILABLE THROUGH OUR SITE. YOU
              UNDERSTAND AND AGREE THAT YOUR USE OF OUR SITE, AND USE, ACCESS,
              DOWNLOAD, OR OTHERWISE OBTAINMENT OF MATERIALS THROUGH THE
              SERVICES AND ANY ASSOCIATED SITES OR SERVICES, ARE AT YOUR OWN
              DISCRETION AND RISK, AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY
              DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
              DEVICE USED IN CONNECTION WITH THE SERVICES), OR THE LOSS OF DATA
              THAT RESULTS FROM THE USE OF THE SERVICES OR THE DOWNLOAD OR USE
              OF THOSE MATERIALS. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER
              OR WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM
              JURISDICTION TO JURISDICTION.
            </p>
            <br />{" "}
            <h4 className="terms_subheading">Limitation of Liability </h4>
            <p className="terms_paragraph">
              AFISADO, ITS AFFILIATES, AND THEIR RESPECTIVE OWNERS, EMPLOYEES,
              AGENTS, CONTRACTORS AND THIRD-PARTY LICENSORS SHALL IN NO EVENT BE
              LIABLE FOR ANY DAMAGES OR LOSSES INCLUDING, WITHOUT LIMITATION,
              DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL OR PUNITIVE
              DAMAGES, RESULTING FROM OR CAUSED BY OUR SITE AND SERVICE OR
              OTHERWISE, INCLUDING, WITHOUT LIMITATION, DAMAGES RESULTING FROM
              NEGLIGENCE. IN NO EVENT WILL THE AGGREGATE MAXIMUM LIABILITY OF
              APARTMENTS.COM, ITS AFFILIATES, AND THEIR RESPECTIVE OWNERS,
              EMPLOYEES, AGENTS, CONTRACTORS AND THIRD-PARTY LICENSORS FOR ANY
              CLAIM ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE
              EXCEED THE TOTAL AMOUNT OF FEES ACTUALLY PAID BY YOU TO AFISADO.
              THE NEGATION OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT
              OF THE BASIS OF THE BARGAIN BETWEEN AFISADO AND YOU. THE SERVICE
              WOULD NOT BE PROVIDED TO YOU WITHOUT SUCH LIMITATIONS. NO ADVICE
              OR INFORMATION WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
              AFISADO, CREATES ANY WARRANTY, REPRESENTATION AND/OR GUARANTEE NOT
              EXPRESSLY STATED IN THIS AGREEMENT. FOR PURPOSES OF THIS
              LIMITATION OF LIABILITY, “THIRD-PARTY LICENSOR” DOES NOT INCLUDE
              YOU.
            </p>
            <br />{" "}
            <h4 className="terms_subheading">Termination of Service: </h4>
            <p className="terms_paragraph">
              You understand and agree that in AFISADO’s sole discretion, and
              without prior notice, your access to our Site may be terminated or
              suspended, and AFISADO may exercise any other remedy available and
              remove any Content, if it believes that your use of our Site
              and/or any Content or Reference Information You provide (a)
              violates (i) these Terms of Service, (ii) the rights of AFISADO,
              its affiliates, a Licensor, or another User, or (iii) any law or
              regulation, or are otherwise objectionable or inappropriate or (b)
              constitute fraudulent or unlawful activity of any nature. You
              agree that monetary damages may not provide a sufficient remedy to
              AFISADO for violations of these Terms of Service, and You consent
              to injunctive or other equitable relief for such violations
              without the requirement that AFISADO post a bond. AFISADO is not
              required to provide any refund to you if your use is terminated as
              a result of AFISADO’s determination, in its sole discretion, that
              you have violated these Terms of Service.
            </p>
            <br /> <h4 className="terms_subheading">Waiver </h4>
            <p className="terms_paragraph">
              You understand and agree that any individual or entity that
              violates any term of these Terms of Service is precluded from
              enforcing, agrees not to enforce, and covenants not to sue to
              enforce, any similar or related provision in any contract,
              including terms and conditions, Terms of Service, asserted by such
              individual, entity or any affiliate thereof, as binding upon
              AFISADO or any of its affiliates.
            </p>
            <br />{" "}
            <h4 className="terms_subheading">Terms of Service Changes </h4>
            <p className="terms_paragraph">
              These Terms of Service may be changed at any time and you will be
              notified of any such changes by an updated posting of the new
              Terms of Service on this page of the Site. Your continued use of
              the Site after the posting of any amended Terms of Service shall
              constitute your agreement to be bound by any such changes. AFISADO
              may modify, suspend, discontinue or restrict the use of any
              portion of our Site without notice or liability.
              <br />
              <br />
              You may contact AFISADO by emailing us at
              <a href="mailto:support@afisado.com ">support@afisado.com</a>
              or mail us at
              <address>
                Atten: Support
                <br />
                1309 Coffeen Avenue, Ste 1897
                <br />
                Sheridan, WY 82801.
                <br />
              </address>
            </p>
            <br /> <h4 className="terms_subheading">Notice of Disputes: </h4>
            <p className="terms_paragraph">
              Any dispute, claim, or controversy, including but not limited to
              those asserting statutory claims under federal or state law,
              arising out of or relating to the Terms of Service; the breach,
              termination, enforcement, interpretation, or validity of the Terms
              of Service; or any use of AFISADO or our Site, are governed by
              District of Wyoming law, without regard to its conflict of laws
              principles. You hereby agree that any cause of action you may have
              with respect to our Site must be exclusively filed in the federal
              or state courts located in the District of Wyoming. We make no
              representation that the Site are appropriate or available for use
              in other locations. You hereby consent to personal jurisdiction in
              the federal and state courts in the District of Wyoming, and waive
              any objection based on forum non conveniens. As a condition of
              using our Site, You agree that all causes of action arising out of
              or connected with our Site shall be resolved individually, without
              resort to any form of class action. If for any reason a court of
              competent jurisdiction finds any provision of these Terms of
              Service, or portion thereof, to be unenforceable, that provision
              of these Terms of Service will be enforced to the maximum extent
              permissible so as to affect the intent of the parties, and the
              remainder of these Terms of Service will continue in full force
              and effect. Failure by AFISADO to enforce any provision of this
              Terms of Service will not be deemed a waiver of future enforcement
              of that or any other provision of these Terms of Service. These
              Terms of Service and any other terms and conditions or policies
              applying to your use of our Site, constitute the entire agreement
              between the parties regarding the subject matter hereof. Nothing
              contained herein shall be construed as creating a partnership,
              joint venture or agency relationship or granting a franchise
              between the parties. Notwithstanding anything to the contrary
              herein, if you and AFISADO have entered into a separate written
              AFISADO advertising contract, the terms and conditions of such
              contract shall control with respect to any term that is
              inconsistent with these Terms of Service. IF YOU DO NOT AGREE TO
              THE TERMS STATED ABOVE OR TO ANY CHANGES TO THESE TERMS OF
              SERVICE, PLEASE EXIT THE SERVICE IMMEDIATELY.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
