import React from 'react'

const guestVirtualOfficeMyMessages = () => {
  return (
    <div>
            (coming soon)

    </div>
  )
}

export default guestVirtualOfficeMyMessages
