import React from 'react'
import { Button } from 'react-bootstrap'
import { FaLongArrowAltRight } from 'react-icons/fa'
import "./Adv_Why_Home.scss"
const Adv_Why_Home = () => {
  return (
    <section className='advert border-0 text-center  justify-content-center'>
      {/* <h2 className='py-2  typicalHeading text-center bg-danger h-25'>Why Homer?</h2> */}
      <h2 className='mb-3 fw-bold'>Why Home</h2>

      {/* ------------------------------------------------------------------------------------- */}
      <div className='Adv_pop_content bg-white'>
        <div className='d-flex demo text-center justify-content-center'>
        <div className='pop_design_content'>
          <div className='text-center exp'>
            <h4 className='type_exp_heading text-start'>Typical Experience</h4>
          </div>

          <div className='d-flex py-4 mt-3'>
            <div className='typical_p1 text-start'>Design</div>
            <div className='typical_p text-start'>Do It yourself</div>
          </div>
          {/* ---*/}
          <div className='d-flex py-4'>
            <div className='typical_p1 text-start'>Materials</div>
            <div className='typical_p text-start'>Order yourself</div>
          </div>
          {/* ---- */}
          <div className='d-flex py-4'>
            <div className='typical_p1 text-start'>Project Management</div>
            <div className='typical_p text-start'>Complete chao and management hell </div>
          </div>
          {/* ----- */}
          <div className='d-flex py-4'>
            <div className='typical_p1 text-start '>Construction</div>
            <div className='typical_p text-start'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has</div>
          </div>
          {/* ----- */}
          <div className='d-flex py-4'>
            <div className='typical_p1 text-start'>Build Time</div>
            <div className='typical_p text-start'>Lorem Ipsum is simply dummy</div>
          </div>
          {/* -- */}
          <div className='d-flex py-4'>
            <div className='typical_p1 text-start '>Pricing</div>
            <div className='typical_p text-start'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has</div>
          </div>
          {/* -------------------------------------------btn------------------------------------------------------------------- */}
          
        </div>


        <div className='Adv_pop_card  text-center justify-content-center' >
          <div className='pop_design_card text-start text-white bold-0 p-4'>
            <h4 className='py-2 my-2 px-5'>The Homer Way</h4>
            <p className='py-3 my-2'>Leading designers in house</p>
            <p className='py-3 my-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            <p className='py-3 my-2'>Lorem Ipsum is simply </p>
            <p className='py-3 my-2'>Lorem Ipsum is simply </p>
            <p className='py-3 my-2'>Lorem Ipsum is simply </p>
            <p className='py-3 my-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        </div>
      
        <div className='border-0 btn_content'>
            <h4 className='Typical_Heading '>Ready to get started?</h4>
            <Button size="lg" className=" app-blue-btn border-0 py-3" active>
              Learn More
              <FaLongArrowAltRight className='mx-5' />
            </Button>
          </div>
      </div>
    </section>
  )
}

export default Adv_Why_Home
