import React, { useState } from "react";
import "./profile.scss";
import Liancence from "./components/liancence/Liancence";
import Teams from "./components/teams/Teams";
import SocialMedia from "./components/socialMedia/SocialMedia";
import Passwordsec from "./components/password/Password";
import Minute from "./components/minute/Minute";
import Personal from "./components/personal/Personal";
import NeighborhoodSpecialist from "../neighbourhoodspecialist/component/NeighborhoodSpecialist/NeighborhoodSpecialist";

import { Col, Nav, Row, Tab } from "react-bootstrap";
import { addOn } from "../../../appVariable/variable";
import { NavLink, useOutletContext } from "react-router-dom";
import StatWatch from "../neighbourhoodspecialist/component/statWatch/StatWatch";
// import ProfilePassPopup from './components/modals/ProfilePassPopup'

const Profile = () => {
  // let { id } = useParams();
  // let token = getToken()
  // let add_on = token?.user_info.add_on;
  let plan = addOn();
  // console.log(plan.add_on)
  const [showLicense, setShowLicense] = useState(false);
  const [profTab, setProfTab] = useState("second");
  // const [profTab, setProfTab] = useState(plan.add_on ? "first" : "second")

  const ChangeTab = (key) => {
    setProfTab(key);
  };
  const context = useOutletContext();

  return (
    <>
      <div className="profile-sec flex-grow-1">
        <Tab.Container
          id="left-tabs-example"
          // defaultActiveKey="first"
          activeKey={profTab}
          onSelect={ChangeTab}
        >
          <Row>
            <Col lg={2} className="profile-left-tab ">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="second">Personal Information</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Brokerage Information</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">Social Media</Nav.Link>
                </Nav.Item>
                {plan.add_on && (
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      Neighborhood Specialist
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link eventKey="seven">30 Minute Showing</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sixth">Password & Security</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="eight">StatWatch</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link>
                    <NavLink
                      className="public-view m-0 p-0"
                      to="/agent-dashboard/Agentprofile"
                    >
                      My Public Website
                    </NavLink>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={10} className="tab-middle-sec hide-scrollbar blure-shadow">
              <Tab.Content>
                {plan.add_on && (
                  <Tab.Pane eventKey="first">
                    {profTab === "first" && (
                      <NeighborhoodSpecialist ChangeTab={ChangeTab} />
                    )}
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="second">
                  <Personal
                    ChangeTab={ChangeTab}
                    setShowLicense={setShowLicense}
                    showLicense={showLicense}
                  />
                  {/* {profTab === "second" && < Personal ChangeTab={ChangeTab} />} */}
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  {profTab === "third" && (
                    <Liancence
                      ChangeTab={ChangeTab}
                      setUpdatesidebar={context.setUpdatesidebar}
                      updatesidebar={context.updatesidebar}
                      setShowLicense={setShowLicense}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  {profTab === "fourth" && <Teams ChangeTab={ChangeTab} />}
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  {profTab === "fifth" && <SocialMedia ChangeTab={ChangeTab} />}
                </Tab.Pane>
                <Tab.Pane eventKey="sixth">
                  {profTab === "sixth" && <Passwordsec ChangeTab={ChangeTab} />}
                </Tab.Pane>
                <Tab.Pane eventKey="seven">
                  {profTab === "seven" && <Minute />}
                </Tab.Pane>
                <Tab.Pane eventKey="eight">
                  {profTab === "eight" && <StatWatch />}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      {/* <ProfilePassPopup /> */}
    </>
  );
};

export default Profile;
