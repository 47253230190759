// icons
import Dashboard from "./Dashboard.svg"
import list from "./list.svg"
import message from "./message.svg"
import mortage from "./mortage.svg"
import office from "./office.svg"
import Reviews from "./Reviews.svg"
import search from "./search.svg"
import settings from "./settings.svg"
import support from "./support.svg"
import allsale from "./allsale.svg"
import analytics from "./analytics.svg"
import boost from "./boost.svg"
import current from "./current.svg"
import documents from "./documents.svg"
import expire from "./expire.svg"
import virtualOffice from "./virtualOffice.svg"
import Contact from "./Contact.svg"
import CustomerInfo from "./CustomerInfo.svg"
import like from "./like.svg"
import dislike from "./dislike.svg"
import filter from "./filter.svg"
import logout from "./logout.svg"
import prof from "../../../../agentbio/image/prof.jpg"
import commercialMarker from "./commercialMarker.svg"
import commercialmarkerIcon from "./commercialmarkerIcon.png"
import profile from "./profile.png"
import coverphoto from "./bgprofile.png"


const icons = {
    Dashboard,
    list,
    message,
    mortage,
    office,
    Reviews,
    search,
    settings,
    support,
    allsale,
    analytics,
    boost,
    current,
    documents,
    expire,
    virtualOffice,
    Contact,
    CustomerInfo,
    like,
    dislike,
    profile,
    coverphoto,
    prof,
    filter,
    logout,
    commercialMarker,
    commercialmarkerIcon

}
export default icons