import React from 'react'

const WedoCard = (props) => {
    return (
        <>
            <div className="what-card d-flex justify-content-center flex-column align-items-center"
            id={props.cardinfo.id}>
                <div className="card-icon-outer center">
                    <div className="card-icon">
                        <img src={props.cardinfo.icon} alt="" />

                    </div>
                </div>
                <h6>{props.cardinfo.title}</h6>
                <p>{props.cardinfo.description} </p>
            </div>
        </>
    )
}

export default WedoCard
