
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Passcheck } from './Password';

const ConfirmPassword = (props) => {
    const [setshowpass, setSetshowpass] = useState(false)
    return (
        <>
            <Modal show={true} centered
                size="lg"
                onHide={() => props.setConfirmPassModal(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className=" pb-0" closeButton>
                    <Modal.Title className=" w-100" id="example-custom-modal-styling-title"><p className=" text-center text-capitalize">Enetr backend password</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className=' mb-3'>
                        <div className="d-flex pb-1" style={{ borderBottom: "2px solid gray" }}>
                            <div className=" pe-3 d-flex align-items-center text-dark" style={{ borderRight: "2px solid gray" }}>
                                <p className='text-dark' >Backend Password<span className="red-star">*</span></p>
                            </div>
                            <div className=" d-flex flex-grow-1" >
                                <input
                                    type={setshowpass ? "text" : "password"}
                                    className="form-control border-0 pb-0"
                                    name="oldPswrd"
                                    onChange={(e) => { props.setBackendPass(e.target.value) }}
                                    value={props.backendPass}
                                />
                                <div style={{ cursor: "pointer" }} onClick={() => setSetshowpass(prevState => !prevState)}>
                                    {setshowpass ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                </div>
                            </div>

                        </div>
                        <div className=" text-end mt-3 media_pading p-0">
                            <button className='save_btn '
                                disabled={!(Passcheck.test(props.backendPass))}
                                onClick={() => props.removeProfilePassword()}
                            >Remove backend Password</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmPassword
