import { Padding, Start } from '@mui/icons-material'
import React from 'react'
import { Button } from 'react-bootstrap'
import { FaLongArrowAltRight } from 'react-icons/fa'
import Ellipse from '../../ImgAdv/Ellipse.png'
import fiveStar from '../../ImgAdv/fiveStar.png'
import "./Saying.scss"

const Saying = () => {
 
  return (
    <div className='Adv_pop_user_pro text-center justify-content-center text-center  position-relative pb-5'>
      <div className='adv_content justify-content-center align-center text-center'>
        <h1 className='sy-heading fw-bold'>What Your Customers Are Saying</h1>
        <div className=' bg-white custom_name text-center align-content-center justify-content-center d-block p-5 position-relative'>
          <h2 className='smit_name'>David Smith</h2>

          <img src={fiveStar} className='fiveStar_Img mt-3' alt="#" />
          <div className='text-center lorem_text '>Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </div>
          <img src={Ellipse} className='position-absolute rounded-circle user_circle__Img ' alt="#" style={{}} />
        </div>
        <h4 className='mt-5'>See What Other People Are Saying </h4>
        <div className='point_text justify-content-center'>
          <div className='text-center'>
            <img src={fiveStar} className='fiveStar_Img2' alt="#" />
          </div>
          <div className='Ad_star_point'>
            <p className='my-3'>4.56 / 5.0</p>
          </div>
        </div>
        {/* <Button size="lg" className=" app-blue-btn border-0 py-3 mb-5" active>
          Read 1,262 more reviews
          <FaLongArrowAltRight className='mx-5' />
        </Button> */}
      </div>
    </div>
  )
}

export default Saying

