import React from "react";
import FilterResultMap from "../FilterResultMap";
import ResidentialMapFilter from "../ResidentialMapFilter";
import CommertialFilter from "./CommertialFilter";
import FilterTop from "./FilterTop";

const MapViewCommfilter = () => {
  return (
    <>
      <div className=" d-flex flex-column gap-4 flex-grow-1">
        <FilterTop />
        <div className="h-100 w-100 d-flex gap-4">
          <CommertialFilter />
          <div className=" flex-grow-1">
            {" "}
            <FilterResultMap />
          </div>
        </div>
      </div>
    </>
  );
};

export default MapViewCommfilter;
