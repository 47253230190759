import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./../../guestVirtualOffice.scss";
const NoteModal = (props) => {
  console.log(props.data.note, "propsprops");
  const [text, settext] = useState(props.data.note);
  return (
    <>
      {/* <div className="add-note-modal-parent"> */}
      <Modal
        show={true}
        centered
        size="lg"
        onHide={() => props.setshownote(false)}
        // dialogClassName="modal-90w add-note-modal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop={true}
      >
        <Modal.Header className=" pb-0" closeButton>
          <Modal.Title
            className="w-100"
            id="example-custom-modal-styling-title"
          >
            <p className="custom-heading-note text-capitalize">
              Notes For You And Your Agent
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-note-modal">
            <div className=" mb-4">
              <div
                className="d-flex pb-1"
                style={{ borderBottom: "2px solid gray" }}
              >
                <div
                  className=" pe-3 d-flex align-items-center text-dark"
                  style={{ borderRight: "2px solid gray" }}
                >
                  <p className="text-dark">
                    Add Note<span className="red-star">*</span>
                  </p>
                </div>
                <div className=" d-flex flex-grow-1">
                  <textarea
                    type="text"
                    className="form-control border-0 pb-0"
                    name="oldPswrd"
                    onChange={(e) => settext(e.target.value)}
                    defaultValue={text}
                    // value={formik.values.oldPswrd}
                  />
                  {/* <div style={{ cursor: "pointer" }} onClick={() => setSetshowpass(prevState => !prevState)}>
                                            {setshowpass ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                        </div> */}
                </div>
              </div>
              <div className=" text-end mt-3 media_pading p-0">
                <button
                  className="save_btn"
                  onClick={() => props.sendNote(text)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default NoteModal;
