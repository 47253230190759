import React, { useState } from "react";
import "./socialMedia.scss";
import linkedin from "./images/linkedin.svg";
import twitter from "./images/Twitter.svg";
import facebook from "./images/Facebook.svg";
import instagram from "./images/Instagram.svg";
import youtub from "./images/Youtub.svg";
import TikTok from "./images/TikTok.svg";
import { useFormik } from "formik";
import { getAgentDetails,  submitSocialMediaData1 } from "./server";
import { getToken } from "../../../../../appVariable/variable";
import * as Yup from "yup";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
const SocialMedia = (props) => {
  // const regex = /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
  const regex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  const [formDisable, setFormDisable] = useState(false)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      linkedInURL: "",
      faceBookURL: "",
      youtubeURL: "",
      twitterURL: "",
      instagramURL: "",
      tiktokURL: "",
    },

    validationSchema: Yup.object({
      linkedInURL: Yup.string().matches(regex, "Link should be a valid URL").nullable(),
      faceBookURL: Yup.string().matches(regex, "Link should be a valid URL").nullable(),
      youtubeURL: Yup.string().matches(regex, "Link should be a valid URL").nullable(),
      twitterURL: Yup.string().matches(regex, "Link should be a valid URL").nullable(),
      instagramURL: Yup.string().matches(regex, "Link should be a valid URL").nullable(),
      tiktokURL: Yup.string().matches(regex, "Link should be a valid URL").nullable(),
    }),
    onSubmit: (values) => {
      setFormDisable(false)
      let tempObj = {};
      let userDetails = getToken()
      tempObj["user_id"] = userDetails.userId
      tempObj["linkedin_url"] = formik.values.linkedInURL;
      tempObj["facebook_url"] = formik.values.faceBookURL;
      tempObj["twitter_url"] = formik.values.twitterURL;
      tempObj["instagram_url"] = formik.values.instagramURL;
      tempObj["youtube_url"] = formik.values.youtubeURL;
      tempObj["tictok_url"] = formik.values.tiktokURL;
      // console.log("submittemp", tempObj)
      if (formik.values.linkedInURL || formik.values.faceBookURL || formik.values.youtubeURL || formik.values.twitterURL || formik.values.instagramURL || formik.values.tiktokURL) {
        // console.log("not error object")
        setLoading(true)
        submitSocialMediaData1(tempObj, (success) => {
          toast.success(success, "success")
          setLoading(false)
          // props.ChangeTab("sixth")
        })
      }
      else {
        setFormDisable(true)
      }
    },
  });



  function getSocialmediaDetails() {
    getAgentDetails((success) => {
      console.log("social succes", success[0])
      formik.setFieldValue("linkedInURL", success[0].linkedin_url)
      formik.setFieldValue("faceBookURL", success[0].facebook_url)
      formik.setFieldValue("twitterURL", success[0].twitter_url)
      formik.setFieldValue("instagramURL", success[0].instagram_url)
      formik.setFieldValue("youtubeURL", success[0].youtube)
      formik.setFieldValue("tiktokURL", success[0].tiktok_url)

    })
  }
  useEffect(() => {
    getSocialmediaDetails()
  }, [])
  return (
    <div className="media_pading">
      {loading ? <Loader /> : null}
      <div className="row">
        <div className="col-sm-12 dading_text">Link your Social Media</div>
        <div className="col-md-6">
          <div className="d-flex main_input">
            <div className="input_name grow-1 text-end">
              <img src={linkedin} alt="" className="input_img" />
            </div>
            <div className="input-sec">
              <input
                type="email"
                className="form-control border-0 pb-0"
                placeholder="Linkedin URL"
                name="linkedInURL"
                onChange={formik.handleChange}
                value={formik.values.linkedInURL}
              />
              {formik.errors.linkedInURL && formik.touched.linkedInURL ? (
                <div className='validation-text'>{formik.errors.linkedInURL}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex main_input">
            <div className="input_name grow-1 text-end">
              {/* Brokerage Name <span className="red-star" >*</span> */}
              <img src={twitter} alt="" className="input_img" />
            </div>
            <div className="input-sec">
              <input
                type="text"
                className="form-control border-0 pb-0"
                placeholder="Twitter URL"
                name="twitterURL"
                onChange={formik.handleChange}
                value={formik.values.twitterURL}
              />
              {formik.errors.twitterURL && formik.touched.twitterURL ? (
                <div className='validation-text'>{formik.errors.twitterURL}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex main_input">
            <div className="input_name grow-1 text-end">
              {/* Brokerage Name <span className="red-star" >*</span> */}
              <img src={facebook} alt="" className="input_img" />
            </div>
            <div className="input-sec">
              <input
                type="text"
                className="form-control border-0 pb-0"
                placeholder="Facebook URL"
                name="faceBookURL"
                onChange={formik.handleChange}
                value={formik.values.faceBookURL}
              />
              {formik.errors.faceBookURL && formik.touched.faceBookURL ? (
                <div className='validation-text'>{formik.errors.faceBookURL}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex main_input">
            <div className="input_name grow-1 text-end">
              {/* Brokerage Name <span className="red-star" >*</span> */}
              <img src={instagram} alt="" className="input_img" />
            </div>
            <div className="input-sec">
              <input
                type="email"
                className="form-control border-0 pb-0"
                placeholder="Instagram URL"
                name="instagramURL"
                onChange={formik.handleChange}
                value={formik.values.instagramURL}
              />
              {formik.errors.instagramURL && formik.touched.instagramURL ? (
                <div className='validation-text'>{formik.errors.instagramURL}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex main_input">
            <div className="input_name grow-1 text-end">
              {/* Brokerage Name <span className="red-star" >*</span> */}
              <img src={youtub} alt="" className="input_img" />
            </div>
            <div className="input-sec">
              <input
                type="email"
                className="form-control border-0 pb-0"
                placeholder="Youtube URL"
                name="youtubeURL"
                onChange={formik.handleChange}
                value={formik.values.youtubeURL}
              />
              {formik.errors.youtubeURL && formik.touched.youtubeURL ? (
                <div className='validation-text'>{formik.errors.youtubeURL}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex main_input">
            <div className="input_name grow-1 text-end">
              {/* Brokerage Name <span className="red-star" >*</span> */}
              <img src={TikTok} alt="" className="input_img" />
            </div>
            <div className="input-sec">
              <input
                type="email"
                className="form-control border-0 pb-0"
                placeholder="TikTok URL"
                name="tiktokURL"
                onChange={formik.handleChange}
                value={formik.values.tiktokURL}
              />
              {formik.errors.tiktokURL && formik.touched.tiktokURL ? (
                <div className='validation-text'>{formik.errors.tiktokURL}</div>
              ) : null}
            </div>
          </div>
        </div>

        {formDisable && <div className='validation-text'>Please fill at least one URL</div>}

        <div className="col-sm-12 d-flex justify-content-end">
          <button className="save_btn" type="submit" onClick={formik.handleSubmit}  >Submit</button>
        </div>
      </div>
      <div className="col-sm-12"></div>
    </div>
  );
};

export default SocialMedia;
