import React, { useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
// import CloseIcon from '@mui/icons-material/Close';
import "./agentmodal.scss"
// import { Container, Draggable } from 'react-smooth-dnd';

const ImageModal = (props) => {
    const { modalShow, setModalShow, images, formik } = props
    const [orderImages, setOrderImages] = useState(images)
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const handleSortImg = () => {
        //duplicate items
        let fruitItems = [...orderImages];
        //remove and save the dragged item content
        const draggedItemContent = fruitItems.splice(dragItem.current, 1)[0];

        //switch the position
        fruitItems.splice(dragOverItem.current, 0, draggedItemContent);

        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;

        //update the actual array
        setOrderImages(fruitItems);
        // formik.setFieldValue("propertymainimage", fruitItems);

    };



    return (
        <>
            <Modal show={modalShow} size="lg" centered
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {images ? <p>{`You select  ${images.length} Images`}</p> : ""}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='property-image-modal'>
                        <div className="list-sort d-flex gap-3 flex-wrap ">
                            {orderImages.map((item, index) => (
                                <div key={index} draggable className="list-item position-relative"
                                    onDragStart={(e) => (dragItem.current = index)}
                                    onDragEnter={(e) => (dragOverItem.current = index)}
                                    onDragEnd={handleSortImg}
                                    onDragOver={(e) => e.preventDefault()}
                                >
                                    {/* <div className="cross-icon-box center pointer position-absolute top-0 start-100 translate-middle">
                                        <CloseIcon style={{ fontSize: '15px' }} />
                                    </div> */}
                                    <img alt="" value={index}
                                        // src={item} 
                                        src={item ? URL.createObjectURL(item) : null}
                                        style={{ margin: "2px", width: '100px' }} className="img-preview h-100"

                                    />
                                </div>
                            ))}
                        </div>


                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ImageModal
