import React, { useEffect } from "react";
import "./Mainreview.scss";
import Reviewcards from "./Reviewcards";
import Reviewcardshorizontal from "./Reviewcardshorizontal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
// import close from "./images/close.svg"
import { Rating } from "@mui/material";
import moment from "moment";
import { AgentRating, GetAgentRating, GetMemebersForReview } from "../Server";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";

export default function Mainreview() {
  let currentDate = new Date();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [teamMember, setTeamMember] = useState([]);
  //form inptr
  const [knowledge, setKnowledge] = useState(0);
  const [professionalisn, setProfessionalisn] = useState(0);
  const [service, setService] = useState(0);
  const [respectful, setRespectful] = useState(0);
  const [overall, setOverall] = useState(0);
  const [experiance, setExperiance] = useState("");
  const [meetOrNot, setMeetOrNot] = useState(false);
  //form input end
  const [currentuser, setCurrentuser] = useState();

  //agent rating data
  const [givenRatingData, setGivenRatingData] = useState([]);

  const TeammerberProfile = () => {
    GetMemebersForReview((success) => {
      if (success.data.code === 200 && success.data.status === "success") {
        let info = success.data.data?.map((person) => {
          return {
            id: person.id,
            profile: person.profile_image,
            name: `${person.first_name} ${person.last_name}`,
            rating: person.rating,
          };
        });
        setTeamMember(info);
      }
      if (success.data.code === 400 && success.data.status === "failed") {
        setTeamMember([]);
      }
    });
  };

  const GetReview = () => {
    GetAgentRating((success) => {
      if (success.data.code === 200 && success.data.status === "success") {
        if (success.data.data === "No Data Found") {
          setGivenRatingData([]);
        } else {
          setGivenRatingData(success.data.data);
        }
        setLoading(false);
      }
      // if (success.data.code === 400 && success.data.status === "failed") { console.log(success.data.data) }
    });
  };

  const submitReview = () => {
    let userdata = {
      AgentUser: currentuser.id,
      meet: meetOrNot,
      Knowledge: knowledge,
      Professionalism: professionalisn,
      Customer_Service: service,
      respectful: respectful,
      Recommend: overall,
      experience: experiance,
      created_date: moment(currentDate).format("YYYY-MM-DD"),
    };

    if (experiance?.length > 5) {
      setLoading(true);
      AgentRating(userdata, (success) => {
        if (success.data.code === 200 && success.data.status === "success") {
          GetReview();
          TeammerberProfile();
          setLoading(false);
          setModalShow(false);
          toast.success(success.data.data, "success");
          setKnowledge(0);
          setProfessionalisn(0);
          setService(0);
          setRespectful(0);
          setOverall(0);
          setExperiance("");
          setMeetOrNot(false);
        }
        if (success.data.code === 400 && success.data.status === "failed") {
          toast.error(success.data.data, "error");
          setExperiance("");
          setLoading(false);
          setModalShow(false);
        }
      });
    } else {
      toast.error("Enter experience with more than 5 chracters");
    }
  };

  useEffect(() => {
    setLoading(true);
    TeammerberProfile();
    GetReview();
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="main_reviewED_section">
        <Row>
          <Col md="7 d-flex flex-wrap" className=" left-sec hide-scrollbar ">
            {teamMember ? (
              teamMember?.map((member, i) => (
                <Reviewcards
                  key={`member${i}`}
                  member={member}
                  setModalShow={setModalShow}
                  setCurrentuser={setCurrentuser}
                  gotReview={
                    givenRatingData.length
                      ? !!givenRatingData.filter(
                          ({ AgentUser }) => AgentUser.id === member.id
                        ).length
                      : false
                  }
                />
              ))
            ) : (
              <p className="pri-text-clr">
                You don't have any connections to review at the moment.
              </p>
            )}
          </Col>
          <Col md="5" className=" right-sec hide-scrollbar">
            <div className="review-group">
              <div className="">
                {givenRatingData?.map((user, i) => (
                  <Reviewcardshorizontal
                    key={`userrating${i}`}
                    userRating={user}
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="forgot_modals">
        <Modal
          className="forgot_modals"
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          ria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="border-bottom-0 Review_Warren_modals gdgyg"
          >
            <Modal.Title
              id="contained-modal-title-vcenter "
              className="border-0"
            >
              <div className="forgot_title">
                <h5 className="ps-2">Write A Review {currentuser?.name}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 pt-2 Review_Warren_modals">
            <Row>
              <Col sm={6} className="pb-3">
                <h6 className="">Did You Meet with {currentuser?.name}</h6>
              </Col>
              <Col
                sm={6}
                className="text-sm-end pb-3 justify-content-end gap-3 d-flex"
              >
                <div className=" center gap-1">
                  <input
                    type="radio"
                    name="meet"
                    id="meetyes"
                    value={false}
                    onChange={(e) => {
                      setMeetOrNot(true);
                    }}
                  />
                  <label htmlFor="meetyes" className="formtext pointer">
                    Yes
                  </label>
                </div>
                <div className=" center gap-1">
                  <input
                    type="radio"
                    name="meet"
                    id="meetno"
                    value="exclusive"
                    onChange={(e) => {
                      setMeetOrNot(false);
                    }}
                  />
                  <label htmlFor="meetno" className="formtext pointer">
                    No
                  </label>
                </div>
              </Col>
              <Col sm={6} className="pb-3">
                <p>Knowledge</p>
              </Col>
              <Col
                sm={6}
                className="text-sm-end pb-3 d-flex gap-2 justify-content-end star_orange"
              >
                <Rating
                  className="rating"
                  name="simple-controlled"
                  value={knowledge}
                  onChange={(event, newValue) => {
                    setKnowledge(newValue);
                  }}
                />
              </Col>

              <Col sm={6} className="pb-3">
                <p>Professionalism</p>
              </Col>
              <Col
                sm={6}
                className="text-sm-end pb-3 d-flex gap-2 justify-content-end star_orange"
              >
                <Rating
                  className="rating"
                  name="simple-controlled"
                  value={professionalisn}
                  onChange={(event, newValue) => {
                    setProfessionalisn(newValue);
                  }}
                />
              </Col>

              <Col sm={6} className="pb-3">
                <p> Customer Service</p>
              </Col>
              <Col
                sm={6}
                className="text-sm-end pb-3 d-flex gap-2 justify-content-end star_orange"
              >
                <Rating
                  className="rating"
                  name="simple-controlled"
                  value={service}
                  onChange={(event, newValue) => {
                    setService(newValue);
                  }}
                />
              </Col>

              <Col sm={6} className="pb-3">
                <p>Respectful </p>
              </Col>
              <Col
                sm={6}
                className="text-sm-end pb-3 d-flex gap-2 justify-content-end star_orange"
              >
                <Rating
                  className="rating"
                  name="simple-controlled"
                  value={respectful}
                  onChange={(event, newValue) => {
                    setRespectful(newValue);
                  }}
                />
              </Col>

              <Col sm={12} className="pb-3 How_likely">
                <p className=" mb-2">
                  How likely are you to recommend{" "}
                  <span className=" text-capitalize">{currentuser?.name}</span>
                </p>
                <div className="star_orange">
                  <Rating
                    className="rating"
                    name="simple-controlled"
                    value={overall}
                    onChange={(event, newValue) => {
                      setOverall(newValue);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex main_input pt-3 mt-1">
              <div className="input_name grow-1 text-end input_widthonly border-end-0 height_zero">
                Write your experience<span className="red-star">*</span>
              </div>
              <div className="input-sec">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="2"
                  className="form-control border-0 p-0 ps-2"
                  onChange={(e) => {
                    setExperiance(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="text-end mb-3">
              <button
                type="submit"
                className="submit_btn"
                onClick={() => {
                  submitReview();
                }}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
