import { useState } from 'react';
import "./ImageShow.scss"
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import CancelIcon from '@mui/icons-material/Cancel';

const IamgeShow = (props) => {
    console.log("proppertyAllImages", props.proppertyAllImages)
    const [isOpen, setIsOpen] = useState(true);
    const galleryImages = props.proppertyAllImages?.map((imageUrl) => ({
        original: imageUrl, thumbnail: imageUrl,
    }));
    return (

        <div className={`image-popup ${isOpen ? 'open' : ''}`}>
            <div className="image-popup-content">
                <ImageGallery showFullscreenButton={false} showPlayButton={false}
                    items={galleryImages}
                />

                <button className="close-button"
                    onClick={() => { setIsOpen(false); props.setShowImgPopup(false) }}
                ><CancelIcon /></button>
            </div>
        </div>
    )
}

export default IamgeShow
