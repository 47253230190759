import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  guestOfficeId: 0,
}

export const GuestVirtualOfficeSlice = createSlice({
  name: 'guestvirtualoffice',
  initialState,
  reducers: {
    guestOfficeVirtualId: (state, action) => {
      state.guestOfficeId = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { guestOfficeVirtualId } = GuestVirtualOfficeSlice.actions

export default GuestVirtualOfficeSlice.reducer