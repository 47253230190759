import React from "react";
import Mainreview from "./Components/Mainreview";
import "./Reviews.scss";
import "../dashboard/dashboard.scss";
export default function Reviews() {
  return (
    <div className="flex-grow-1">
      <Mainreview />
    </div>
  );
}
