import React from 'react'
import "./Adv_Invoice_Table.scss"
const Adv_Invoice_Table = () => {
  return (
    <div className='main_Invoice_container'>
      <table class="table" >
        <thead className=" text-center">
          <tr>
            <th className='ps-5'>Payment Data</th>
            <th>Campaign Name</th>
            <th>Total Impression</th>
            <th className=''> Amount</th>
            <th className=''>Status</th>
            <th className=''> Invoices</th>
          </tr>
        </thead>
      </table>

      <div class="invoice_table-container" style={{ color: "#585656" }}>
        <table class="table" >
          <thead >
            <tr >
              <th>Payment Data</th>
              <th>Campaign Name</th>
              <th>Total Impression</th>
              <th> Amount</th>
              <th>Status</th>
              <th> Invoices</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='ps-3'>22/06/2022</td>
              <td>Lorem</td>
              <td>1000</td>
              <td>$1</td>
              <td><span class="badge">PAID</span></td>
              <td>PDF</td>
            </tr>
            <tr >
              <td className='ps-3'>22/06/2022</td>
              <td>Lorem</td>
              <td>1000</td>
              <td>$1</td>
              <td><span class="badge" style={{ background: "#FF3838", width: "60px", height: "25px", borderRadius: "2px" }}>PAID</span></td>
              <td>PDF</td>
            </tr>
            <tr>
              <td className='ps-3'>22/06/2022</td>
              <td>Lorem</td>
              <td>1000</td>
              <td>$1</td>
              <td><span class="badge" style={{ background: "#2FC40B", width: "60px", height: "25px", borderRadius: "2px" }}>PAID</span></td>
              <td>PDF</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default Adv_Invoice_Table
//tr    font-fimify =Poppins , waight 300, size=12px, line haight 12px
// heading  width=125px , height=15px,
// width=1100px, hei=200px, redius=6px 6px 6px 6px , border=1px 


