import React, {useState} from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import PersonalInfo from './personalInformation/PersonalInfo';
import Security from './security/Security';
import TypeofAccount from './typeOfAccount/TypeofAccount';

const PersonalInfoPage = () => {
    const [profTab, setProfTab] = useState( "first" );
    const ChangeTab = (key) => {
        setProfTab(key);
    };
    return (
        <>
            <div className='profile-sec flex-grow-1'>
                <Tab.Container id="left-tabs-example" 
                // defaultActiveKey="first"
                activeKey={profTab}
                    onSelect={ChangeTab}
                >
                    <Row>
                        <Col lg={2} className="profile-left-tab">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item><Nav.Link eventKey="first">Personal Information</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="two">Types of Account</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="three">Password & Security </Nav.Link></Nav.Item>
                            </Nav>
                        </Col>
                        <Col lg={10}
                            className="tab-middle-sec">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    {/* {profTab === 'first' && <PersonalInfo ChangeTab={ChangeTab}/>} */}
                                    <PersonalInfo ChangeTab={ChangeTab}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="two">
                                {/* {profTab === 'two' &&<TypeofAccount ChangeTab={ChangeTab} />}  */}
                                <TypeofAccount ChangeTab={ChangeTab} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="three">
                                {profTab === 'three' &&<Security  ChangeTab={ChangeTab}/>}    
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}

export default PersonalInfoPage
