import React, { useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import ChangePlan from '../../neighbourhoodspecialist/component/chnagePlan/ChangePlan';
import PaymentHistory from '../../neighbourhoodspecialist/component/paymentHistory/PaymentHistory';
import PaymentMethod from '../../neighbourhoodspecialist/component/paymentMethod/PaymentMethod';
import PlanAndBilling from '../../neighbourhoodspecialist/component/PlanAndBilling/PlanAndBilling';
import StatWatch from '../../neighbourhoodspecialist/component/statWatch/StatWatch';
import CorporateInformation from './Corporate-Information/CorporateInformation';
import AddEmployee from './AddEmployee/AddEmployee';
import Fsbho_Subcription from '../../advertisement-flow/component/FSBHO_FLOW/Fsbho_Subcription';


const LandoadSetting = () => {
    const [profTab, setProfTab] = useState("seven")
    const ChangeTab = (key) => { setProfTab(key); };
    return (
        <>
            <div className='w-100'>
                <div className='profile-sec flex-grow-1'>
                    <Tab.Container id="left-tabs-example" onSelect={ChangeTab} activeKey={profTab} >
                        <Row>
                            <Col lg={2} className="profile-left-tab">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item><Nav.Link eventKey="seven">Change Plan</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="first">Corporate Information</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="second">Plan & Billing</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="third">Payment Method </Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="fourth">Payment History</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="fifth">StatWatch</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="six">Add Employee</Nav.Link></Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg={10}
                                className="tab-middle-sec hide-scrollbar">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <CorporateInformation />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <PlanAndBilling ChangeTab={ChangeTab} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <PaymentMethod ChangeTab={ChangeTab} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <PaymentHistory ChangeTab={ChangeTab} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        <StatWatch />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="six">
                                        <AddEmployee />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="seven">
                                        <Fsbho_Subcription />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default LandoadSetting