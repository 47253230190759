import React, { useState } from "react";
import "./Agentbioabout.scss";
import { Rating } from "@mui/material";
import { GetAllCityAndLanguaage } from "../../expiredListing/server";
import { useEffect } from "react";
import { getToken } from "../../../../appVariable/variable";
import { useParams } from "react-router-dom";

export default function Agentbioabout(props) {
  const { getAgentDetails } = props;
  let token = getToken();
  const params = useParams();
  let agentSlug = token?.profile.slug;
  let agentNameFromUrl = params.id;
  let paramObj = agentNameFromUrl ? agentNameFromUrl : agentSlug;
  const [allCityAndLang, setAllCityAndLang] = useState({});
  let lastLang =
    getAgentDetails[3]?.[getAgentDetails[3]?.length - 1]?.languages_name;
  let lastcity =
    getAgentDetails[6]?.[getAgentDetails[6]?.length - 1]?.area_name;

  function formatPhoneNumber(phoneNumber) {
    let usaFormat = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
      3,
      6
    )}-${phoneNumber.substring(6)}`;
    return usaFormat;
  }

  useEffect(() => {
    GetAllCityAndLanguaage(paramObj, (success) => { setAllCityAndLang(success) });
  }, [getAgentDetails])


  return (
    <div className="mainbio">
      <div className="">
        <div className="row">
          <div className="col-md-4" style={{ width: "100%" }}>
            <address>
              <strong className="agentabout_head">Personal Information</strong>
              <div className="agentbio_text">
                {/* <div><p>{getAgentDetails[4]?.map((plan, index) => <span key={`plan${index}`}> {`${plan.plan_id.Name} `} </span>)}</p></div> */}
                <div><p>{getAgentDetails[0]?.address_line_1} </p></div>
                {/* <div>
                  <p>
                    {getAgentDetails[4]?.map((plan, index) => (
                      <span key={`plan${index}`}>
                        {" "}
                        {`${plan.plan_id?.Name} `}{" "}
                      </span>
                    ))}
                  </p>
                </div> */}
                <div>
                  <p>
                    Work number :{" "}
                    {getAgentDetails[0]?.work_number_1
                      ? formatPhoneNumber(getAgentDetails[0]?.work_number_1)
                      : ""}{" "}
                  </p>
                  <p>
                    Cell number :{" "}
                    {getAgentDetails[0]?.cell_number
                      ? formatPhoneNumber(getAgentDetails[0]?.cell_number)
                      : ""}{" "}
                  </p>
                </div>
                <div>
                  <p>
                    Languages :{" "}
                    {getAgentDetails[3]?.map((data) => {
                      return (
                        <span key={`lang${data?.languages_name}`}>{`${
                          data?.languages_name
                        }  ${
                          lastLang === data?.languages_name ? "" : "|"
                        }  `}</span>
                      );
                    })}
                    {allCityAndLang.language?.map(
                      (lang) => `| ${lang.languages_name}`
                    )}
                  </p>
                </div>
                <div>
                  <p>
                    City :{" "}
                    {getAgentDetails[6]?.map((data) => {
                      return (
                        <span>{`${data.area_name} ${
                          lastcity === data.area_name ? "" : "|"
                        } `}</span>
                      );
                    })}
                    {allCityAndLang.area?.map((city) => `| ${city.area_name}`)}
                  </p>
                </div>
              </div>
            </address>

            <div className="agentbio_start_ratings">
              <Rating name="read-only" value={getAgentDetails[0]?.rating ? getAgentDetails[0]?.rating : 0} readOnly />
            </div>
          </div>

          <div style={{ marginTop: "1rem" }}>
            <strong className="agentabout_head">About Me</strong>
            {/* <p className=" agentbio_text">{getAgentDetails[0]?.personal_bio}</p> */}

            <div
              dangerouslySetInnerHTML={{
                __html: getAgentDetails[0]?.personal_bio,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
