import React from "react";
import PropertyCard from "../../dashboard/components/mainSection/PropertyCard";
import { updatePropertyDatail } from "../../dashboard/propperty-datails/PropertySlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllpropertyInfo } from "../../dashboard/components/mainSection/server";
import PropertyDetailsPopUp from "../../dashboard/propperty-datails/PropertyDetailsPopUp";
import { useState } from "react";
import Loader from "../../Loader/Loader";
const AgentCloseListing = ({ listing }) => {
  const dispatch = useDispatch();
  const propertyDetailInStore = useSelector(
    (state) => state.propertyDetailInStore
  );
  const [propertyDetailInfo, setPropertyDetailInfo] = useState({
    propertyImg: [],
  });
  const [loading, setloading] = useState(false);
  let propertDetails = async (id) => {
    // setloading(true);
    await getAllpropertyInfo(id, (success) => {
      // dispatch(
      //   updatePropertyDatail({
      //     PropertyDetailInfo: success,
      //     propertyId: id,
      //     setshowPropertyPopup: true,
      //     type: "close",
      //   })
      // );
      // setPropertyDetailInfo(success);
      // setPropertyDetailInfo((state) => {
      //   return state;
      // });
      // setloading(false);
    });
  };

  return (
    <>
      <div className="scrollS">
        {loading ? <Loader /> : null}
        <div className="d-flex flex-wrap gap-4">
          {listing?.length > 0
            ? listing.map((agentExpireListing, id) => {
                return (
                  <PropertyCard
                    handelOpenPopUp={propertDetails}
                    agentListingData={agentExpireListing}
                    key={id}
                    isClosed={true}
                  />
                );
              })
            : null}
          {/* {propertyDetailInStore.setshowPropertyPopup &&
        !loading &&
        propertyDetailInStore.type === "close" &&
        propertyDetailInfo ? (
          <PropertyDetailsPopUp
            setPropertyDetailInfo={setPropertyDetailInfo}
            propertyDetailInfo={propertyDetailInfo} // passing info about
            showTravelview={true}
          />
        ) : null} */}
        </div>
      </div>
    </>
  );
};

export default AgentCloseListing;
