import React, { useEffect, useState } from "react";
import Planbox from "./components/Planbox";
import FeatureTable from "./components/table/FeatureTable";
import "./subscription.scss";
import { getsubcriptionPlan, getSubscriptionDetails, } from "./server"; //getServicesDetails

const Subscription = () => {
  const [tab, settab] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [plandescription, setPlandescription] = useState([]);
  const [residential, setResidential] = useState([]);
  const [resiAndCommercial, setResiAndCommercial] = useState([]);
  const [commercial, setCommercial] = useState([]);
  const [neighbourHood, setNeighbourhood] = useState([]);
  function getPlansData() {
    getsubcriptionPlan((success) => {
      let monthlyData = success?.map((item) => {
        return {
          id: item.id,
          heading: item.Name,
          title: item.titles,
          price: item.monthly_price,
          services: item.Subscription_services,
        };
      });
      let yearlyData = success?.map((item) => {
        return {
          id: item.id,
          heading: item.Name,
          title: item.titles,
          price: item.yearly_price,
          services: item.Subscription_services,
        };
      });
      setMonthlyData(monthlyData);
      setYearlyData(yearlyData);
      setResidential(success[0]?.Subscription_services);
      setResiAndCommercial(success[1]?.Subscription_services);
      setCommercial(success[2]?.Subscription_services);
      setNeighbourhood(success[3]?.Subscription_services);
    });
  }
  function getSubscriptionPlansDetails() {
    getSubscriptionDetails((success) => {
      setPlandescription(success);
    });
  }
  useEffect(() => {
    getPlansData();
    getSubscriptionPlansDetails();
  }, []);
  return (
    <>
      <div className="subscription hide-scrollbar d-flex  flex-column flex-grow-1 mt-5 pt-2">
        <div className=" center flex-column gap-2">
          <div className="subscription-heading d-flex align-items-center flex-column gap-2">
            <h3>Best Plan For You !</h3>
            <p>Lorem Ipsum is simply dummy text of the typesetting</p>
          </div>
          <div className="subscription-tab d-flex gap-2">
            <div className={`center pointer tab-btn ${tab ? "monthly-active" : ""}`}
              onClick={() => settab(true)}>Monthly </div>
            <div className={`center pointer tab-btn ${tab ? "" : "yearly-active"}`}
              onClick={() => settab(false)} >Yearly</div>
          </div>
        </div>
        <div className=" d-flex">
          {tab ?
            (<Planbox SubscriptionData={monthlyData} plandetails={plandescription} tab={tab} emptycolshow={true} />)
            : (<Planbox SubscriptionData={yearlyData} plandetails={plandescription} tab={tab} emptycolshow={true} />)
          }
        </div>

        <div className="feature-table ">
          <FeatureTable
            plandescription={plandescription}
            residential={residential}
            resiAndCommercial={resiAndCommercial}
            commercial={commercial}
            neighbourHood={neighbourHood}
          />
        </div>
      </div>
    </>
  );
};

export default Subscription;
