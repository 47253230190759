import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getMinutedata } from "../../profile/components/minute/server";

const MinModal = (props) => {
  const {
    minModalValue,
    setMinModalValue,
    setMinModalResponce,
    selectedTimeSlot,
  } = props;
  const [sunDayTime, setSunDayTime] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [monDayTime, setMonDayTime] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [tueDayTime, setTueDayTime] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [wedDayTime, setWedDayTime] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [thuDayTime, setThuDayTime] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [friDayTime, setFriDayTime] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [satDayTime, setSatDayTime] = useState([
    { start_time: "", end_time: "" },
  ]);

  const [userSunDayTime, setUserSunDayTime] = useState(
    selectedTimeSlot?.sunday ? selectedTimeSlot?.sunday : []
  );
  const [usermonDayTime, setUsermonDayTime] = useState(
    selectedTimeSlot?.monday ? selectedTimeSlot?.monday : []
  );
  const [usertueDayTime, setUsertueDayTime] = useState(
    selectedTimeSlot?.tuesday ? selectedTimeSlot?.tuesday : []
  );
  const [userwedDayTime, setUserwedDayTime] = useState(
    selectedTimeSlot?.wednesday ? selectedTimeSlot?.wednesday : []
  );
  const [userthuDayTime, setUserthuDayTime] = useState(
    selectedTimeSlot?.thrusday ? selectedTimeSlot?.thrusday : []
  );
  const [userfriDayTime, setUserfriDayTime] = useState(
    selectedTimeSlot?.friday ? selectedTimeSlot?.friday : []
  );
  const [usersatDayTime, setUsersatDayTime] = useState(
    selectedTimeSlot?.saturday ? selectedTimeSlot?.saturday : []
  );

  let InitialSlot = () => {
    getMinutedata((success) => {
      setSunDayTime(success.Sunday.slot);
      setMonDayTime(success.Monday.slot);
      setTueDayTime(success.Tuesday.slot);
      setWedDayTime(success.Wednesday.slot);
      setThuDayTime(success.Thursday.slot);
      setFriDayTime(success.Friday.slot);
      setSatDayTime(success.Saturday.slot);
    });
  };

  let SetTimeSlot = (e, times) => {
    const { name, checked } = e.target;
    switch (name) {
      case "sundayslot":
        {
          let timeArr = [];
          if (checked) {
            timeArr = [...userSunDayTime, times];
            setUserSunDayTime(timeArr);
          } else {
            let filterTime = userSunDayTime.filter(
              (x) =>
                x.start_time !== times.start_time &&
                x.end_time !== times.end_time
            );
            setUserSunDayTime(filterTime);
          }
        }
        break;
      case "mondayslot":
        {
          let timeArr = [];
          if (checked) {
            timeArr = [...usermonDayTime, times];
            setUsermonDayTime(timeArr);
          } else {
            let filterTime = usermonDayTime.filter(
              (x) =>
                x.start_time !== times.start_time &&
                x.end_time !== times.end_time
            );
            setUsermonDayTime(filterTime);
          }
        }
        break;
      case "tuedayslot":
        {
          let timeArr = [];
          if (checked) {
            timeArr = [...usertueDayTime, times];
            setUsertueDayTime(timeArr);
          } else {
            let filterTime = usertueDayTime.filter(
              (x) =>
                x.start_time !== times.start_time &&
                x.end_time !== times.end_time
            );
            setUsertueDayTime(filterTime);
          }
        }
        break;
      case "weddayslot":
        {
          let timeArr = [];
          if (checked) {
            timeArr = [...userwedDayTime, times];
            setUserwedDayTime(timeArr);
          } else {
            let filterTime = userwedDayTime.filter(
              (x) =>
                x.start_time !== times.start_time &&
                x.end_time !== times.end_time
            );
            setUserwedDayTime(filterTime);
          }
        }
        break;
      case "thusdayslot":
        {
          let timeArr = [];
          if (checked) {
            timeArr = [...userthuDayTime, times];
            setUserthuDayTime(timeArr);
          } else {
            let filterTime = userthuDayTime.filter(
              (x) =>
                x.start_time !== times.start_time &&
                x.end_time !== times.end_time
            );
            setUserthuDayTime(filterTime);
          }
        }
        break;
      case "fridayslot":
        {
          let timeArr = [];
          if (checked) {
            timeArr = [...userfriDayTime, times];
            setUserfriDayTime(timeArr);
          } else {
            let filterTime = userfriDayTime.filter(
              (x) =>
                x.start_time !== times.start_time &&
                x.end_time !== times.end_time
            );
            setUserfriDayTime(filterTime);
          }
        }
        break;
      case "satdayslot":
        {
          let timeArr = [];
          if (checked) {
            timeArr = [...usersatDayTime, times];
            setUsersatDayTime(timeArr);
          } else {
            let filterTime = usersatDayTime.filter(
              (x) =>
                x.start_time !== times.start_time &&
                x.end_time !== times.end_time
            );
            setUsersatDayTime(filterTime);
          }
        }
        break;
      default:
        break;
    }
  };

  let submitPetModal = () => {
    let tempObj = {
      sunday: userSunDayTime,
      monday: usermonDayTime,
      tuesday: usertueDayTime,
      wednesday: userwedDayTime,
      thrusday: userthuDayTime,
      friday: userfriDayTime,
      saturday: usersatDayTime,
    };
    if (
      Boolean(
        userSunDayTime.length ||
          usermonDayTime.length ||
          usertueDayTime.length ||
          userwedDayTime.length ||
          userthuDayTime.length ||
          userfriDayTime.length ||
          usersatDayTime.length
      )
    ) {
      setMinModalValue(false);
      setMinModalResponce(tempObj);
    }
  };

  useEffect(() => {
    InitialSlot();
  }, []);

  return (
    <>
      <Modal
        show={minModalValue}
        centered
        size="lg"
        onHide={() => setMinModalValue(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <p>30 Min Visit</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-3 flex-wrap justify-content-evenly">
            <div className="d-flex flex-column align-items-center gap-2 day-slot">
              <button className="white__btn min-modal-btn center">
                MONDAY
              </button>
              <div className="d-flex flex-column  gap-1">
                {monDayTime?.map((times, index) => (
                  <div key={`monday${index}`}>
                    <input
                      type="checkbox"
                      className="Prop time-input visually-hidden"
                      id={`mondaytime${index}`}
                      name="mondayslot"
                      checked={usermonDayTime
                        ?.map(
                          (selecttime, i) =>
                            selecttime.start_time == times.start_time &&
                            selecttime.end_time == times.end_time
                        )
                        ?.includes(true)}
                      onChange={(e) => SetTimeSlot(e, times)}
                    />
                    <label
                      htmlFor={`mondaytime${index}`}
                      className="pointer aminity  min-modal-btn center"
                    >
                      {`${times.start_time} - ${times.end_time}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center gap-2 day-slot">
              <button className="white__btn min-modal-btn center">
                TUESDAY
              </button>
              <div className="d-flex flex-column  gap-1">
                {tueDayTime?.map((times, index) => (
                  <div key={`tuesday${index}`}>
                    <input
                      type="checkbox"
                      className="Prop time-input visually-hidden"
                      id={`tuedaytime${index}`}
                      name="tuedayslot"
                      checked={usertueDayTime
                        ?.map(
                          (selecttime, i) =>
                            selecttime.start_time == times.start_time &&
                            selecttime.end_time == times.end_time
                        )
                        ?.includes(true)}
                      onChange={(e) => SetTimeSlot(e, times)}
                    />
                    <label
                      htmlFor={`tuedaytime${index}`}
                      className="pointer aminity  min-modal-btn center"
                    >
                      {`${times.start_time} - ${times.end_time}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center gap-2 day-slot">
              <button className="white__btn min-modal-btn center">
                Wednesday
              </button>
              <div className="d-flex flex-column  gap-1">
                {wedDayTime?.map((times, index) => (
                  <div key={`wedday${index}`}>
                    <input
                      type="checkbox"
                      className="Prop time-input visually-hidden"
                      id={`weddaytime${index}`}
                      name="weddayslot"
                      checked={userwedDayTime
                        ?.map(
                          (selecttime, i) =>
                            selecttime.start_time == times.start_time &&
                            selecttime.end_time == times.end_time
                        )
                        ?.includes(true)}
                      onChange={(e) => SetTimeSlot(e, times)}
                    />
                    <label
                      htmlFor={`weddaytime${index}`}
                      className="pointer aminity  min-modal-btn center"
                    >
                      {`${times.start_time} - ${times.end_time}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center gap-2 day-slot">
              <button className="white__btn min-modal-btn center">
                Thursday
              </button>
              <div className="d-flex flex-column  gap-1">
                {thuDayTime?.map((times, index) => (
                  <div key={`thusday${index}`}>
                    <input
                      type="checkbox"
                      className="Prop time-input visually-hidden"
                      id={`thusdaytime${index}`}
                      name="thusdayslot"
                      // checked={formik.values.amenities.includes(`${aminiti.id}`)}
                      checked={userthuDayTime
                        ?.map(
                          (selecttime, i) =>
                            selecttime.start_time == times.start_time &&
                            selecttime.end_time == times.end_time
                        )
                        ?.includes(true)}
                      onChange={(e) => SetTimeSlot(e, times)}
                    />
                    <label
                      htmlFor={`thusdaytime${index}`}
                      className="pointer aminity  min-modal-btn center"
                    >
                      {`${times.start_time} - ${times.end_time}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center gap-2 day-slot">
              <button className="white__btn min-modal-btn center">
                Friday
              </button>
              <div className="d-flex flex-column  gap-1">
                {friDayTime?.map((times, index) => (
                  <div key={`friday${index}`}>
                    <input
                      type="checkbox"
                      className="Prop time-input visually-hidden"
                      id={`fridaytime${index}`}
                      name="fridayslot"
                      // checked={formik.values.amenities.includes(`${aminiti.id}`)}
                      checked={userfriDayTime
                        ?.map(
                          (selecttime, i) =>
                            selecttime.start_time == times.start_time &&
                            selecttime.end_time == times.end_time
                        )
                        ?.includes(true)}
                      onChange={(e) => SetTimeSlot(e, times)}
                    />
                    <label
                      htmlFor={`fridaytime${index}`}
                      className="pointer aminity  min-modal-btn center"
                    >
                      {`${times.start_time} - ${times.end_time}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center gap-2 day-slot">
              <button className="white__btn min-modal-btn center">
                Saturday
              </button>
              <div className="d-flex flex-column  gap-1">
                {satDayTime?.map((times, index) => (
                  <div key={`saturday${index}`}>
                    <input
                      type="checkbox"
                      className="Prop time-input visually-hidden"
                      id={`satdaytime${index}`}
                      name="satdayslot"
                      // checked={formik.values.amenities.includes(`${aminiti.id}`)}
                      checked={usersatDayTime
                        ?.map(
                          (selecttime, i) =>
                            selecttime.start_time == times.start_time &&
                            selecttime.end_time == times.end_time
                        )
                        ?.includes(true)}
                      onChange={(e) => SetTimeSlot(e, times)}
                    />
                    <label
                      htmlFor={`satdaytime${index}`}
                      className="pointer aminity  min-modal-btn center"
                    >
                      {`${times.start_time} - ${times.end_time}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center gap-2 day-slot">
              <button className="white__btn min-modal-btn center">
                SUNDAY
              </button>
              <div className="d-flex flex-column  gap-1">
                {sunDayTime?.map((times, index) => (
                  <div key={`sunday${index}`}>
                    <input
                      type="checkbox"
                      className="Prop time-input visually-hidden"
                      id={`sundaytime${index}`}
                      name="sundayslot"
                      checked={userSunDayTime
                        ?.map(
                          (selecttime, i) =>
                            selecttime.start_time == times.start_time &&
                            selecttime.end_time == times.end_time
                        )
                        ?.includes(true)}
                      onChange={(e) => {
                        SetTimeSlot(e, times);
                      }}
                    />
                    <label
                      htmlFor={`sundaytime${index}`}
                      className="pointer aminity  min-modal-btn center"
                    >
                      {`${times.start_time} - ${times.end_time}`}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="white__btn min-modal-btn center"
            onClick={submitPetModal}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MinModal;
