import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./commertialBuyMapFilter.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  ".MuiSwitch-track": {
    backgroundColor: "#315EFB",
    opacity: "1",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#315EFB",
    "&:hover": {
      backgroundColor: alpha("#315EFB", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#315EFB",
    height: "120%",
    transform: "translate(0px, -1px)",
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    border: "1px solid rgba(88, 86, 86, 1)",
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    display: "none",
  },
  "&:before": {
    display: "none",
  },
  "&:after": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    height: "14px",
    minHeight: "unset",
    color: "#315efb",
    padding: "0px 8px 0px 0px",
    ".MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "end",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CommertialBuyMapFilter = ({ onclick }) => {
  const [unit, setUnit] = useState(1);
  const [room, setRoom] = useState(1);
  const [Type, setType] = useState({
    office: false,
    industrial: false,
    retail: false,
    leisure: false,
    mixeduse: false,
    specialty: false,
  });
  const [minSqft, setminSqft] = useState("");
  const [maxSqft, setmaxSqft] = useState("");
  const [minPrice, setminPrice] = useState("");
  const [maxPrice, setmaxPrice] = useState("");
  const [Amenities, setAmenities] = useState({
    Penthouse: false,
    Triplex: false,
    Duplex: false,
    Doorman: false,
    Storage: false,
    Elevator: false,
    Parking: false,
    Laundry: false,
  });
  const [category, setCategory] = React.useState(10);
  const [subType, setSubType] = React.useState(0);
  const [priceType, setPriceType] = React.useState("total");
  const [expanded, setExpanded] = React.useState("panel1");

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleSubType = (event) => {
    setSubType(event.target.value);
  };

  const handlePriceType = (event) => {
    setPriceType(event.target.value);
  };

  const handleAminity = (e, aminiti) => {
    const tempAminity = { ...Amenities };
    tempAminity[aminiti] = !tempAminity[aminiti];
    setAmenities(tempAminity);
    setAmenities((state) => {
      return state;
    });
  };

  const handleSelectChange = (event) => {
    console.log(event.target.value);
  };

  const sendFormdata = () => {
    const asArray = Object.entries(Type);

    const filtered = asArray.filter(([key, value]) => value === true);

    const asArray_aminity = Object.entries(Amenities);

    const filtered_aminity = asArray_aminity.filter(
      ([key, value]) => value === true
    );
    let defaultType = {};
    let defaultAmini = {};
    let defaultminSqft = "";
    let defaultmaxSqft = "";
    let defaultminPrice = "";
    let defaultmaxPrice = "";

    Object.keys(filtered).length !== 0
      ? (defaultType = Type)
      : (defaultType = {
          room: true,
          apartment: true,
          studio: true,
          coop: true,
          multifamily: true,
          townhouse: true,
          brownstone: true,
          estate: true,
          vacation: true,
        });
    Object.keys(filtered_aminity).length !== 0
      ? (defaultAmini = Amenities)
      : (defaultAmini = {
          Penthouse: false,
          Triplex: false,
          Duplex: false,
          Doorman: false,
          Storage: false,
          Elevator: false,
          Parking: false,
          Laundry: false,
        });
    minSqft === "" ? (defaultminSqft = "0") : (defaultminSqft = minSqft);
    maxSqft === ""
      ? (defaultmaxSqft = "1000000000000")
      : (defaultmaxSqft = maxSqft);
    minPrice === "" ? (defaultminPrice = "0") : (defaultminPrice = minPrice);
    maxPrice === ""
      ? (defaultmaxPrice = "1000000000000")
      : (defaultmaxPrice = maxPrice);

    const filterGroup = {
      Type: defaultType,
      unit: unit,
      room: room,
      subType: subType,
      priceType: priceType,
      minSqft: defaultminSqft,
      maxSqft: defaultmaxSqft,
      minPrice: defaultminPrice,
      maxPrice: defaultmaxPrice,
      Amenities: defaultAmini,
    };
    console.log("filterGroup", filterGroup);
    onclick(filterGroup);
  };

  const handleChange = (e, filtertype) => {
    const tempType = { ...Type };

    switch (filtertype) {
      case "office":
        tempType.office = !tempType.office;
        break;
      case "industrial":
        tempType.industrial = !tempType.industrial;
        break;
      case "retail":
        tempType.retail = !tempType.retail;
        break;
      case "leisure":
        tempType.leisure = !tempType.leisure;
        break;
      case "mixeduse":
        tempType.mixeduse = !tempType.mixeduse;
        break;
      case "specialty":
        tempType.specialty = !tempType.specialty;
        break;
      default:
        break;
    }
    setType(tempType);
    setType((state) => {
      return state;
    });
  };

  const handleViewMoreChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div
        className=" d-flex flex-column filter-body scrollS"
        style={{ width: "220px", height: "100%" }}
      >
        <div className="filter-by filter-form-row">
          <p className="filter-heading">Filter By :</p>
        </div>

        <div className="filter-form d-flex flex-column">
          <div className="type filter-form-row">
            <p className="small-heading">Category</p>
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={category}
                className="select-category"
                onChange={(e) => handleCategory(e)}
              >
                <MenuItem value={10}>Rental</MenuItem>
                <MenuItem value={20}>Buy</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="aminity-type filter-form-row  ">
            <p className="small-heading">Type</p>
            <div className="d-flex justify-content-between">
              <div className="checkbox-column d-flex flex-column">
                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Office"
                    name="aminity"
                    value="office"
                    onChange={(e) => handleChange(e, "office")}
                  />
                  <label htmlFor="Office" className="aminity-radio-label">
                    Office
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Retail"
                    name="aminity"
                    value="Retail"
                    onChange={(e) => handleChange(e, "retail")}
                  />
                  <label htmlFor="Retail" className="aminity-radio-label">
                    Retail
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Mixed Use"
                    name="aminity"
                    value="Mixed Use"
                    onChange={(e) => handleChange(e, "mixeduse")}
                  />
                  <label htmlFor="Mixed Use" className="aminity-radio-label">
                    Mixed Use
                  </label>
                </div>
              </div>

              <div className="checkbox-column d-flex flex-column  ">
                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Industrial"
                    name="aminity"
                    value="Industrial"
                    onChange={(e) => handleChange(e, "industrial")}
                  />
                  <label htmlFor="Industrial" className="aminity-radio-label">
                    Industrial
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Leisure"
                    name="aminity"
                    value="Leisure"
                    onChange={(e) => handleChange(e, "leisure")}
                  />
                  <label htmlFor="Leisure" className="aminity-radio-label">
                    Leisure
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Specialty"
                    name="aminity"
                    value="Specialty"
                    onChange={(e) => handleChange(e, "specialty")}
                  />
                  <label htmlFor="Specialty" className="aminity-radio-label">
                    Specialty
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="type filter-form-row">
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50, marginLeft: "0px" }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={subType}
                className="select-category"
                onChange={(e) => handleSubType(e)}
                placeholder="Sub Type"
              >
                <MenuItem value={0}>Sub Type</MenuItem>
                <MenuItem value={10}>Industry</MenuItem>
                <MenuItem value={20}>Sepciality</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="bedroom-bathroom">
            <div className=" small-in-box-div">
              <div className="filter-form-row ">
                <p className="small-heading">Unit</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p onClick={() => setUnit(unit + 1)} className="pointer">
                    +
                  </p>
                  <p>{unit}</p>
                  <p onClick={() => setUnit(unit - 1)} className="pointer">
                    -
                  </p>
                </div>
              </div>
              <div className="filter-form-row">
                <p className="small-heading">Room</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p onClick={() => setRoom(room + 1)} className="pointer">
                    +
                  </p>
                  <p>{room}</p>
                  <p onClick={() => setRoom(room - 1)} className="pointer">
                    -
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sqft-price filter-form-row">
            <p className="small-heading">Sqft</p>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Sqft Min"
                  onChange={(e) => {
                    setminSqft(e.target.value);
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Sqft Max"
                  onChange={(e) => {
                    setmaxSqft(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {/* price section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Price</p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1">
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="Price"
                    className="Prop"
                    id="sqft-price"
                    value="2"
                  />
                  <label htmlFor="sqft-price" className="price-type-text">
                    + Cost Per Sqft (if provided)
                  </label>
                </div>
              </div>
            </div>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Price Min"
                  onChange={(e) => {
                    setminPrice(e.target.value);
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Price Max"
                  onChange={(e) => {
                    setmaxPrice(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {/* cap occupancy section */}
          <div className="sqft-price  filter-form-row flex-row gap-4">
            <div className="sqft-price filter-form-row">
              <p className="small-heading">Cap Rate</p>
              <div className=" d-flex small-in-box-div">
                <div className="input-sec small-in-box-cap">
                  <input type="text" placeholder="0%" onChange={(e) => {}} x />
                </div>
                <div className="input-sec small-in-box-cap">
                  <input type="text" placeholder="100%" onChange={(e) => {}} />
                </div>
              </div>
            </div>
            <div className="sqft-price filter-form-row">
              <p className="small-heading">Occupancy</p>
              <div className=" d-flex small-in-box-div">
                <div className="input-sec small-in-box-cap">
                  <input type="text" placeholder="0%" onChange={(e) => {}} />
                </div>
                <div className="input-sec small-in-box-cap">
                  <input type="text" placeholder="100%" onChange={(e) => {}} />
                </div>
              </div>
            </div>
          </div>
          {/* lease type section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Investment of Lease Type</p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1 flex-wrap">
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="new-lease"
                    value="newlease"
                  />
                  <label htmlFor="new-lease" className="price-type-text">
                    New Lease
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="triple"
                    value="triple"
                  />
                  <label htmlFor="triple" className="price-type-text">
                    Triple Net
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="absolute"
                    value="absolute"
                  />
                  <label htmlFor="absolute" className="price-type-text">
                    Absolute Net
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="gross"
                    value="gross"
                  />
                  <label
                    htmlFor="gross"
                    className="price-type-text"
                    style={{ width: "40px" }}
                  >
                    Gross
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="leaseType"
                    className="Prop"
                    id="modified"
                    value="modified"
                  />
                  <label htmlFor="modified" className="price-type-text">
                    Modified Gross
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* property condition section */}
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Property Conditions&Class</p>
            <div className=" d-flex small-in-box-div">
              <div className="filter-radio d-flex gap-1 flex-wrap">
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="ground"
                    value="ground"
                  />
                  <label htmlFor="ground" className="price-type-text">
                    Ground Lease
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="ground"
                    value="ground"
                  />
                  <label htmlFor="ground" className="price-type-text">
                    Distressed Property
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="a"
                    value="a"
                  />
                  <label htmlFor="a" className="price-type-text">
                    A
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="b"
                    value="b"
                  />
                  <label htmlFor="b" className="price-type-text">
                    B
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="c"
                    value="c"
                  />
                  <label htmlFor="c" className="price-type-text">
                    C
                  </label>
                </div>
                <div className="d-flex gap-1">
                  <input
                    type="radio"
                    name="classes"
                    className="Prop"
                    id="d"
                    value="d"
                  />
                  <label htmlFor="d" className="price-type-text">
                    D
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* available air rights section */}
          <div className="sqft-price  filter-form-row d-flex flex-row">
            <p className="small-heading">Available Air Rights</p>
            <input
              type="text"
              placeholder="Y/N"
              className="air-right-input"
              onChange={(e) => {
                setmaxPrice(e.target.value);
              }}
            />
          </div>
          {/* new this week */}
          <div className="sqft-price  filter-form-row d-flex flex-row justify-content-between">
            <div className="small-heading new-week">New This Week</div>
            <div className="d-flex justify-content-end">
              <PinkSwitch
                {...label}
                // onChange={(e) => {
                //   setViewsetting(e.target.checked);
                //   setViewsetting((state) => {
                //     return state;
                //   });
                //   setView(!viewValue);
                // }}
                // checked={viewsetting}
                checked={true}
                style={{ color: "#315EFB" }}
              />
            </div>
          </div>
          <div
            className="buttons pointer blue__btn adfasdfsad"
            onClick={(e) => sendFormdata()}
            style={{ margin: "auto" }}
          >
            {" "}
            Update{" "}
          </div>

          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleViewMoreChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p>View More</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>this is content for view more</div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default CommertialBuyMapFilter;
