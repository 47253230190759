import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import CustomRange from './components/customRange/CustomRange'
import Daily from './components/daily/Daily'
import Monthly from './components/monthly/Monthly'
import Weekly from './components/weekly/Weekly'
import "./StatWatch.scss"
const StatWatch = () => {
    return (
        <>
            <div className="stopwatch_mainsection ">
                <div className="w-100 position-relative">
                    <Tabs defaultActiveKey="Daily" className="" id="uncontrolled-tab-example" >
                        <Tab eventKey="Daily" title="Daily"><Daily /></Tab>
                        <Tab eventKey="Weekly" title="Weekly"><Weekly /></Tab>
                        <Tab eventKey="Monthly" title="Monthly"><Monthly /></Tab>
                        <Tab eventKey="Custom Range" title="Custom Range"><CustomRange /></Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default StatWatch
