import { createSlice } from "@reduxjs/toolkit";

export const PropertydetailSlice = createSlice({
  name: "counter",
  initialState: {
    propertyId: 0,
    PropertyDetailInfo: {
      propertDetails: undefined,
      propertyMainImg: undefined,
      amenities: undefined,
      SpaceAvailable: undefined,
      agentTIme: undefined,
      SalesFeatures: undefined,
      propertyImg: undefined,
      propertyTeamMember: undefined,
    },
    type: undefined,
  },
  reducers: {
    updatePropertyDatail: (state, action) => {
      // state = action.payload
      return { ...state, ...action.payload };
      // return {
      //     ...state,
      //     PropertyDetailInfo: {
      //         ...state.PropertyDetailInfo,
      //         ...action.payload,
      //     },
      // };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePropertyDatail } = PropertydetailSlice.actions;

export default PropertydetailSlice.reducer;
