import React from 'react'
import Stunning from '../../ImgAdv/Stunning.png'
import Ele_dowt_Img from '../../ImgAdv/Ele_dowt_Img.png'
import number2 from '../../ImgAdv/number2.png'
import "./Adv_EstimateDesign.scss"


const Adv_EstimateDesign = () => {
  return (

    <section className='Advertise5'>
      <div className='d-flex text-start numberContent'>
        <div className='text-white text-center '>
          <img src={number2} alt="#" className='number2Img' />
        </div>
        <div className='ElevatedHeading'>
          <h2 className=''>Elevated Design</h2>
        </div>
      </div>
      <div className='text-left '>
        <p className='my-2 AdvEle_text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
      </div>
      <div className='Img_Content2 d-flex h-75'>
        <img src={Stunning} className='ElevatedImg' alt="#" />
        <div className='AdvIMG'>
          <img src={Stunning} className='ElevatedImgF' alt="#" />
          <img src={Stunning} className='ElevatedImgS' alt="#" />
        </div>
      </div>
      {/* ----------------dowt img--------------------------------------- */}
      <img src={Ele_dowt_Img} className='EleDowtImg border-none position-absolute' alt="#" />
    </section>
  )
}

export default Adv_EstimateDesign
