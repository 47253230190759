import { createSlice } from '@reduxjs/toolkit';

export const currentListingDetailSlice = createSlice({
    name: 'agentcurrentlisting',
    initialState: [],
    reducers: {
        updateListingDatail: (state, action) => {
            return [...action.payload];
            // state = action.payload.concat([]);
            // state = action.payload
            // return { ...state, ...action.payload };
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateListingDatail } = currentListingDetailSlice.actions

export default currentListingDetailSlice.reducer
