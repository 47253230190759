import sms from "./sms.svg"
import display from "./display.svg"
import native from "./native.svg"
import streaming from "./streaming.svg"
import social from "./social.svg"
import podcast from "./podcast.svg"
import commercial from "./commercial.svg"
import remarketing from "./remarketing.svg"
import digital from "./digital.svg"
import paidmedia from "./paidmedia.svg"
import other from "./other.svg"

const MarketingImg = {
    sms,
    display,
    native,
    streaming,
    social,
    podcast,
    commercial,
    remarketing,
    digital,
    paidmedia,
    other,
}
export default MarketingImg