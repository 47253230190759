import React from 'react'
import card_IMg from '../../ImgAdv/card_IMg.png'
import "./checkOutPopup.scss"
const checkOut_Pop = () => {
    return (
        <div className=''>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Open modal for @mdo</button>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog ad-popup">
                    <div class="modal-content rounded-0 w-100" >
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Payment Method</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="mb-3 w-50">
                                    <p>Card Marker</p>
                                    <img src={card_IMg} className='home' alt="#" />
                                </div>
                                <div class="mb-3">
                                    <label for="recipient-name" class="col-form-label">Name On Card</label>
                                    <input type="text" placeholder="Enter Here"class="form-control border border-primary" id="recipient-name" />
                                </div>
                                <div class="mb-3">
                                    <label for="recipient-name" class="col-form-label">Card Number</label>
                                    <input type="text" placeholder="Enter Here"class="form-control border border-primary" id="recipient-name" />
                                </div>
                                {/* ------------------------------- */}
                                <div class="mb-3 d-flex w-100 justify-content-between">
                                  <div className='w-800'>
                                    <label for="recipient-name" class="col-form-label">Expiry Date</label>
                                    <input type="text" placeholder="Enter Here"class="form-control border border-primary w-100" id="recipient-name" style={{borderRadius:"var(--bs-border-radius);"}}/>
                                  </div>
                                  <div className=' w-80 '>
                                    <label for="recipient-name" class="col-form-label">CVV Number</label>
                                    <input type="text" placeholder="Enter Here"class="form-control border border-primary w-100" id="recipient-name" />
                                  </div>
                                </div>
                                
                            </form>
                        </div>
                        <div class="modal-footer w-100 ">
                            <button type="button" class="btn btn-primary w-100 border border-white text-white" style={{boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>Pay Now</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default checkOut_Pop
