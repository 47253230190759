import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tempId: 0,
  officeId: 0,
  memberDetails: {},
  teamMemberList: [],
  memberId: 0,
};

export const virtualOfficeSlice = createSlice({
  name: "virtualoffice",
  initialState,
  reducers: {
    getOfficeId: (state, action) => {
      state.officeId = action.payload;
    },
    setLogInOfficeId: (state, action) => {
      state.tempId = action.payload;
    },
    teamList: (state, action) => {
      state.teamMemberList = action.payload;
    },
    memberInfo: (state, action) => {
      state.memberDetails = action.payload;
    },
    memberId: (state, action) => {
      state.memberId = action.payload;
    },
  },
});

export const { getOfficeId, setLogInOfficeId, teamList, memberInfo, memberId } =
  virtualOfficeSlice.actions;
export default virtualOfficeSlice.reducer;
