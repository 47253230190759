import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
  totalListing: 0,
};

export const DashboardSlice = createSlice({
  name: "localdata",
  initialState,
  reducers: {
    localDataStore: (state, action) => {
      state.userInfo = action.payload;
    },
    updateTotalListing: (state, action) => {
      state.totalListing = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { localDataStore, updateTotalListing } = DashboardSlice.actions;

export default DashboardSlice.reducer;
