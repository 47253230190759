import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TopSec from '../sms-marketing/top-sec/TopSec';
import BottomSec from '../sms-marketing/bottom-sec/BottomSec';
import { BoostMarketingDashboard } from '../../server';

const MarketingPlans = () => {
    let { id } = useParams();
    const [respBoostMarket, setRespBoostMarket] = useState({});
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const path = url.pathname;
    const segments = path.split('/'); // Split the path by slashes
    let currentUser = ''
    if (segments.length > 1) { currentUser = segments[1]; }
    //  else {
    //     console.log('Invalid path format');
    // }


    const PlanIfo = [
        { heading: 'SMS MARKETING', title: "Per Unit", url: `/${currentUser}/marketing-form` },
        { heading: 'DISPLAY ADS', title: "Per Impressions", url: `/${currentUser}/marketing-form` },
        { heading: 'NATIVE ADS', title: "Per Impressions", url: `/${currentUser}/marketing-form` },
        { heading: 'STREAMING ADS', title: "Per Impressions", url: `/${currentUser}/marketing-form` },
        { heading: 'SOCIAL MEDIA', title: "Per Impressions", url: `/${currentUser}/boost-marketing/social-media/form` },
        { heading: 'PODCAST ADS', title: "Per Impressions", url: `/${currentUser}/boost-marketing-form` },
        { heading: 'COMMERCIAL ADS', title: "Per Impressions", url: `/${currentUser}/marketing-form` },
        { heading: 'REMARKETING', title: "Per Impressions", url: `/${currentUser}/boost-marketing-form` },
        { heading: 'digital billboard', title: "Per Impressions", url: `/${currentUser}/boost-marketing-form` },
        { heading: 'PAID MEDIA GOOGLE ADWORD', title: "Per Impressions", url: `/${currentUser}/boost-marketing/paid-media/form` },
        { heading: 'other digital marketing service', title: "Per Impressions", url: `/${currentUser}/boost-marketing-form` },
    ]

    useEffect(() => {
        let respObj = { "plan_type": id, }
        BoostMarketingDashboard(respObj, (success) => { setRespBoostMarket(success) })
    }, [])
    return (
        <>
            <div className="marketing-page d-flex flex-column flex-grow-1 hide-scrollbar gap-4" >
                <TopSec heading={PlanIfo[id - 1].heading} title={PlanIfo[id - 1].title} description={respBoostMarket?.description} />
                <BottomSec url={PlanIfo[id - 1].url} boostMarketResp={respBoostMarket} />
            </div>
        </>
    )
}

export default MarketingPlans
