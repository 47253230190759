import React, { useEffect, useState } from 'react'
import { getToken } from '../../../../../../../appVariable/variable'
import { DayStarWatch } from '../../../../server'
import "./daily.scss"

// export const hasEmptyObject = (arr) => { return arr.some((item) => Object.keys(item).length === 0);};
const Daily = () => {
    let token = getToken()
    let userId = token?.userId;
    const [dayStartwatch, setDayStartwatch] = useState([{}])

    useEffect(() => {
        let watchobj = { "user_id": userId, "date": new Date().toJSON().slice(0, 10) }
        DayStarWatch(watchobj, (success) => {
            if (success.data.code === 200 && success.data.status === "success") { setDayStartwatch(success.data.data) }
        });
    }, [])

    return (
        <>
            {Object.keys(dayStartwatch[0]).length !== 0?
                <div className='daily_tab_section w-100'>
                    <div className='row_heading'>
                        <div className="col_width"><p>Daily</p></div>
                        <div className="col_width"><p>Town </p></div>
                        <div className="col_width text-center"><p>Total Number</p></div>
                    </div>
                    <div className='scrolls_stopwatch'>
                        <div className='WEElks_outer_border '>
                            {dayStartwatch?.map((watch, index) =>
                                <div className='row_headings pad_top_bottom' key={`dailywatch${index}`}>
                                    <div className="col_width"><p>{watch.day}</p></div>
                                    <div className="col_width"><p>{watch.property_area} </p></div>
                                    <div className="col_width text-center"><p>{watch.count}</p></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                : <p className='data-not-found'>No Data Found</p>
            }
        </>
    )
}

export default Daily
