import React from 'react';
import './AdvNavBar.scss';
import { Button } from 'react-bootstrap';
import logo from '../../Images/logosvg.svg';
import { NavLink, useNavigate } from 'react-router-dom';

const AdvNavBar = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="navbar  d-flex justify-content-lg-between">
                <div className="logo pointer" onClick={() => { navigate('/advertisement') }}
                >
                    <img src={logo} alt="" />
                </div>
                <div className="sign-in">
                    <NavLink to="/ad-login" className=" text-white text-decoration-none" >
                        <Button variant="primary">
                            Sign in / Register
                        </Button>
                    </NavLink>
                </div>

            </div>
        </div>
    )
}

export default AdvNavBar
