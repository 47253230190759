import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const BottomSec = (props) => {
    const Navigate = useNavigate();
    const plans = props.boostMarketResp.plans
    return (
        <>
            <div className="marketing-sec marketing-bottom-sec">
                <div className="marketing-table">
                    <Table responsive className=' m-0'>
                        <thead>
                            <tr>
                                <th><p>Impressions</p></th>
                                <th>Cost</th>
                                <th>Descriptions</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {plans?.map((trow, index) =>
                                <tr className='marketing-table-row' key={`trow${index}`}>
                                    <td>{trow.impression}</td>
                                    <td>$ {trow.cost} </td>
                                    <td className='descripton-col'> {trow.description} </td>
                                    <td className=' text-end align-middle'><Button variant="outline-primary" onClick={() => Navigate(`${props.url}/${trow.id}`)} className="btn long-btn"> send</Button></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default BottomSec
