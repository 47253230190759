import car from "../componants/images/car.svg";
import plus from "../componants/images/plus.svg";

const TravelCard = () => {
  return (
    <div className="Points_Interest_card" style={{ marginTop: "10px" }}>
      <div
        className="p-2 card_border mb-4"
        style={{ boxShadow: "0px 0px 0px 0px" }}
      >
        <div className="d-flex justify-content-between gap-10">
          <div className="d-flex car_group">
            <div className="car_img">
              <img src={car} alt="" />{" "}
            </div>
            <div className="center">
              <p>From work-10min</p>
            </div>
          </div>
          <div className="d-flex py-1">
            <div className="center" style={{ marginRight: "10px" }}>
              <p>62-59 108th st</p>
            </div>
            <div className="pointer" style={{ color: "rgba(49, 94, 251, 1)" }}>
              {" "}
              <span>Add New Points of Interest</span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end flex-wrap pointer">
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>
    </div>
  );
};

export default TravelCard;
