import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import "./topsec.scss"

const TopSec = (props) => {
    const Navigate = useNavigate()
    return (
        <>
            <div className='marketing-top-sec d-flex flex-column '>
                <div className="table-btn text-end">
                    <Button variant="outline-primary" className='long-btn' onClick={() => Navigate(-1)}>Back</Button>
                </div>

                <div className="marketing-sec d-flex flex-column">
                    <div className="marketing-message">
                        <div className="marketing-category text-center mb-2">
                            <h4 className=' mb-1 text-uppercase'>{props.heading}</h4>
                            <p>{props.title}</p>
                        </div>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopSec
