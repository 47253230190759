import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./residentialfilter.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
let Allaminity = [
  "Penthouse",
  "Triplex",
  "Duplex",
  "Doorman",
  "Storage",
  "Elevator",
  "Parking",
  "Laundry",
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    display: "none",
  },
  "&:before": {
    display: "none",
  },
  "&:after": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: "row-reverse",
    height: "14px",
    minHeight: "unset",
    color: "#315efb",
    padding: "0px 8px 0px 0px",
    ".MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "end",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ResidentialBuyMapFilter = ({ onclick }) => {
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [Type, setType] = useState({
    room: false,
    apartment: false,
    studio: false,
    coop: false,
    multifamily: false,
    townhouse: false,
    brownstone: false,
    estate: false,
    vacation: false,
  });
  const [minSqft, setminSqft] = useState("");
  const [maxSqft, setmaxSqft] = useState("");
  const [minPrice, setminPrice] = useState("");
  const [maxPrice, setmaxPrice] = useState("");
  const [Amenities, setAmenities] = useState({
    Penthouse: false,
    Triplex: false,
    Duplex: false,
    Doorman: false,
    Storage: false,
    Elevator: false,
    Parking: false,
    Laundry: false,
  });
  const [feeType, setfeeType] = useState("No Fee");
  const [category, setCategory] = React.useState(10);
  const [expanded, setExpanded] = React.useState("panel1");

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleAminity = (e, aminiti) => {
    console.log(aminiti);
    const tempAminity = { ...Amenities };
    tempAminity[aminiti] = !tempAminity[aminiti];
    setAmenities(tempAminity);
    setAmenities((state) => {
      return state;
    });
  };

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    setfeeType(event.target.value);
    setfeeType((state) => {
      return state;
    });
  };

  const sendFormdata = () => {
    const asArray = Object.entries(Type);

    const filtered = asArray.filter(([key, value]) => value === true);

    const asArray_aminity = Object.entries(Amenities);

    const filtered_aminity = asArray_aminity.filter(
      ([key, value]) => value === true
    );
    let defaultType = {};
    let defaultAmini = {};
    let defaultminSqft = "";
    let defaultmaxSqft = "";
    let defaultminPrice = "";
    let defaultmaxPrice = "";

    Object.keys(filtered).length !== 0
      ? (defaultType = Type)
      : (defaultType = {
          room: true,
          apartment: true,
          studio: true,
          coop: true,
          multifamily: true,
          townhouse: true,
          brownstone: true,
          estate: true,
          vacation: true,
        });
    Object.keys(filtered_aminity).length !== 0
      ? (defaultAmini = Amenities)
      : (defaultAmini = {
          Penthouse: false,
          Triplex: false,
          Duplex: false,
          Doorman: false,
          Storage: false,
          Elevator: false,
          Parking: false,
          Laundry: false,
        });
    minSqft === "" ? (defaultminSqft = "0") : (defaultminSqft = minSqft);
    maxSqft === ""
      ? (defaultmaxSqft = "1000000000000")
      : (defaultmaxSqft = maxSqft);
    minPrice === "" ? (defaultminPrice = "0") : (defaultminPrice = minPrice);
    maxPrice === ""
      ? (defaultmaxPrice = "1000000000000")
      : (defaultmaxPrice = maxPrice);

    const filterGroup = {
      feeType: feeType,
      Type: defaultType,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      minSqft: defaultminSqft,
      maxSqft: defaultmaxSqft,
      minPrice: defaultminPrice,
      maxPrice: defaultmaxPrice,
      Amenities: defaultAmini,
    };
    onclick(filterGroup);
  };

  const handleChange = (e, filtertype) => {
    const tempType = { ...Type };

    switch (filtertype) {
      case "room":
        tempType.room = !tempType.room;
        break;
      case "apartment":
        tempType.apartment = !tempType.apartment;
        break;
      case "studio":
        tempType.studio = !tempType.studio;
        break;
      case "coop":
        tempType.coop = !tempType.coop;
        break;
      case "multifamily":
        tempType.multifamily = !tempType.multifamily;
        break;
      case "townhouse":
        tempType.townhouse = !tempType.townhouse;
        break;
      case "brownstone":
        tempType.brownstone = !tempType.brownstone;
        break;
      case "estate":
        tempType.estate = !tempType.estate;
        break;
      case "vacation":
        tempType.vacation = !tempType.vacation;
        break;
      default:
        break;
    }
    setType(tempType);
    setType((state) => {
      return state;
    });
  };

  const handleViewMoreChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div
        className=" d-flex flex-column filter-body scrollS"
        style={{ width: "220px", height: "100%" }}
      >
        <div className="filter-by filter-form-row">
          <p className="filter-heading">Filter By :</p>
        </div>

        <div className="filter-form d-flex flex-column">
          <div className="type filter-form-row">
            <p className="small-heading">Category</p>
            <FormControl
              sx={{ m: 1, minWidth: 100, maxHeight: 50 }}
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={category}
                className="select-category"
                onChange={(e) => handleCategory(e)}
              >
                <MenuItem value={10}>Rental</MenuItem>
                <MenuItem value={20}>Buy</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="aminity-type filter-form-row  ">
            <p className="small-heading">Type</p>
            <div className="d-flex justify-content-between">
              <div className="checkbox-column d-flex flex-column">
                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Room"
                    name="aminity"
                    value="rooms"
                    onChange={(e) => handleChange(e, "room")}
                  />
                  <label htmlFor="Room" className="aminity-radio-label">
                    Room
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Studio"
                    name="aminity"
                    value="Studio"
                    onChange={(e) => handleChange(e, "studio")}
                  />
                  <label htmlFor="Studio" className="aminity-radio-label">
                    Studio
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Multifamily"
                    name="aminity"
                    value="Multifamily"
                    onChange={(e) => handleChange(e, "multifamily")}
                  />
                  <label htmlFor="Multifamily" className="aminity-radio-label">
                    Multifamily
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Brownstone"
                    name="aminity"
                    value="Brownstone"
                    onChange={(e) => handleChange(e, "brownstone")}
                  />
                  <label htmlFor="Brownstone" className="aminity-radio-label">
                    Brownstone
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Vacation"
                    name="aminity"
                    value="Vacation"
                    onChange={(e) => handleChange(e, "vacation")}
                  />
                  <label htmlFor="Vacation" className="aminity-radio-label">
                    Vacation
                  </label>
                </div>
              </div>

              <div className="checkbox-column d-flex flex-column  ">
                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Apartment"
                    name="aminity"
                    value="Apartment"
                    onChange={(e) => handleChange(e, "apartment")}
                  />
                  <label htmlFor="Apartment" className="aminity-radio-label">
                    Apartment
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Coop"
                    name="aminity"
                    value="Coop"
                    onChange={(e) => handleChange(e, "coop")}
                  />
                  <label htmlFor="Coop" className="aminity-radio-label">
                    Coop
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Townhouse"
                    name="aminity"
                    value="Townhouse"
                    onChange={(e) => handleChange(e, "townhouse")}
                  />
                  <label htmlFor="Townhouse" className="aminity-radio-label">
                    Townhouse
                  </label>
                </div>

                <div className="aminity-radio">
                  <input
                    type="checkbox"
                    id="Estate"
                    name="aminity"
                    value="Estate"
                    onChange={(e) => handleChange(e, "estate")}
                  />
                  <label htmlFor="Estate" className="aminity-radio-label">
                    Estate
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="bedroom-bathroom">
            <div className=" small-in-box-div">
              <div className="filter-form-row ">
                <p className="small-heading">Bedrooms</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p
                    onClick={() => setBedrooms(bedrooms + 1)}
                    className="pointer"
                  >
                    +
                  </p>
                  <p>{bedrooms}</p>
                  <p
                    onClick={() => setBedrooms(bedrooms - 1)}
                    className="pointer"
                  >
                    -
                  </p>
                </div>
              </div>
              <div className="filter-form-row">
                <p className="small-heading">Bathrooms</p>
                <div className="beadroom small-in-box d-flex justify-content-around">
                  <p
                    onClick={() => setBathrooms(bathrooms + 1)}
                    className="pointer"
                  >
                    +
                  </p>
                  <p>{bathrooms}</p>
                  <p
                    onClick={() => setBathrooms(bathrooms - 1)}
                    className="pointer"
                  >
                    -
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sqft-price filter-form-row">
            <p className="small-heading">Sqft</p>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Sqft Min"
                  onChange={(e) => {
                    setminSqft(e.target.value);
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Sqft Max"
                  onChange={(e) => {
                    setmaxSqft(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sqft-price  filter-form-row">
            <p className="small-heading">Price</p>
            <div className=" d-flex small-in-box-div">
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Price Min"
                  onChange={(e) => {
                    setminPrice(e.target.value);
                  }}
                />
              </div>
              <div className="input-sec small-in-box">
                <input
                  type="text"
                  placeholder="Price Max"
                  onChange={(e) => {
                    setmaxPrice(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="amenity-filter filter-form-row">
            <p className="small-heading">Amenities</p>
            <div className="amenity-box">
              <div className="Amenities d-flex flex-wrap ">
                {Allaminity.map((aminiti, i) => (
                  <div
                    className="select-aminity position-relative"
                    key={`aminity${i}`}
                  >
                    <input
                      type="checkbox"
                      name="aminity"
                      className="Prop aminity-input"
                      id={`filteraminity${i}`}
                      value={aminiti}
                      onChange={(e) => handleAminity(e, aminiti)}
                    />
                    <label
                      htmlFor={`filteraminity${i}`}
                      className="pointer aminity"
                    >
                      {aminiti}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div
            className="buttons pointer blue__btn adfasdfsad"
            onClick={(e) => sendFormdata()}
            style={{ margin: "auto" }}
          >
            {" "}
            Update{" "}
          </div> */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleViewMoreChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p>View More</p>
            </AccordionSummary>
            <AccordionDetails>
              <div>this is content for view more of regidential</div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ResidentialBuyMapFilter;
