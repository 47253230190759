import React, { useState } from 'react';
import "./MortgageCalculaterPop.scss";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Slider } from '@mui/material';
const MortgageCalculaterPop = (props) => {
    const { propertDetails } = props;
    let listing_amount = propertDetails?.propertDetails?.property_listing_amount
    let isResidential = propertDetails?.propertDetails?.property_listing_type?.property_listing_name === "Residential"
    const [data, setData] = useState({
        homeValue: listing_amount,
        downPayment: listing_amount * 0.2,
        loanAmount: listing_amount * 0.8,
        loanTerm: 5,
        interestRate: 5,
        // monthlyPayment: 0,
    });

    const totalLoanMonths = data.loanTerm * 12;
    const interestPerMonth = data.interestRate / 100 / 12;
    const monthlyPayment =
        (data.loanAmount * interestPerMonth * (1 + interestPerMonth) ** totalLoanMonths) /
        ((1 + interestPerMonth) ** totalLoanMonths - 1);

    return (
        <div className=' mt-3'>
            <div className=' mb-3'>
                <h6 className='Ameniti_text' style={{ color: "#315EFB" }}>{isResidential ? "Mortgage Calculator" : "Valuation Calculator"}</h6>
            </div>

            <div className='Mortgage_section_main'>
                <div className='div_one d-flex'>
                    <div className='div_one_child_one'>
                        <div className="d-flex mortgage_input">
                            <div className="input_name  text-end input_namepad">
                                {isResidential ? "Home Price" : "Purchases  Price"}
                            </div>
                            <div className="input-sec"> ${listing_amount}</div>
                        </div>

                        <div className='d-flex down_payment_main gap-3'>
                            <div className="d-flex mortgage_input" style={{ width: "90%" }}>
                                <div className="input_name  text-end input_namepad">Down Payment</div>
                                <div className="input-sec">
                                    <Slider
                                        size="small" aria-label="Small" valueLabelDisplay="auto"
                                        min={0}
                                        max={listing_amount}
                                        defaultValue={0}
                                        onChange={(e, value) =>
                                            setData({ ...data, downPayment: value.toFixed(0), loanAmount: (data.homeValue - value).toFixed(0), })
                                        }
                                        value={data.downPayment}
                                        marks
                                        step={100}
                                    />
                                </div>
                            </div>
                            <div className='downpayment_percentage mortgage_input d-flex align-items-center' style={{ width: "10%" }}>
                                <p>{((data.downPayment / listing_amount) * 100).toFixed(2)} %</p>
                            </div>
                        </div>

                        <div className="d-flex mortgage_input">
                            <div className="input_name text-end input_namepad text-capitalize">
                                {isResidential ? "Loan Program (Amount)" : "Net Operating Income"}
                            </div>
                            <div className="input-sec ">
                                <Slider
                                    size="small" aria-label="Small" valueLabelDisplay="auto"
                                    min={0}
                                    max={data.homeValue}
                                    defaultValue={data.loanAmount}
                                    onChange={(e, value) =>
                                        setData({ ...data, loanAmount: value.toFixed(0), downPayment: (data.homeValue - value).toFixed(0), })
                                    }
                                    step={100}
                                    value={data.loanAmount}
                                    marks
                                />
                            </div>
                        </div>

                        <div className='d-flex intrest_termyear gap-3'>
                            <div className="d-flex mortgage_input w-50" >
                                <div className="input_name  text-end input_namepad">Interest Rate (%)</div>
                                <div className="input-sec">
                                    <Slider size="small" aria-label="Small" valueLabelDisplay="auto"
                                        min={2}
                                        max={25}
                                        steps={0.5}
                                        defaultValue={data.interestRate}
                                        onChange={(e, value) => setData({ ...data, interestRate: value.toFixed(0), })}
                                        value={data.interestRate}
                                        marks
                                    />
                                </div>
                            </div>
                            <div className=' d-flex  mortgage_input w-50' >
                                <div className="input_name  text-end input_namepad">Term (Years)</div>
                                <div className="input-sec">
                                    <select className="" defaultValue={5} value={data.loanTerm}
                                        onChange={(e) => setData({ ...data, loanTerm: e.target.value })}>
                                        <option value={5}>5 years</option>
                                        <option value={10}>10 years</option>
                                        <option value={15}>15 years</option>
                                        <option value={20}>20 years</option>
                                        <option value={25}>25 years</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mortgage_input">
                            <div className="input_name  text-end input_namepad">Monthly Pay</div>
                            <div className="input-sec"> $ {monthlyPayment.toFixed(2)}</div>
                        </div>
                    </div>

                    <div className='div_one_child_two' >
                        <div className='' style={{ width: "100%", marginLeft: "82px", lineHeight: "1.6" }}>
                            <div className='' >
                                <div className='d-flex div_two_content'>
                                    <p style={{ fontSize: "14px" }}>Loan Amount</p>
                                    <span> <ErrorOutlineOutlinedIcon style={{ fontSize: "15px" }} /></span>

                                </div>
                                <p className='div_two_para' style={{ color: "#315EFB", fontSize: "20px" }}>
                                    $ {propertDetails?.propertDetails?.property_listing_amount}
                                </p>
                            </div>

                            <div >
                                <div className='d-flex div_two_content'>
                                    <p style={{ fontSize: "14px" }}>Annual Debt Service</p>
                                    <span> <ErrorOutlineOutlinedIcon style={{ fontSize: "15px" }} /></span>
                                </div>
                                <p className='div_two_para' style={{ color: "#315EFB", fontSize: "20px" }}>$ --</p>
                                <p className='div_two_para' style={{ color: "#315EFB", fontSize: "20px" }}>$ --/mo</p>
                            </div>

                            <div >
                                <div className='d-flex div_two_content'>
                                    <p style={{ fontSize: "14px" }}>Annual Cash Flow&I</p>
                                    <span> <ErrorOutlineOutlinedIcon style={{ fontSize: "15px" }} /></span>
                                </div>
                                <p className='div_two_para' style={{ color: "#315EFB", fontSize: "20px" }}>$ --</p>
                                <p className='div_two_para' style={{ color: "#315EFB", fontSize: "20px" }}>$ --/mo</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='div_two'>
                    <div className='d-flex' style={{ width: "100%", padding: "20px" }}>
                        <div className='' style={{ margin: "auto" }}>
                            <p className='div_two_para'>$105</p>
                            <div className='d-flex div_two_content'>
                                <p style={{ fontSize: "16px" }}>Insurance</p>
                                <span> <ErrorOutlineOutlinedIcon style={{ fontSize: "15px" }} /></span>

                            </div>
                        </div>

                        <div style={{ margin: "auto" }}>
                            <p className='div_two_para'>$105</p>
                            <div className='d-flex div_two_content'>
                                <p style={{ fontSize: "16px" }}>Taxes</p>
                                <span> <ErrorOutlineOutlinedIcon style={{ fontSize: "15px" }} /></span>
                            </div>
                        </div>

                        <div style={{ margin: "auto" }}>
                            <p className='div_two_para'>$105</p>
                            <div className='d-flex div_two_content'>
                                <p style={{ fontSize: "16px" }}>P&I</p>
                                <span> <ErrorOutlineOutlinedIcon style={{ fontSize: "15px" }} /></span>
                            </div>
                        </div>

                        <div style={{ margin: "auto" }}>
                            <p className='div_two_para'>$105</p>
                            <div className='d-flex div_two_content'>
                                <p style={{ fontSize: "16px" }}>HOA Dues</p>
                                <span> <ErrorOutlineOutlinedIcon style={{ fontSize: "15px" }} /></span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            {/* <Grid container spacing={5} alignItems="center">
                <Grid item xs={12} md={7}>
                    <SliderSelect data={data} setData={setData} />
                    <TenureSelect data={data} setData={setData} />
                </Grid>
                <Grid item xs={12} md={5}>
                    <Result data={data} />
                </Grid>
            </Grid> */}
        </div>
    )
}

export default MortgageCalculaterPop
