import React from 'react'
import { Form } from 'react-bootstrap'
// import filter from "../dashboard/components/mainSection/images/filter.svg"
// import search from "../dashboard/components/mainSection/images/search.svg"
// import PlaceIcon from '@mui/icons-material/Place';
// import OpenMiddlesec from './components/OpenMiddlesec';
import PropertyCard from '../dashboard/components/mainSection/PropertyCard';
import "./openlisting.scss"
import AdCard from '../dashboard/components/advertise/AdCard';

const OpenListing = () => {
    return (
        <>
            <div className=" d-flex flex-grow-1 flex-column gap-4">
                {/* <div className="filter-sec  d-flex justify-content-between">
                    chech box
                    <div className="buy-rent gap-3 d-flex align-items-center">
                        <div className="d-flex">
                            <input type="checkbox" name="PropType" className="Prop" id="Buy" value="Buy" />
                            <label htmlFor="Buy" className="pointer">
                                Buy
                            </label>
                        </div>
                        <div className="d-flex">
                            <input type="checkbox" name="PropType" className="Prop" id="Rent" value="Rent" />
                            <label htmlFor="Rent" className="pointer">
                                Rent
                            </label>
                        </div>
                    </div>

                    Search box
                    <div className="search-box d-flex flex-grow-1">
                        <div className="search d-flex align-items-center justify-content-between flex-grow-1">
                            <input type="text" className="trans-input" placeholder="Search Town or City" name="" id="" />
                            <div className="search-icon pointer">
                                <img src={search} alt="" />
                            </div>
                        </div>
                        <div className="filter-icon pointer d-flex align-items-center justify-content-center">
                            <img src={filter} alt="" />
                        </div>
                    </div>

                    quik-view map-view
                    <div className="quik-map center gap-3">
                        <p>Quick View</p>
                        <Form.Check className="pointer" type="switch" id="custom-switch" />
                        <p>Map View</p>
                    </div>
                </div> */}


                <div className="d-flex gap-4 open-listing-section">
                    <div className=' open-listing-left-section d-flex flex-column gap-4'>
                        <div className="buy-rent gap-3 d-flex align-items-center" style={{ height: '40px' }}>
                            <div className="d-flex">
                                <input type="checkbox" name="PropType" className="Prop" id="Buy" value="Buy" />
                                <label htmlFor="Buy" className="pointer">
                                    Buy
                                </label>
                            </div>
                            <div className="d-flex">
                                <input type="checkbox" name="PropType" className="Prop" id="Rent" value="Rent" />
                                <label htmlFor="Rent" className="pointer">
                                    Rent
                                </label>
                            </div>
                        </div>
                        <div className="open-listing-card-result d-flex flex-column">
                            <p className='center open-listing-heading'>
                                {/* <PlaceIcon style={{ width: '18px' }} />  */}
                                FOREST HILLS, QUEENS, NY</p>
                            <div className="d-flex flex-column gap-3">
                                <PropertyCard />
                                <PropertyCard />
                                <PropertyCard />
                                <PropertyCard />
                            </div>
                        </div>
                    </div>
                    {/* <OpenMiddlesec /> */}
                    <div className="Neighborhood-section d-flex flex-column gap-4" style={{ width: '220px' }}>
                        <div className='filter-sec d-flex align-items-center' style={{ height: '40px' }}>
                            <div className="quik-map center gap-3">
                                <p>Quick View</p>
                                <Form.Check className="pointer" type="switch" id="custom-switch" />
                                <p>Map View</p>
                            </div>
                        </div>
                        <div className="Neighborhood-section d-flex flex-column gap-4">
                            <p className='open-listing-heading'>Neighborhood Specialist</p>
                            <div className=" d-flex flex-column gap-2 justify-content-center align-items-center" >
                                <AdCard />
                                <AdCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OpenListing
