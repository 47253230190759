import { Modal } from 'react-bootstrap'

const SelectedSlotModal = (props) => {
    let { data, setSetshowAllTime } = props
    return (
        <>
            <Modal show={true} centered
                size="lg"
                onHide={() => setSetshowAllTime(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title"> <p className=' text-center'>30 Min Timings</p>  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex gap-3 flex-wrap justify-content-evenly">
                        <div className="d-flex flex-column align-items-center gap-2 day-slot">
                            <button className='min-show min-modal-btn center'  >Monday</button>
                            <div className="d-flex flex-column  gap-1">
                                {data.mondaySlot?.map((times, index) =>
                                    <div key={`mondaySlot${index}`}>
                                        <p className="pointer aminity min-show min-modal-btn center"> {`${times.start_time} - ${times.end_time}`} </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center gap-2 day-slot">
                            <button className='min-show min-modal-btn center'  >Tuesday</button>
                            <div className="d-flex flex-column  gap-1">
                                {data.tuesdaySlot?.map((times, index) =>
                                    <div key={`tuesdaySlot${index}`}>
                                        <p className="pointer aminity min-show min-modal-btn center"> {`${times.start_time} - ${times.end_time}`} </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center gap-2 day-slot">
                            <button className='min-show min-modal-btn center'  >Wednesday</button>
                            <div className="d-flex flex-column  gap-1">
                                {data.wednesdaySlot?.map((times, index) =>
                                    <div key={`wednesdaySlot${index}`}>
                                        <p className="pointer aminity min-show min-modal-btn center"> {`${times.start_time} - ${times.end_time}`} </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center gap-2 day-slot">
                            <button className='min-show min-modal-btn center'  >Thursday</button>
                            <div className="d-flex flex-column  gap-1">
                                {data.thursdaySlot?.map((times, index) =>
                                    <div key={`thursdaySlot${index}`}>
                                        <p className="pointer aminity min-show min-modal-btn center"> {`${times.start_time} - ${times.end_time}`} </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center gap-2 day-slot">
                            <button className='min-show min-modal-btn center'  >Friday</button>
                            <div className="d-flex flex-column  gap-1">
                                {data.fridaySlot?.map((times, index) =>
                                    <div key={`fridaySlot${index}`}>
                                        <p className="pointer aminity min-show min-modal-btn center"> {`${times.start_time} - ${times.end_time}`} </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center gap-2 day-slot">
                            <button className='min-show min-modal-btn center'  >Saturday</button>
                            <div className="d-flex flex-column  gap-1">
                                {data.saturdaySlot?.map((times, index) =>
                                    <div key={`saturdaySlot${index}`}>
                                        <p className="pointer aminity min-show min-modal-btn center"> {`${times.start_time} - ${times.end_time}`} </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center gap-2 day-slot">
                            <button className='min-show min-modal-btn center'  >Sunday</button>
                            <div className="d-flex flex-column  gap-1">
                                {data.sundaySlot?.map((times, index) =>
                                    <div key={`sundaySlot${index}`}>
                                        <p className="pointer aminity min-show min-modal-btn center"> {`${times.start_time} - ${times.end_time}`} </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SelectedSlotModal
