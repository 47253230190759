import React, { useEffect, useState } from 'react'
import { getToken } from '../../../../../../../appVariable/variable'
import { WeekStarWatch } from '../../../../server'
import "./weekly.scss"
import moment from 'moment'
const Weekly = () => {
    let token = getToken()
    let userId = token?.userId;
    const [weekStartwatch, setWeekStartwatch] = useState([])

    useEffect(() => {
        let watchobj = { "user_id": userId, "date": new Date().toJSON().slice(0, 10) }
        WeekStarWatch(watchobj, (success) => {
            if (success.data.code === 200 && success.data.status === "success") {
                setWeekStartwatch(success.data.data)
            }
        });
    }, [])
    return (
        <>
            {weekStartwatch.length ?
                <div className='weekly_tab_section'>
                    <div className='daily_tab_section w-100'>
                        <div className='row_heading'>
                            <div className="col_width"><p>Weekly</p></div>
                            <div className="col_width text-center"><p>Total Number </p></div>
                        </div>
                        <div className='scrolls_stopwatch'>
                            <div className='WEElks_outer_border'>
                                {weekStartwatch?.map((time, index) =>
                                    <div className='row_headings pad_top_bottom' key={`week${index}`}>
                                        <div className="col_width"><p>Week {time.week} {' '}
                                            ({`${moment.utc(time?.start_date).local().format("MM-DD-YYYY")}  to  ${moment.utc(time?.end_date).local().format("MM-DD-YYYY")}`})
                                        </p> </div>
                                        <div className="col_width text-center"><p>{time.count} </p> </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <p className='data-not-found'>No Data Found</p>
            }

        </>
    )
}

export default Weekly

