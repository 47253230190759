import "./Adv_Login.scss"
import Footer from '../../../homepage/components/footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css'
import AdvNavBar from '../../../../Advertising-Marketing-Pages/AdvComponent/AdNavBar/AdvNavBar';
import { NavLink } from "react-router-dom";

const Adv_Login_Page = () => {
  return (
    <>
      <AdvNavBar/>
      <div className='signup template d-flex justify-content-center align-items-center vh-100 border-0'>
        <div className='form-container rounded bg-white p-5'>
          <form className=''>
            <h2 className='text-center mb-3'>Login Account</h2>
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='email text-end ' className='text-end'> Email</label>
              <input type='email' className='pb-1 w-100' />
            </div>
            <div className='mb-3 d-flex pt-2 input_div'>
              <label htmlFor='password' className='text-end'> Password</label>
              <input type='email' className='pb-1 w-100' />
            </div>
            <div className='d-grid mt-3'>
              <button type="button" className='btn btn-primary btn-lg border-0'>
                Sign in
              </button>
            </div>
            <p className='text-center mt-3'>Not Registered Yet<NavLink to="/ad-signup" className='ms-2 text-decoration-none'>Sign up here</NavLink></p>
          </form>
        </div>
      </div>

      <Footer />

    </>
  )
}

export default Adv_Login_Page
















// //  flexDirection:"column",