import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
// import profile from "./image/profile-pic.png"
// import uplaod from "./image/upload.svg"
// import link from "./images/link.svg"
// import share from "./images/share.svg"
import listingimg from "./images/listing-img.png"
import "./AddEmployee.scss"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';


const AddEmployee = () => {
    let row = [1, 1, 1, 1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1, 1]
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleClose = () => { setAnchorEl(null); };
    return (
        <>
            <div className='AddEmployee_section'>
                <Table className='table-borderless '>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            {/* <th style={{ maxWidth: 80 }}>Profile Picture</th> */}
                            <th>Profile Picture</th>
                            <th>Salesperson Access</th>
                            <th>Boost Marketing Access</th>
                        </tr>
                    </thead>
                    <tbody className='scrollS'>
                        {row.map((info, i) =>
                            <tr className='align-middle table_row_border' key={`hbdhdb${i}`}>
                                <td className='address_width'>Ellen</td>
                                <td className='table_body_text'>Last</td>
                                <td className='table_body_text'>ell33nas@gmail.com</td>
                                <td><img src={listingimg} alt="" style={{ width: "80px" }} /></td>
                                <td><input type="checkbox" className=' pointer' name="" id="" /> </td>
                                <td>
                                    <div className='d-flex justify-content-between w-100 align-items-center'>
                                        <div><input type="checkbox" className=' pointer' name="" id="" /> </div>
                                        <div className=' pointer'>
                                            <MoreVertIcon onClick={(event) => { setAnchorEl(event.currentTarget) }} />
                                            <Menu elevation={0}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                                <MenuItem onClick={handleClose}>My account</MenuItem>
                                            </Menu>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div >
        </>
    )
}

export default AddEmployee
