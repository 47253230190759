import React, { useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import PaymentHistory from './component/paymentHistory/PaymentHistory'
import PaymentMethod from './component/paymentMethod/PaymentMethod'
import PlanAndBilling from './component/PlanAndBilling/PlanAndBilling'
import ChangePlan from './component/chnagePlan/ChangePlan'


const Neighbourhoodmains = () => {
    const [profTab, setProfTab] = useState("second")
    const ChangeTab = (key) => { setProfTab(key); };
    return (
        <>
            <div className='w-100'>
                <div className='profile-sec flex-grow-1'>
                    <Tab.Container id="left-tabs-example" onSelect={ChangeTab} activeKey={profTab}>
                        <Row>
                            <Col lg={2} className="profile-left-tab">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item><Nav.Link eventKey="first">Change Plan</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="second">Plan & Billing</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="third">Payment Method </Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="fourth">Payment History</Nav.Link></Nav.Item>
                                    {/* <Nav.Item><Nav.Link eventKey="fifth">StatWatch</Nav.Link></Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col lg={10}
                                className="tab-middle-sec hide-scrollbar">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <ChangePlan />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <PlanAndBilling ChangeTab={ChangeTab} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <PaymentMethod ChangeTab={ChangeTab} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <PaymentHistory ChangeTab={ChangeTab} />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="fifth">
                                        <StatWatch ChangeTab={ChangeTab} />
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default Neighbourhoodmains
