import React, { useState } from "react";
import "./boostMarketing.scss";
import { useNavigate } from "react-router-dom";
import MarketingImg from "./images/MarkrtingIcons";
import { Button, Modal } from "react-bootstrap";
import { TermsAndServices } from "./server";

const cardinfo = [
  { img: MarketingImg.display, title: "DISPLAY ADS", url: "plans/2" },
  { img: MarketingImg.native, title: "NATIVE ADS", url: "plans/3" },
  { img: MarketingImg.streaming, title: "STREAMING ADS", url: "plans/4" },
  { img: MarketingImg.social, title: "SOCIAL MEDIA", url: "plans/5" },
  { img: MarketingImg.podcast, title: "PODCAST ADS", url: "plans/6" },
  { img: MarketingImg.commercial, title: "COMMERCIAL ADS", url: "plans/7" },
  { img: MarketingImg.remarketing, title: "REMARKETING", url: "plans/8" },
  { img: MarketingImg.digital, title: "digital billboard", url: "plans/9" },
  {
    img: MarketingImg.paidmedia,
    title: "PAID MEDIA GOOGLE ADWORD",
    url: "plans/10",
  },
  {
    img: MarketingImg.other,
    title: "other digital marketing service",
    url: "plans/11",
  },
];
// const cardinfo = [
//     // { img: MarketingImg.sms, title: 'SMS MARKETING', url: "sms-marketing/1" },
//     { img: MarketingImg.display, title: 'DISPLAY ADS', url: "display-ads/2" },
//     { img: MarketingImg.native, title: 'NATIVE ADS', url: "native-ad/3" },
//     { img: MarketingImg.streaming, title: 'STREAMING ADS', url: "steaming-ad/4" },
//     { img: MarketingImg.social, title: 'SOCIAL MEDIA', url: "social-media/5" },
//     { img: MarketingImg.podcast, title: 'PODCAST ADS', url: "podcast-ad/6" },
//     { img: MarketingImg.commercial, title: 'COMMERCIAL ADS', url: "commercial-ad/7" },
//     { img: MarketingImg.remarketing, title: 'REMARKETING', url: "remarketing/8" },
//     { img: MarketingImg.digital, title: 'digital billboard', url: "digital-billboard/9" },
//     { img: MarketingImg.paidmedia, title: 'PAID MEDIA GOOGLE ADWORD', url: "paid-media/10" },
//     { img: MarketingImg.other, title: 'other digital marketing service', url: "digital-marketing/11" },
// ]

const BoostMarketing = () => {
  const Navigate = useNavigate();
  const [agreemodalShow, setAgreemodalShow] = useState(false);
  const [marketingTerms, setMarketingTerms] = useState();
  const [acceptterms, setAcceptterms] = useState(true);
  // const [marketingurl, setMarketingurl] = useState("sms-marketing/1")
  // const [id, setId] = useState("");
  // const Gotolink = (directto, key) => {
  //     let respObj = { "plan_type": key + 1, }
  //     setMarketingurl(directto)
  //     BoostMarketingDashboard(respObj)
  //     setAgreemodalShow(true)
  // }
  let getTermsAndServices = (id) => {
    TermsAndServices(id, (success) => {
      setMarketingTerms(success);
    });
  };
  return (
    <>
      <div className="marketing-card-container d-flex flex-wrap justify-content-evenly">
        <div className="marketing-card center box-shodow flex-column gap-4">
          <div className="marketing-logo">
            <img src={MarketingImg.sms} className="h-100" alt="" srcSet="" />
          </div>
          <div className="marketing-title text-uppercase text-center">
            <p>SMS MARKETING</p>
          </div>
          <div className="marketing-page-popup">
            <Button
              variant="outline-primary"
              className="text-uppercase boost-market-button"
              // onClick={() => Gotolink(`${card.url}`, i)}
              onClick={() => {
                setAgreemodalShow(true);
                getTermsAndServices(1);
              }}
            >
              Select
            </Button>
          </div>
        </div>

        {cardinfo.map((card, i) => (
          <div
            className="marketing-card center box-shodow flex-column gap-4"
            key={`marketing${i}`}
          >
            <div className="marketing-logo">
              <img src={card.img} className="h-100" alt="" srcSet="" />
            </div>
            <div className="marketing-title text-uppercase text-center">
              <p>{card.title}</p>
            </div>
            <div className="marketing-page-popup">
              <Button
                variant="outline-primary"
                className="text-uppercase boost-market-button"
                // onClick={() => Gotolink(`${card.url}`, i)}
                onClick={() => Navigate(card.url)}
              >
                Select
              </Button>
            </div>
          </div>
        ))}
      </div>

      <Modal
        className="boost-marketing-modal"
        show={agreemodalShow}
        onHide={() => setAgreemodalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="boost-marketing-modal-body d-flex flex-column gap-3">
            <p>{marketingTerms?.termsandservices}</p>
            <div className="d-flex justify-content-between  align-items-end">
              <div className="marketing-page-popup d-flex gap-3 align-items-center">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  className=" pointer"
                  onClick={(e) => {
                    setAcceptterms(!e.target.checked);
                  }}
                />
                <Button
                  variant="outline-primary"
                  className={`text-uppercase`}
                  disabled={acceptterms}
                  onClick={() => Navigate("plans/1")}
                  // onClick={() => Navigate("sms-marketing/1")}
                  // onClick={() => Navigate(marketingurl)}
                >
                  Accept
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BoostMarketing;
