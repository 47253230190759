import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Validation from '../../../Auth/Validation';
// import Footer from '../../../homepage/components/footer/Footer'
// import Navbar from '../../../homepage/components/navbar/Navbar'
import Loader from '../../../Loader/Loader';
import swal from "sweetalert";
import { profileSettingResetPassword } from './server';
import { toast } from 'react-toastify';

const ProfileSetResetPass = () => {
    const navigate = useNavigate();
    // const [forgetTab, setForgetTab] = useState(true)
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confpassword, setConfPassword] = useState("")
    const [error, setError] = useState("");
    const [confPassError, setConfPassError] = useState("")
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false)
    const [uid, setUid] = useState()
    const [token, setToken] = useState()


    // const location = useParams();
    // const searchParam = new URLSearchParams(window.location.search);
    // console.log(searchParam)
    // const urlUid = searchParam.get("uid");
    // const urlToken = searchParam.get("token");


    useEffect(() => {
        let urlString = window.location.href
        let paramString = urlString.split('?')[1];
        let queryString = new URLSearchParams(paramString);
        console.log(queryString.entries()[0])
        for (let pair of queryString.entries()) {
            if (pair[0] === "uid") {
                setUid(pair[1])
            }
            if (pair[0] === "token") {
                setToken(pair[1])
            }
            console.log("Key is:" + pair[0]);
            console.log("Value is:" + pair[1]);
        }
    }, [])

    const handleConfirmPassword = () => {
        let upperCase = /^(?=.*[A-Z])/;
        let Lowercase = /^(?=.*[a-z])/;
        let specialChar = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
        let digitRegex = /(?=.*?[0-9])/;

        if (password === "") {
            setError("please Enter Password ");
            return;
        }
        if (password.length < 8) {
            setError("please Enter Password mim 8 char");
            return;
        }
        if (!upperCase.test(password)) {
            setError("please Enter One Uppercase Value");
            return;
        }
        if (!Lowercase.test(password)) {
            setError("please Enter One Lowercase Value");
            return;
        }
        if (!specialChar.test(password)) {
            setError("please Enter One SpecialChar Value");
            return;
        }
        if (!digitRegex.test(password)) {
            setError("please Enter One Char in number Value");
            return;
        }

        if (confpassword !== password) {
            setConfPassError("Please Match The Password")
        }
        // else {
        //     swal("success!", "Plese Check Your Mail !", "success").then(() => {
        //         navigate("/login");
        //     });
        // }

        let userVal = {
            password: password,
            password1: confpassword,
            // token: urlToken,
            // uid: urlUid,
            token: token,
            uid: uid,
        }

        console.log("urltoken", token);
        console.log("urltoken", uid)

        setLoading(true)
        profileSettingResetPassword(userVal, (success) => {
            console.log("forgetPassword", success.data.code);
            if (success.data.code === 200 && success.data.status === "success") {
                toast.success(success.data.data, "success")
                setLoading(false)
                navigate("/agent-dashboard");
            }
            if (success.data.code === 400 && success.data.status === "failed") {
                setLoading(false)
                swal("Warning", success.data.data, "warning");
            }

        })


    };

    return (
        <div className='w-100'>
            {loading ? <Loader /> : null}

            <div className="loginpage_body forget-page profile_reset_password" style={{ backgroundImage: "none" }}>
                <div className="loginpage_form center flex-column">
                    <div className=''>
                        <div className="loginpage_text">
                            <div className="loginpage_text_head">Forgot Password</div>
                            {/* <div className="loginpage_text_subhead "></div> */}
                        </div>
                        <div className="loginpage_inputs" style={{ maxWidth: "500px", margin: "auto" }}>
                            <div className="input-container">
                                <i className="fa fa-envelope icon"></i>
                                <span className='login-divider'></span>
                                <input className="input-field" type={isRevealPwd ? "text" : "password"}
                                    placeholder="Password"
                                    name="password"
                                    autoComplete="off"
                                    onChange={(e) => setPassword(e.target.value)}
                                    // onBlur={formik.handleBlur}
                                    value={password} />

                                {/* view icon of password */}
                                <div style={{ cursor: "pointer" }} onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                    {isRevealPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                </div>


                            </div>
                            {error ? (
                                <div className='validation-text log-validation'>{error}</div>
                            ) : null}
                            {/* validation code start */}
                            <Validation formikPass={password} />

                            {/* validation code End */}

                            <div className="input-container">
                                <i className="fa fa-key icon"></i>
                                <span className='login-divider'></span>
                                <input className="input-field" type={isRevealConfirmPwd ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    name="confirmpassword"
                                    autoComplete="off"
                                    onChange={(e) => setConfPassword(e.target.value)}

                                    value={confpassword} />
                                {/* view icon of password */}
                                <div style={{ cursor: "pointer" }} onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}>
                                    {isRevealConfirmPwd ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
                                </div>
                            </div>
                            {confPassError ? (
                                <div className='validation-text log-validation'>{confPassError}</div>
                            ) : null}
                        </div>

                        <div className="loginpage_help_button justify-content-center mb-3 mx-auto">
                            <div className="loginpage_signin">
                                <button className="loginpage_signin_btn" type="submit"
                                    onClick={handleConfirmPassword}
                                >
                                    <span className="loginpage_signin_btn_text">Reset</span>
                                </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProfileSetResetPass
