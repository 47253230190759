import React from 'react'
import "./Adv_How_it_works.scss"
import Stunning from '../../ImgAdv/Stunning.png'
import dowt from '../../ImgAdv/dowt.png'
import number1 from '../../ImgAdv/number1.png'

const Adv_How_it_works = () => {
  return (
    <section className='Advertise4 mb-5'>
      <div className='text-center '>
        <h2 className='EstimateHeading pb-5'>How it works</h2>
      </div>


      <div className='d-flex text-start  advNumberImg'>
        <div className='text-center headingContent'>
          <img src={number1} alt="#" className='number1Img' />
        </div>
        <div className='Estimate_H'>
          <h2 className='getHeading pt-4'>Get A Free Estimate</h2>
        </div>
      </div>
      <div className='text-left Estimate-text w-100'>
        <p className='p-text '>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
      </div>
      <div className='Img_Content d-flex h-75'>
        <img src={Stunning} className='EstimateImg' alt="#" />
        <div>
          <img src={dowt} className='dowtImg2 border-none w-100' alt="#" />
        </div>
      </div>
      {/* ----------------------------------------pop desing------------------------------------------------------- */}
      <div className='pop-design'>
        
        <h4 className='estimateB_heading'>Your Bedroom Estimate</h4>
        <div className='pop_design_content justify-content-center text-center'>
          <div className='justify-content-between d-flex'>
            <p>Design</p>
            <p>$3,000</p>
          </div>
          <div className='justify-content-between d-flex'>
            <p>Materials</p>
            <p>$6,000</p>
          </div>
          <div className='justify-content-between d-flex'>
            <p>Project Management</p>
            <p>$4,000</p>
          </div>
          <div className='justify-content-between d-flex'>
            <p>Construction</p>
            <p>$24,000</p>
          </div>
          <div className='justify-content-between d-flex border-bottom-0' >
            <p>Estimated Total</p>
            <p>$37,000</p>
          </div>
        </div>
        <h4 className='Renova_heading'>Homer Renovation</h4>
      </div>
    </section>
  )
}

export default Adv_How_it_works
