import "./Fsbho_plan.scss"
import { IoIosCheckmarkCircle } from 'react-icons/io';
import CancelIcon from '@mui/icons-material/Cancel';
// import Bounce from 'react-reveal/Bounce';
const Fsbho_plan = ({ allDetails }) => {

    return (
        <div className='sub_plan m-0 mt-3 ps-2'>
            <h2 className=''>Including Our Plans</h2>
            <div className=' plan-body scrollS'>
                {allDetails.map((plan, i) =>
                    <div className='justify-content-start d-flex text-start plans ' key={`fsho${i}`}>
                        <div className='me-2'>
                            {plan?.is_active ?
                                <IoIosCheckmarkCircle style={{ fontSize: "16px", color: 'green' }} /> :
                                <CancelIcon style={{ fontSize: "16px", color: 'red' }} />
                            }
                        </div>
                        <p>{plan.service_name}</p>
                    </div>
                )}

            </div>
        </div>

    )
}

export default Fsbho_plan
