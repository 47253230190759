import React from 'react'
import FeatureColumn from './FeatureColumn'
import "./featuretable.scss"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// let features = [
//     { title: '300 ACTIVE LISTINGS' },
//     { title: 'RESIDENTIAL LISTINGS SALES & RENTALS OPEN & EXCLUSIVE LISTINGS' },
//     { title: 'COMMERCIAL LISTINGS SALES & LEASING OPEN & EXCLUSIVE LISTINGS' },
//     { title: 'BOOST MARKETING' },
//     { title: 'PROFILE PROMOTED RECOGNIZED AS SPECIALIST 1-6 SPECIFIC TOWNS' },
//     { title: 'FSBHO & PRIVATE RENTER LEADS PROVIDED' },
//     { title: 'PROFILE PROMOTED TO PROPERTY MANAGERS & LANDLORDS' },
//     { title: 'PROFILE PROMOTED TO PROPERTY INVESTORS & DEVELOPERS' },
//     { title: '30 MINUTE SHOWINGS ASSIGN LISTINGS FOR IMMEDIATE SHOWING CUSTOMERS EXPECTING SHOWING WITHIN 30 -45 MINUTES' },
//     { title: 'PERSONAL WEBPAGE - BIO PAGE' },
//     { title: 'STAT-WATCH MONITORING # OF LISTINGS DAILY/ WEEKLY /MONTHLY /QUARTERLY' },
//     { title: "PERSONALIZED URL'S" },
//     { title: 'TEAMS' },
//     { title: 'CUSTOMIZE TEAM MEMBERS & SPECIFIC LISTINGS' },
//     { title: 'SUPPORT TICKETS PERSONAL HELP WITH ISSUES ON SITE YOU NEED ADDRESSED' },
//     { title: 'Reposting one, some or all listings with one click. Save Time Dates are adjusted to current date and if there is an end date it is adjusted forward for the same amount of time on original listing.' },
//     { title: 'DOCUMENT SHARING SHARE YOUR DISCLOSURES AGREEMENTS FAIR HOUSING AGREEMENTS BUYER OR SELLER CONTRACTS TO REMAIN COMPLIANT AND NOT MISS ANY OPPORTUNITY TO TURN CUSTOMER INTO A CLIENT' },
//     { title: 'Syndication Company listings or personal listings can be syndicated to your account without having to re-post listings' },
//     { title: 'Analytic Information View Count /Contact Click though rate – engagements Heat map of listings – are ppl looking or skipping listings same for pictures of listing' },
// ]
export const handleClickScroll = () => {
    const element = document.getElementById('PlanTable');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
    }
};
const FeatureTable = (props) => {
    const { plandescription, residential, resiAndCommercial, commercial, neighbourHood } = props
    // console.log("residential", residential)
    return (
        <>
            <div style={{ marginTop: '76px' }} id='PlanTable' className="PlanTable " >
                <p className={`tableHeading text-center`}>Compare Our Plans</p>
                <div className="d-flex  tableBody justify-content-between mx-auto m-0">
                    <Row className=' w-100'>
                        <Col md={4} className='description-parent me-3 ps-0'>
                            <div className={`d-flex flex-column  description h-100`}>
                                <p className='ChartText' style={{ fontSize: '16px' }}>DESCRIPTION</p>
                                <div className={`d-flex flex-column align-self-start text-start text-uppercase w-100`}
                                    style={{ gap: '42px', padding: '64px 10px 0px 14px' }} >
                                    {plandescription?.map((item, i) => <p key={i} id={`feature${i}`}
                                        className={`feature ChartText ChartText w-100`} >{item.service_name}</p>)}
                                </div>
                            </div>
                        </Col>
                        <Col className='table-col' ><FeatureColumn title='RESIDENTIAL' active={false} data={residential} /></Col>
                        <Col className='table-col' ><FeatureColumn title='RESIDENTIAL AND COMMERCIAL' active={true} data={resiAndCommercial} /></Col>
                        <Col className='table-col' ><FeatureColumn title='COMMERCIAL' active={false} data={commercial} /></Col>
                        <Col className=' pe-0 table-col' ><FeatureColumn title='NEIGHBORHOOD SPECIALIST' active={false} data={neighbourHood} /></Col>
                    </Row>
                </div>

            </div>
        </>
    )
}

export default FeatureTable
