import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const Validation = ({ formikPass }) => {

  return (
    <div className="d-flex flex-wrap validation pt-2" style={{ justifyContent: "center" }}>

      <div className="val-div-one" style={{ display: "flex" }}>
        <div
          className={
            formikPass.length >= 8
              ? 'd-flex align-items-center me-3 active'
              : 'd-flex align-items-center me-3'
          }
        >

          {formikPass.length >= 8 ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
          <span className="ms-1">Min 8 Char</span>

        </div>

        <div
          className={
            /^(?=.*[A-Z])/.test(formikPass)
              ? 'd-flex align-items-center me-3 active'
              : 'd-flex align-items-center me-3'
          }
        >

          {/^(?=.*[A-Z])/.test(formikPass) ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}

          <span className="ms-1">One Uppercase</span>

        </div>

        <div
          className={
            /^(?=.*[a-z])/.test(formikPass)
              ? 'd-flex align-items-center me-3 active'
              : 'd-flex align-items-center me-3'
          }
        >

          {/^(?=.*[a-z])/.test(formikPass) ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}

          <span className="ms-1">One Lowercase</span>

        </div>





      </div>

      <div className='val-div-two' style={{ display: "flex" }}>
        <div
          className={
            /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/.test(
              formikPass
            )
              ? 'd-flex align-items-center me-3 active'
              : 'd-flex align-items-center me-3'
          }
        >

          {/^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/.test(
            formikPass
          )
            ? <CheckCircleIcon className="circle-icon" />
            : <CheckCircleOutlineIcon />}

          <span className="ms-1">One Special Char</span>

        </div>

        <div
          className={
            /(?=.*?[0-9])/.test(
              formikPass
            )
              ? 'd-flex align-items-center me-3 active'
              : 'd-flex align-items-center me-3'
          }
        >

          {/(?=.*?[0-9])/.test(
            formikPass
          )
            ? <CheckCircleIcon className="circle-icon" />
            : <CheckCircleOutlineIcon />}

          <span className="ms-1">One Number</span>

        </div>


      </div>

    </div>
  )
}

export default Validation