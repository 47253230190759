import React, { useEffect, useState } from 'react'
import { getAllpropertyInfo } from '../dashboard/components/mainSection/server';
import { useParams } from 'react-router-dom';
import PropertyInfoComponent from '../dashboard/propperty-datails/component/PropertyInfoComponent';
import Navbar from '../../pages/homepage/components/navbar/Navbar'

const ShareProperty = () => {
    const [propertyDetailInfo, setPropertyDetailInfo] = useState({})
    const { id } = useParams();

    let propertDetails = async (id) => { await getAllpropertyInfo(id, (success) => { setPropertyDetailInfo(success) }); }

    useEffect(() => { propertDetails(id) }, [])

    return (
        <div className="d">
            <Navbar />
            <div className=' container mx-auto' style={{ maxWidth: "1300px" }}>
                {Object.keys(propertyDetailInfo).length > 0 && <PropertyInfoComponent propertyDetailInfo={propertyDetailInfo} />}
            </div>
        </div>
    )
}

export default ShareProperty