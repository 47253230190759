
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MasterCard_Logo from "./Images/MasterCard_Logo.png"
import "./paymentMethod.scss";
// import Atm from "./Images/atm.jpg";
// import Atm1 from "./Images/atm2.jpg";
import garbage from "./Images/garbage.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddnewCard, deletecard, getuserCards } from "../../../check-out-page/server";
import moment from "moment";
import { toast } from "react-toastify";
import { getToken } from "../../../../../appVariable/variable";
import Loader from "../../../Loader/Loader";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/es/styles-compiled.css';

function maskCreditCardNumber(creditCardNumber) {
    const maskedNumber = creditCardNumber.slice(-4).padStart(creditCardNumber.length, 0);
    return maskedNumber;
}

// import Addnewcard from "./Addnewcard";
const PaymentMethod = () => {
    let token = getToken()
    let userId = token?.userId;
    const [modalShow, setModalShow] = useState(false);
    const [savecards, setSavecards] = useState();
    const [loading, setLoading] = useState(false)

    const NewCardformik = useFormik({
        initialValues: { CardHolderName: "", CardNumber: "", ExpiryDate: "", cvc: "", },
        validationSchema: Yup.object({
            CardHolderName: Yup.string().required("*Card Holder Name required"),
            CardNumber: Yup.string().min(16, "please enter correct number").max(16, "please enter correct number").required("*Card Number required"),
            ExpiryDate: Yup.date().required("*Expiry Date required"),
            cvc: Yup.string().min(3, "please enter correct cvv").max(3, "please enter correct cvv").required("*cvc required"),
        }),
        onSubmit: (values, action) => {
            let cardobj = {
                "user_id": userId,
                "card_name": values.CardHolderName,
                "card_number": `${values.CardNumber}`,
                "expirary_date": moment(values.ExpiryDate).format("MM/YY"),
                "cvv": values.cvc
            }
            setLoading(true)
            AddnewCard(cardobj, (success) => {
                if (success.code === 200 && success.status === "success") {
                    setLoading(false)
                    toast.success(success.data, "success")
                    getuserCards((success) => { setSavecards(success) })
                    setModalShow(false)
                }
                if (success.code === 400 && success.status === "failed") {
                    setLoading(false)
                    toast.success(success.data, "error")
                    setModalShow(false)
                }
            });
            action.resetForm();
        },
    });

    let deleteusercard = async (deleteid) => {
        setLoading(true)
        await deletecard(deleteid, (success) => {
            setLoading(false)
            toast.success("Card delete successfully", "success")
            getuserCards((success) => { setSavecards(success) })
            // console.log(success)
        });
    }

    useEffect(() => {
        getuserCards((success) => { setSavecards(success) })
    }, [])


    return (
        <>
            {loading ? <Loader /> : null}
            <div className="Paymentmethod_sectiON">
                <div className="Dgrid">
                    {savecards?.map((card, i) =>
                        <div key={`card${i}`}>
                            <Cards key={`card${i}`}
                                // number={parseInt(card.card_number)}
                                number={(maskCreditCardNumber(`${card.card_number}`))}
                                // number={parseInt(maskCreditCardNumber(`${card.card_number}`))}
                                name={card.card_name}
                                expiry={card.month <= 9 ? `0${card.month}${card.year}` : `${card.month}${card.year}`}
                            />
                            <div className=" d-flex gap-2 mt-2  mx-auto" style={{ maxWidth: '290px' }}>
                                <Button className="btn_payment default flex-grow-1">Make Default</Button>
                                <Button className="btn_payment delete flex-grow-1"
                                    onClick={() => { deleteusercard(card.id) }}
                                ><img src={garbage} alt="" /></Button>
                            </div>
                        </div>
                    )}


                    <div className="add_card pointer" onClick={() => setModalShow(true)} >
                        <button className="m-auto" >
                            <span className="add_symbol">+</span>
                            <p className="pt-2 mt-1">Add New Card</p>
                        </button>
                    </div>
                </div>


                {/*start Check Out Page Add New Card Popup */}
                <div className="forgot_modals">
                    <Modal className="forgot_modals" show={modalShow} centered
                        onHide={() => setModalShow(false)} size="lg"
                        ria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton className="border-bottom-0" >
                            <Modal.Title id="contained-modal-title-vcenter " className="border-0 " ></Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="CheckOutPage_body">
                            <div className='card_img_end pb-3'>
                                <img src={MasterCard_Logo} alt="" className='MasterCard_img' />
                            </div>
                            <div className="d-flex main_input">
                                <div className="input_name grow-1 text-end input_namepad">
                                    Card Holder Name<span className="red-star">*</span>
                                </div>
                                <div className="input-sec d-flex align-items-center">
                                    <input type="text" className="form-control border-0 pb-0"
                                        name="CardHolderName"
                                        onChange={NewCardformik.handleChange}
                                        value={NewCardformik.values.CardHolderName}
                                    />
                                    {NewCardformik.errors.CardHolderName ? (
                                        <div className='validation-text'>{NewCardformik.errors.CardHolderName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex main_input">
                                <div className="input_name grow-1 text-end input_namepad">
                                    Card Number<span className="red-star">*</span>
                                </div>
                                <div className="input-sec d-flex align-items-center">
                                    <input type="number" className="form-control border-0 pb-0"
                                        name="CardNumber"
                                        onChange={NewCardformik.handleChange}
                                        value={NewCardformik.values.CardNumber}
                                    />
                                    {NewCardformik.errors.CardNumber ? (
                                        <div className='validation-text'>{NewCardformik.errors.CardNumber}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className='d-flex gap_input'>
                                <div className="flex-grow-1">
                                    <div className="d-flex main_input pt">
                                        <div className="input_name grow-1 text-end input_namepad Expiry_Date_width">
                                            Expiry Date<span className="red-star">*</span>
                                        </div>
                                        <div className="input-sec d-flex align-items-center">
                                            <input type="month" className="form-control border-0 pb-0"
                                                name="ExpiryDate"
                                                onChange={NewCardformik.handleChange}
                                                value={NewCardformik.values.ExpiryDate}
                                            />
                                            {NewCardformik.errors.ExpiryDate ? (
                                                <div className='validation-text'>{NewCardformik.errors.ExpiryDate}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex main_input">
                                        <div className="input_name grow-1 text-end input_namepad cvc_width">
                                            cvv<span className="red-star">*</span>
                                        </div>
                                        <div className="input-sec d-flex align-items-center">
                                            <input type="number" className="form-control border-0 pb-0"
                                                name="cvc"
                                                onChange={NewCardformik.handleChange}
                                                value={NewCardformik.values.cvc}
                                            />
                                            {NewCardformik.errors.cvc ? (
                                                <div className='validation-text'>{NewCardformik.errors.cvc}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 d-grid">
                                    <button type="submit" className="submit_btn btn-block"
                                        onClick={NewCardformik.handleSubmit}>Pay Now
                                    </button>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                </div>
                {/*end Check Out Page Add New Card Popup */}
            </div>
        </>
    )
}

export default PaymentMethod
