import React from 'react'
import { Button } from 'react-bootstrap'
import { FaLongArrowAltRight } from 'react-icons/fa'
import qualityimg from '../../ImgAdv/quality-img.png'
import "./Adv_Quality.scss"
const Adv_Quality = (props) => {
  const { title, description, buttonTitle } = props
  return (
    <div className='quality_container justify-content-center d-flex' >
      <img src={qualityimg} className='qualityimg' alt="#" />
      <div className='quality_text_content justify-content-center ' >
        <h2>{title}</h2>
        <p className="mt-5 second_text">{description}</p>
        <Button size="lg" className="mt-5 app-blue-btn border-0" active style={{ background: "#43A4F6" }}>
          {buttonTitle}
          <FaLongArrowAltRight className='mx-5' />
        </Button>
      </div>
    </div>
  )
}

export default Adv_Quality
