import React from 'react'
import { Button } from 'react-bootstrap'
import "./virtualOfficeCard.scss"
import property from "./images/property.png"
import user from "./images/user.png"
import bed from "./images/bed.svg"
const VirtualOfficeCard = () => {
    // const cardRow = [1, 1, 1, 1]
    return (
        <>
            {/* <>
                <div className="VirtualOfficeCads scrollS">
                    <div className='d-flex flex-column gap-2'>
                        {cardRow.map((onerow, i) =>
                            <div className="property-card d-flex flex-column justify-content-around pointer" key={`row${i}`}>
                                <div className="property-img">
                                    <img src={property} alt="" />
                                </div>

                                <div className="property-detail d-flex justify-content-between align-items-center">
                                    <div className="property-detail-left d-flex flex-column">
                                        <div className="d-flex">
                                            <p className='price pe-2'>$15,480</p> <div className="border-start pe-2"></div>
                                            <img src={bed} alt="" /> <div className='dbr_text'>0BR</div>
                                        </div>
                                        <div>
                                            <p className=' mb-1'>Long Island City</p>
                                            <p>48-21 40th St</p>
                                        </div>
                                    </div>


                                    <div className="property-detail-right center flex-column gap-2">
                                        <div className='center'>
                                            <img src={user} alt="" />
                                        </div>
                                        <Button variant="outline-primary" className=' '>1 Year</Button>

                                    </div>

                                </div>
                                <div className='d-flex card_btns gap-1'>
                                    <button className='blue__btn  '>Interested</button>
                                    <button className='blue__btn '>Don’t Like</button>
                                </div>

                                <div >
                                    <button className="addNotBtn  w-100">Add Note</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='d-flex py-3'>
                    <input type="checkbox" name="" id="check" />
                    <label htmlFor="check" className='select_all'>Select All</label>
                </div>

                <div>
                    <button className='send_tobtn blue__btn'>
                        Send to Review
                    </button>
                </div>
            </> */}
            <div className="VirtualOfficeCads">
                <div className="OfficeProperty-card d-flex flex-column justify-content-around pointer" >
                    <div className="property-img position-relative">
                        <img src={property} alt="" />
                        <input type="checkbox" name="" id="" className='select-property position-absolute pointer' />
                    </div>

                    <div className="property-detail d-flex justify-content-between align-items-center">
                        <div className="property-detail-left d-flex flex-column">
                            <div className="d-flex">
                                <p className='price pe-2'>$15,480</p> <div className="border-start pe-2"></div>
                                <img src={bed} alt="" /> <div className='dbr_text'>0BR</div>
                            </div>
                            <div>
                                <p className=' mb-1'>Long Island City</p>
                                <p>48-21 40th St</p>
                            </div>
                        </div>


                        <div className="property-detail-right center flex-column gap-2">
                            <div className='center'>
                                <img src={user} alt="" />
                            </div>
                            <Button variant="outline-primary" className=' '>1 Year</Button>

                        </div>

                    </div>

                    <div className='d-flex card_btns gap-1'>
                        <button className='blue__btn  '>Interested</button>
                        <button className='blue__btn '>Don’t Like</button>
                    </div>

                    <div >
                        <button className="addNotBtn  w-100">Add Note</button>
                    </div>
                </div>
            </div>




        </>
    )
}

export default VirtualOfficeCard
