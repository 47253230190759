import React, { useEffect, useState } from 'react'
import "./Fsbho_Subcription.scss"
import Fsbho_card from './Fsbho_card';
import Fsbho_plan from './Fsbho_plan';
import { getSubscriptionDetails, getsubcriptionPlan } from '../../../Subscription/server';

const Fsbho_Subcription = () => {
    const [tab, settab] = useState(true);
    const [subscriptionInfo, setSubscriptionInfo] = useState([])
    const [allDetails, setAllDetails] = useState([])


    function getPlansData() {
        getsubcriptionPlan((success) => {
            let item = success[0]
            let info = {
                id: item.id,
                heading: item.Name,
                title: item.titles,
                monthly_price: item.monthly_price,
                yearly_price: item.yearly_price,
                services: item.Subscription_services,
            }
            setSubscriptionInfo(info)
        });
    }
    function getSubscriptionPlansDetails() {
        getSubscriptionDetails((success) => {
            setAllDetails(success)
        });
    }


    useEffect(() => {
        getPlansData()
        getSubscriptionPlansDetails()
    }, [])

    return (
        <div className='sub_container h-100 w-100 mt-2'>
            <div className=" center flex-column gap-2">
                <div className="subscription-heading d-flex align-items-center flex-column gap-2">
                    <h3>Best Plan For You !</h3>
                    <p>Lorem Ipsum is simply dummy text of the typesetting</p>
                </div>
                <div className="subscription-tab d-flex gap-2">
                    <div className={`center pointer tab-btn ${tab ? "monthly-active" : ""}`}
                        onClick={() => settab(true)}>Monthly </div>
                    <div className={`center pointer tab-btn ${tab ? "" : "yearly-active"}`}
                        onClick={() => settab(false)} >Yearly</div>
                </div>
            </div>
            {/* ------------------------call component subscription plan--------------------------------- */}
            <div className='row plans_content mt-3 py-2 px-2 m-0'>
                <div className='col-md-4'>
                    <Fsbho_card
                        subscriptionInfo={subscriptionInfo} monthly={tab}
                        allDetails={allDetails}
                    />
                </div>
                <div className='col-md-8'>
                    <Fsbho_plan allDetails={allDetails}/>
                </div>
            </div>
        </div>
    )
}

export default Fsbho_Subcription
