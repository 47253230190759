import React, { useState } from 'react'
import yes from "../cards/image/yes.svg"
import no from "../cards/image/no.svg"

const FeatureColumn = (props) => {
    const { data } = props
    const [isHide, setIsHide] = useState(true);
    let eleHeight = document.getElementsByClassName("feature");
    setTimeout(() => setIsHide(false), 500);

    let available = [
        { status: 1 }, { status: 0 }, { status: 1 }, { status: 0 }, { status: 1 }, { status: 0 },
        { status: 1 }, { status: 0 }, { status: 1 }, { status: 0 }, { status: 1 }, { status: 0 },
        { status: 1 }, { status: 0 }, { status: 1 }, { status: 0 }, { status: 1 }, { status: 0 }, { status: 0 }
    ]
    return (
        <>
            {!isHide ?
                <div className='d-flex align-items-center flex-column mx-auto'
                // style={{ flex: '1 1 130px' }}
                // style={{ width:'198px' }}
                >

                    <div className={`d-flex flex-column plan ${props.active ? 'activeCol' : null}`}>
                        <p className="ChartText" style={{ fontSize: '16px', fontWeight: '500' }}>{props.title}</p>

                        {/* Feature TickMark */}
                        <div className="d-flex flex-column w-100" style={{
                            gap: '42px',
                            paddingTop: "64px"
                            //  paddingTop: ((props.title).length) > 15 ? "50px" : "64px"

                        }}>
                            {data?.map((feature, index) =>
                                <div key={index} className='d-flex align-items-center justify-content-center'
                                    style={{ height: eleHeight[index]?.offsetHeight }}
                                >
                                    <img src={feature.status ? yes : no} alt="" srcSet="" className="featureIcon" />
                                </div>
                            )}
                        </div>

                    </div>

                    <div className={`d-flex  align-items-center  justify-content-center ColumnBottom`}>
                        <p>Choose Plan</p>
                    </div>

                </div>
                : null}
        </>
    )
}

export default FeatureColumn
