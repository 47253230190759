import React, { useEffect } from "react";
import "./Terms.scss";
import { Link } from "react-router-dom";
import logo from "./image/logosvg.svg"
// import bgimg from "./image/termbg.png"
import bgimg from "./image/privacybg.png"
import Footer from "../homepage/components/footer/Footer";

const Policy = () => {
  const path = window.location.href.split("/");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);


  return (
    <>
      <div>
        <div className="term-privacy-container">
          <div className="terms-header position-relative">
            <div className="skyblue-bottom-line position-absolute w-100"></div>
            <div className="orange-bottom-line  position-absolute w-100"></div>
            <img src={bgimg} style={{ maxWidth: '50%', marginTop: '5px' }} alt="" />
            <div className="home-link-logo  position-absolute mx-auto">
              <Link to="/"><img src={logo} alt=""/></Link>
            </div>
            <div className="terms-heading  position-absolute"><h1>Privacy Policy</h1></div>
          </div>

          <div className="container mt-5">
            <h4 className="policy_subheading">INTRODUCTION AND AGREEMENT </h4>
            <p className="policy_paragraph">
              <ul className="policy_list">
                <li className="policy_list_item">
                  Thank you for choosing to be part of our community at AFISADO
                  LLC, doing business as AFISADO (“AFISADO”, “we”, “us”, or
                  “our”). We are committed to protecting your personal information
                  and your right to privacy. If you have any questions or concerns
                  about this privacy notice, or our practices with regards to your
                  personal information, please contact us at
                  <a href="mailto:support@afisado.com">support@afisado.com</a>
                </li>
                <br />
                <li className="policy_list_item">
                  When you visit our website
                  <a href="https://www.afisado.com">https://www.afisado.com</a>
                  (the "Website"), and any related mobile application, use any of
                  our services (the "Services", which include the Website), we
                  appreciate that you are trusting us with your personal
                  information. We take your privacy very seriously and take
                  reasonable steps to protect user privacy consistent with the
                  guidelines set forth in this Privacy Policy and with applicable
                  U.S. laws. In this privacy notice, we seek to explain to you in
                  the clearest way possible what information we collect, how we
                  use it and what rights you have in relation to it. We hope you
                  take some time to read through it carefully, as it is important.
                  If there are any terms in this privacy notice that you do not
                  agree with, please discontinue use of our Services immediately.
                </li>
                <br />
                <li className="policy_list_item">
                  When using our Website, you may choose to interact with features
                  from third parties that operate independently from AFISADO, such
                  as social media widgets and links to third-party widgets.
                  AFISADO has no control over and is not responsible for the
                  privacy practices of such third parties. Our Privacy Policy does
                  not apply to the extent AFISADO does not own or control any
                  linked third-party widgets or features you visit or use. We
                  recommend that you familiarize yourself with the privacy
                  practices of these third parties.
                </li>{" "}
                <br />
                <li className="policy_list_item">
                  This privacy notice applies to all information collected through
                  our Services (which, as described above, includes our Website).{" "}
                </li>{" "}
                <br />
                <li className="policy_list_item" style={{ fontWeight: "bold" }}>
                  Please read this privacy notice carefully as it will help you
                  understand what we do with the information that we collect.
                </li>{" "}
                <br />
              </ul>
            </p>
            <h4 className="policy_subheading">TABLE OF CONTENT </h4>
            <p className="policy_paragraph">
              <ul>
                <li>
                  <a href="#sectionone">1. WHAT INFORMATION DO WE COLLECT? </a>
                </li>{" "}
                <li>
                  <a href="#sectiontwo">2. HOW DO WE USE YOUR INFORMATION? </a>
                </li>{" "}
                <li>
                  <a href="#sectionthree">
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectionfour">
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectionfive">
                    5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectionsix">
                    6. HOW LONG DO WE KEEP YOUR INFORMATION?{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectionseven">
                    7. HOW DO WE KEEP YOUR INFORMATION SAFE?{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectioneight">
                    8. DO WE COLLECT INFORMATION FROM MINORS?{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectionnine">9. WHAT ARE YOUR PRIVACY RIGHTS? </a>
                </li>{" "}
                <li>
                  <a href="#sectionten">
                    10. CONTROLS FOR DO-NOT-TRACK FEATURES{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectioneleven">
                    11. WEBSITE VISITORS FROM OUTSIDE THE UNITED STATES{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectiontwelve">12. STATEMENT REGARDING THE GDPR </a>
                </li>{" "}
                <li>
                  <a href="#sectionthirteen">
                    13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectionfourteen">
                    14. DO WE MAKE UPDATES TO THIS NOTICE?{" "}
                  </a>
                </li>{" "}
                <li>
                  <a href="#sectionfifteen">
                    15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
                  </a>
                </li>
              </ul>
            </p>
            <section id="sectionone">
              <h4 className="terms_subheading">
                1. WHAT INFORMATION DO WE COLLECT?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li style={{ fontWeight: "bold" }}>
                    Personal information you disclose to us{" "}
                  </li>
                  <li>
                    In Short: We collect select information not all from what you
                    provide to us.{" "}
                  </li>{" "}
                  <li>
                    We collect personal information that you voluntarily provide
                    to us when you visit or register on the Website, express an
                    interest in obtaining information about us or our products and
                    Services, when you participate in activities on the Website or
                    otherwise when you contact us. The personal information that
                    we collect depends on the context of your interactions with us
                    and the Website, the choices you make and the features you
                    use. The personal information we collect may include the
                    following:{" "}
                  </li>{" "}
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Personal Information Provided by You.
                    </span>
                    We collect names; email addresses; phone numbers; IP
                    addresses; real estate license number, contact preferences;
                    and other similar information like frequency of visit and
                    Website preferences you select. All personal information that
                    you provide to us must be true, complete and accurate, and you
                    must notify us of any changes to such personal information.{" "}
                  </li>
                  <li style={{ fontWeight: "bold" }}>
                    Information automatically collected{" "}
                  </li>
                  <li>
                    In Short: Some information — such as your Internet Protocol
                    (IP) address and/or browser and device characteristics — is
                    collected automatically when you visit our Website.{" "}
                  </li>
                  <li>
                    We automatically collect certain information when you visit,
                    use or navigate the Website. This information does not reveal
                    your specific identity (like your name or contact information)
                    but may include information, such as your IP address, browser
                    and device characteristics, language preferences, referring
                    URLs, device name, location, information about who and when
                    you use our Website and other technical information. This
                    information is primarily needed to maintain the security and
                    operation of our Website, and for our internal analytics and
                    reporting purposes.{" "}
                  </li>
                  <li>
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                  </li>
                  <li style={{ fontWeight: "bold" }}>
                    The information we collect includes:{" "}
                  </li>
                  <li>
                    Log and Usage Data. Log and usage data is service-related,
                    diagnostic usage and performance information our servers
                    automatically collect when you access or use our Website and
                    which we record in log files. Depending on how you interact
                    with us, this log data may include your IP address, device
                    information, browser type and Website settings and information
                    about your activity in the Website (such as the date/time
                    stamps associated with your usage, pages, saved preferences
                    and files viewed, searches and other actions you take such as
                    which features you use), device event information (such as
                    system activity, error reports and site settings). The
                    information allows us to provide you a better experience on
                    the Website.{" "}
                  </li>
                  <li>
                    Device Data. We collect device data such as information about
                    your computer, phone, tablet or other device you use to access
                    the Website. Depending on the device used, this device data
                    may include information such as your IP address (or proxy
                    server), device application identification numbers, location,
                    and browser type.{" "}
                  </li>
                  <li>
                    Location Data. We collect information data such as information
                    about your device's location, which can be either precise or
                    imprecise. We limit information we collect, we limit it based
                    upon type of user who accesses the Website. For example, if
                    you are browsing to buy or rent the Website we retain your
                    town and zip code information. If you are a subscription
                    member we retain your account information. We may use GPS and
                    other technologies to collect geolocation data that tells us
                    your current location (based on your IP address). Note
                    however, if you choose to opt out by turning off your location
                    settings, this may cause some features not to work or be able
                    to use certain aspects of the Services.{" "}
                  </li>
                </ul>
              </p>
            </section>
            <br />
            <section id="sectiontwo">
              <h4 className="terms_subheading">
                2. HOW DO WE USE YOUR INFORMATION?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: We process select information for collection
                    purposes. We only share information you chose to provide to
                    our third party participants. We do not sell your information.{" "}
                  </li>
                  <li>
                    We use personal information collected via our Website for a
                    variety of business purposes described below. We process your
                    personal information for these purposes in reliance on our
                    legitimate business interests, in order to enter into or
                    perform a contract with you, with your consent, and/or for
                    compliance with our legal obligations. We indicate the
                    specific processing grounds we rely on next to each purpose
                    listed below.{" "}
                  </li>
                  <li>We use the information we collect or receive: </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      To facilitate account creation and logon process.
                    </span>
                    We use your information to facilitate account creation and
                    logon process for the performance of registration and account
                    login.{" "}
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Request feedback. </span>
                    We may use your information to request feedback and to contact
                    you about your use of our Website.
                    <li>
                      To enable user-to-user communications. We may use your
                      information in order to enable user-to-user communications
                      with each user's consent.
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        To manage user accounts.
                      </span>
                      We may use your information for the purposes of managing
                      our account and keeping it in working order.
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        To send you marketing communications based upon your saved
                        preferences.
                      </span>
                      We may use your preference information for sending new
                      properties in accordance with your search preferences.
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        Deliver targeted advertising to you.
                      </span>
                      Our third-party marketing partners may use the personal
                      information you chose to send them in order to contact you
                      by email for the selected service you requested.{" "}
                    </li>
                  </li>
                </ul>
              </p>
            </section>
            <br />
            <section id="sectionthree">
              <h4 className="terms_subheading">
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: We only share information with your consent, to
                    comply with laws, to provide you with services, to protect
                    your rights, or to fulfill business obligations.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Consent: </span>
                    We may process your data if you have given us specific consent
                    to use your personal information in a specific purpose.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Legitimate Interests:
                    </span>
                    We may process your data when it is reasonably necessary to
                    achieve our legitimate business interests.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Performance of a Contract:
                    </span>
                    Where we have entered into a Service Agreement with you, we
                    may process your personal information to fulfill the Terms of
                    Service.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Legal Obligations:</span>
                    We may disclose your information where we are legally
                    required to do so in order to comply with applicable law,
                    governmental requests, a judicial proceeding, court order, or
                    legal process, such as in response to a court order or a
                    subpoena (including in response to public authorities to meet
                    national security or law enforcement requirements).
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Vital Interests:</span>
                    We may disclose your information where we believe it is
                    necessary to investigate, prevent, or take action regarding
                    potential violations of our policies, suspected fraud,
                    situations involving potential threats to the safety of any
                    person and illegal activities, or as evidence in litigation in
                    which we are involved.
                  </li>

                  <li>
                    More specifically, we may need to process your data or share
                    your personal information in the following situations:
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Business Transfers.
                    </span>
                    We may share or transfer your information in connection with,
                    or during negotiations of, any merger, sale of company assets,
                    financing, or acquisition of all or a portion of our business
                    to another company.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Vendors and Other Third-Party Service Providers
                    </span>
                    . We may share your data with third-party vendors, service
                    providers, contractors or agents who perform services for us
                    or on our behalf and require access to such information to do
                    that work. Examples include: payment processing, data
                    analysis, email delivery, hosting services, customer service
                    and marketing efforts. We may allow selected third parties to
                    use tracking technology on the Website, which will enable them
                    to collect data on our behalf about how you interact with our
                    Website over time. This information may be used to, among
                    other things, analyze and track data, determine the popularity
                    of certain content, pages or features, and better understand
                    online activity. Unless described in this notice, we do not
                    share, sell, rent or trade any of your information with third
                    parties for their promotional purposes.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>Other Users.</span>
                    When you share personal information (for example, by
                    requesting information, contributions of a review or other
                    content to the Website) or otherwise interact with public
                    areas of the Website, such personal information may be viewed
                    by all users and may be publicly made available to the Website
                    in perpetuity. Similarly, other users will be able to view
                    descriptions of your activity, communicate with you within our
                    Website, and view your profile.
                  </li>
                </ul>
              </p>{" "}
            </section>
            <br />
            <section id="sectionfour">
              <h4 className="terms_subheading">
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: We may use cookies and other tracking technologies
                    to collect and store your information.
                  </li>
                  <li>
                    We may use cookies and similar tracking technologies (like web
                    beacons and pixels) to access or store information. Cookies
                    are pieces of text that may be provided to your computer
                    through your web browser when you access a website. Your
                    browser stores cookies in a manner associated with each
                    website you visit. We use cookies to enable our servers to
                    recognize your web browser and tell us how and when you visit
                    the Site and otherwise use the Services through the Internet.
                  </li>
                  <li>
                    This Privacy Policy covers our use of cookies only and does
                    not cover the use of cookies by third parties. We do not
                    control when or how third parties place cookies on your
                    computer. For example, third party websites to which a link
                    points may set cookies on your computer.
                  </li>
                  <li>We use the following types of cookies:</li>
                  <li>
                    Essential Cookies. Essential Cookies are required for
                    providing you with features or services that you have
                    requested. For example, certain Cookies enable you to log into
                    secure areas of our Services. Disabling these Cookies may make
                    certain features and services unavailable.
                  </li>
                  <li>
                    Functional Cookies. Functional Cookies are used to record your
                    choices and settings regarding our Services, maintain your
                    preferences over time and recognize you when you return to our
                    Services. These Cookies help us to personalize our content for
                    you, greet you by name, and remember your preferences (for
                    example, your choice of language or region).
                  </li>
                  <li>Aggregated or De-identified Data:</li>
                  <li>
                    We collect aggregated or de-identified information that cannot
                    reasonably identify, relate to, describe, be capable of being
                    associated with, or be linked, directly or indirectly, to a
                    particular user.
                  </li>{" "}
                </ul>
              </p>{" "}
            </section>
            <br />
            <section id="sectionfive">
              <h4 className="terms_subheading">
                5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: We are not responsible for the safety of any
                    information that you share with third-party providers who
                    advertise, but are not affiliated with, our Website.
                  </li>
                  <li>
                    The Website may contain advertisements from third parties that
                    are not affiliated with us and which you may choose to provide
                    information for services. We cannot guarantee the safety and
                    privacy of data you provide to any third parties. Any data
                    provided to third parties is not covered by this privacy
                    notice. We are not responsible for the content or privacy and
                    security practices and policies of any third parties,
                    including other websites, services or applications that may be
                    linked to or from the Website. You should review the policies
                    of such third parties and contact them directly to respond to
                    your questions.
                  </li>
                </ul>
              </p>
            </section>
            <br />
            <section id="sectionsix">
              <h4 className="terms_subheading">
                6. HOW LONG DO WE KEEP YOUR INFORMATION?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: We keep your information for as long as necessary to
                    fulfill the purposes outlined in this privacy notice unless
                    otherwise required by law.
                  </li>
                  <li>
                    We will only keep your personal information for as long as it
                    is necessary for the purposes set out in this privacy notice,
                    unless a longer retention period is required or permitted by
                    law (such as tax, accounting or other legal requirements). No
                    purpose in this notice will require us keeping your personal
                    information for longer than the period of time in which users
                    have an account with us.
                  </li>
                  <li>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or anonymize
                    such information, or, if this is not possible (for example,
                    because your personal information has been stored in backup
                    archives), then we will securely store your personal
                    information and isolate it from any further processing until
                    deletion is possible.
                  </li>
                </ul>
              </p>
            </section>
            <br />
            <section id="sectionseven">
              <h4 className="terms_subheading">
                7. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: We aim to protect your personal information through
                    a system of organizational and technical security measures.
                  </li>
                  <li>
                    We have implemented appropriate technical and organizational
                    security measures designed to protect the security of any
                    personal information we process. However, despite our
                    safeguards and efforts to secure your information, no
                    electronic transmission over the Internet or information
                    storage technology can be guaranteed to be 100% secure, so we
                    cannot promise or guarantee that hackers, cybercriminals, or
                    other unauthorized third parties will not be able to defeat
                    our security, and improperly collect, access, steal, or modify
                    your information. Although we will do our best to protect your
                    personal information, transmission of personal information to
                    and from our Website is at your own risk. You should only
                    access the Website within a secure environment.
                  </li>{" "}
                </ul>
              </p>
              <br />{" "}
            </section>
            <section id="sectioneight">
              <h4 className="terms_subheading">
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: We do not knowingly collect data from or market to
                    children under 18 years of age.
                  </li>
                  <li>
                    We do not knowingly solicit data from or market to children
                    under 18 years of age.
                    <span style={{ fontWeight: "bold" }}>
                      BY USING THE WEBSITE, YOU REPRESENT THAT YOU ARE AT LEAST
                      18. IF YOU ARE NOT 18 YEARS OLD YOU ARE NOT PERMITTED TO USE
                      OUR SERVICES. IF WE LEARN THAT PERSONAL INFORMATION FROM
                      USERS LESS THAN 18 YEARS OF AGE HAS BEEN COLLECTED, WE WILL
                      DEACTIVATE THE ACCOUNT AND TAKE REASONABLE MEASURES TO
                      PROMPTLY DELETE SUCH DATA FROM OUR RECORDS. IF YOU BECOME
                      AWARE OF ANY DATA WE MAY HAVE COLLECTED FROM CHILDREN UNDER
                      AGE 18, PLEASE CONTACT US AT
                      <a href="mailto:support@afisado.com">
                        {" "}
                        support@afisado.com
                      </a>
                    </span>
                  </li>{" "}
                </ul>
              </p>
            </section>
            <br />
            <section id="sectionnine">
              <h4 className="terms_subheading">
                9. WHAT ARE YOUR PRIVACY RIGHTS?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: You may review, change, or terminate your account at
                    any time.
                  </li>
                  <li>
                    If you have questions or comments about your privacy rights,
                    you may email us at
                    <a href="mailto:someone@support@afisado.com">
                      {" "}
                      support@afisado.com
                    </a>
                  </li>
                  <li style={{ fontWeight: "bold" }}>Account Information</li>
                  <li>
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you can
                    visit your setting to make changes. To remain compliant with
                    our Terms of Service all account information must remain
                    accurate and true. Choosing to do otherwise with an active
                    account will violate our Terms of Service, your account will
                    become suspend and terminated if corrections are not made.
                  </li>
                  <li>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, we may retain some information in
                    our files to prevent fraud, troubleshoot problems, assist with
                    any investigations, enforce our Terms of Use and/or comply
                    with applicable legal requirements.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Cookies and similar technologies:
                    </span>
                    Most Web browsers are set to accept cookies by default. If
                    you prefer, you can usually choose to set your browser to
                    remove cookies and to reject cookies. If you choose to remove
                    cookies or reject cookies, this could affect certain features
                    or services of our Website.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>
                      Opting out of email marketing:
                    </span>
                    You can unsubscribe from our marketing email list at any time
                    by clicking on the unsubscribe link in the emails that we send
                    or by contacting us using the details provided below. You will
                    then be removed from the marketing email list – however, we
                    may still communicate with you, for example to send you
                    service-related emails that are necessary for the
                    administration and use of your account, to respond to service
                    requests, or for other non-marketing purposes. To otherwise
                    opt-out, you may unsubscribe through
                    <a href="mailto:unsubscribe@afisado.com">
                      {" "}
                      unsubscribe@afisado.com
                    </a>
                  </li>{" "}
                </ul>
              </p>
            </section>
            <br />
            <section id="sectionten">
              <h4 className="terms_subheading">
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    Most web browsers and some mobile operating systems and mobile
                    applications include a Do-Not-Track (“DNT”) feature or setting
                    you can activate to signal your privacy preference not to have
                    data about your online browsing activities monitored and
                    collected. At this stage, no uniform technology standard for
                    recognizing and implementing DNT signals has been finalized.
                    As such, we do not currently respond to DNT browser signals or
                    any other mechanism that automatically communicates your
                    choice not to be tracked online. If a standard for online
                    tracking is adopted that we must follow in the future, we will
                    inform you about that practice in a revised version of this
                    privacy notice.
                  </li>
                </ul>
              </p>{" "}
            </section>
            <br />
            <section id="sectioneleven">
              <h4 className="terms_subheading">
                11. WEBSITE VISITORS FROM OUTSIDE THE UNITED STATES
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    AFISADO and services are located in the United States and are
                    subject to the applicable state and federal laws of the United
                    States. If you choose to access the Website, you consent to
                    the use and disclosure of information in accordance with this
                    Privacy Policy and subject to such laws.
                  </li>
                </ul>
              </p>
              <br />
              <h4 className="terms_subheading" id="sectiontwelve">
                12. STATEMENT REGARDING THE GDPR
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    AFISADO is not currently subject to the General Data
                    Protection Regulation (EU) (“GDPR”). AFISADO does not act as
                    either a controller or processor of personal data provided by
                    EU data subjects, as those terms are understood under the
                    GDPR. AFISADO markets and provides its products and services
                    to entities and individuals based in the United States.
                    AFISADO does not have a presence in the European Union, and
                    does not offer or otherwise market any products or services to
                    individuals based in the EU. Although AFISADO is not subject
                    to the GDPR, AFISADO takes seriously its customers’ privacy,
                    and is committed to protecting Personal Information in
                    accordance with this Privacy Policy and applicable law. Should
                    you have any questions, please do not hesitate to contact us
                    at
                    <a href="mailto:support@afisado.com">support@afisado.com</a>
                  </li>
                </ul>
              </p>{" "}
            </section>
            <br />{" "}
            <section id="sectionthirteen">
              <h4 className="terms_subheading">
                13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: Yes, if you are a resident of California, you are
                    granted specific rights regarding access to your personal
                    information.
                  </li>
                  <li>
                    California Civil Code Section 1798.83, also known as the
                    “Shine The Light” law, permits our users who are California
                    residents to request and obtain from us, once a year and free
                    of charge, information about categories of personal
                    information (if any) we disclosed to third parties for direct
                    marketing purposes and the names and addresses of all third
                    parties with which we shared personal information in the
                    immediately preceding calendar year. If you are a California
                    resident and would like to make such a request, please submit
                    your request in writing to us using the contact information
                    provided below.
                  </li>
                  <li>
                    If you are under 18 years of age, reside in California, and
                    have a registered account with the Website, you have the right
                    to request removal of unwanted data that you publicly post on
                    the Website. To request removal of such data, please contact
                    us using the contact information provided below, and include
                    the email address associated with your account and a statement
                    that you reside in California. We will make sure the data is
                    not publicly displayed on the Website, but please be aware
                    that the data may not be completely or comprehensively removed
                    from all our systems (e.g. backups, etc.).
                  </li>
                  <li style={{ fontWeight: "bold" }}> CCPA Privacy Notice</li>
                  <li>
                    The California Code of Regulations defines a "resident" as:
                  </li>
                  <li>
                    (1) every individual who is in the State of California for
                    other than a temporary or transitory purpose and
                  </li>
                  <li>
                    (2) every individual who is domiciled in the State of
                    California who is outside the State of California for a
                    temporary or transitory purpose
                  </li>
                  <li>All other individuals are defined as "non-residents."</li>
                  <li>
                    If this definition of "resident" applies to you, certain
                    rights and obligations apply regarding your personal
                    information.
                  </li>
                  <li style={{ fontWeight: "bold" }}>
                    What categories of personal information do we collect?
                  </li>
                  <li>
                    We have collected the following categories of personal
                    information and :
                  </li>
                </ul>

                <table className="policy_table">
                  <tr>
                    <th>Category</th>
                    <th>Why </th>
                    <th>Who Recieves Your information</th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li style={{ fontWeight: "bold" }}>A. Identifiers </li>
                        <li>
                          Contact details, such as real name, alias, postal
                          address, telephone or mobile contact number, unique
                          personal identifier, online identifier, Internet
                          Protocol address, email address and account name{" "}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li style={{ fontWeight: "bold" }}>
                          Use information to:{" "}
                        </li>
                        <li>
                          Operate the Website and provide the Website Services to
                          you. Communicate with you. Provide customer service,
                          support, and account security. Detect and investigate
                          fraud, bugs, and security risks, and to comply with
                          legal obligations. Improve Website functionality,
                          product offerings, and customer experience Personalize
                          or customize your experience using the Website Process
                          transactions Perform analytic analysis to measure
                          Performance, improve performance, and to better market
                          our Website and Services Personalize, measure, and
                          improve our marketing and advertising.{" "}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li style={{ fontWeight: "bold" }}>
                          We share identifiers with the following:
                        </li>
                        <li>
                          With other Users, Third Parties when you direct us to,
                          and the Website Service Providers. Ex: when you submit
                          your information for third party services.{" "}
                        </li>
                        <li>
                          Ex: Contact other Users on the Website. Ex: Service
                          Providers who help operate our Website.{" "}
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      C. Protected classification characteristics under California
                      or federal law: Gender and date of birth{" "}
                    </td>
                    <td>
                      Use information to: Includes but not limited to verifying
                      users of the Website, and providing services customized to
                      your experience{" "}
                    </td>
                    <td>
                      We share identifiers with the following: The Website Service
                      Providers who help in the daily operations of the Website
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      D. Commercial information: Transaction information, browsing
                      history, payment information, your records of personal
                      property, products or services you have obtained, or
                      considered, your Website preferences, including details
                      about the properties you have applied to rent, information
                      about roommates, what listings you saved, messages about
                      properties listed on the Website sent through third-party
                      syndication platforms such as Facebook Marketplace,
                      Information regarding your preferences as a User, property
                      listing information (including address, description, and
                      photos) and messages sent through the Website{" "}
                    </td>
                    <td>
                      Use information to: Create User account profiles for viewing
                      Create a page for a listing to be viewed Improve Website
                      functionality, product offerings, and customer experience
                      Personalize or customize your experience using the Website
                      Perform analytic analysis to measure performance, improve
                      performance, and to better market our Website and Services
                      Personalize, measure, and improve our marketing and
                      advertising{" "}
                    </td>
                    <td>
                      We share identifiers with the following: The information is
                      shared with the Website Service Providers who help in the
                      daily operations of the Website, limited information shared
                      with other Users and Third Party Services{" "}
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      F. Internet or other similar network activity: Browsing
                      history, search history, online behavior, interest data, and
                      interactions with our and other websites, applications,
                      systems and advertisements
                    </td>
                    <td>
                      Use information to: Detect and investigate fraud, bugs, and
                      security risks, and to comply with legal obligations Improve
                      Website functionality, product offerings, and customer
                      experience Personalize and measure or customize your
                      experience using the Website Target advertisements to our
                      existing users, and improve our marketing and advertising
                      Perform analytic analysis to measure performance, improve
                      performance, and to better market our Website and Services{" "}
                    </td>
                    <td>
                      We share identifiers with the following: The information is
                      shared with the Website Service Providers who help in the
                      daily operations of the Website
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      G. Geolocation data: Device precise or approximate location{" "}
                    </td>
                    <td>
                      Use information to: Improve Website functionality, product
                      offerings, and customer experience Personalize or customize
                      your experience using the Website Perform analytic analysis
                      to measure performance, improve performance, and to better
                      market our Website and Services Personalize, measure, and
                      improve our marketing and advertising{" "}
                    </td>
                    <td>
                      We share identifiers with the following: The Website Service
                      Providers who help in the daily operations of the Website,
                      other Users and Third Party Services whose services are
                      limited to geography
                    </td>
                  </tr>
                  <tr>
                    <td>
                      H. Biometric information, electronic and visual: Including
                      but not limited to photos of yourself, and other photos and
                      videos of your property listings, and your business logos{" "}
                    </td>
                    <td>
                      Use information to: We use this information to help you
                      identify yourself, and other photos to other users, create a
                      page for a listing to be viewed{" "}
                    </td>
                    <td>
                      We share identifiers with the following: With other Users,
                      Third Parties when you direct us to, and the Website Service
                      Providers. Ex: when you submit your information for third
                      party services. Ex: Contact other Users on the Website. Ex:
                      Service Providers who help operate our Website.{" "}
                    </td>
                  </tr>
                </table>

                <ul>
                  <li>
                    More information about our data collection and sharing
                    practices
                  </li>
                  <li style={{ fontWeight: "bold" }}>
                    Will your information be shared with anyone else?
                  </li>
                  <li>
                    Your information is not shared with additional parties that
                    have not been mentioned.
                  </li>
                  <li>
                    The categories of third parties to whom we disclosed personal
                    information for a business or commercial purpose can be found
                    under "WHO WILL YOUR INFORMATION BE SHARED WITH?"
                  </li>
                  <li>Your rights with respect to your personal data</li>
                  <li>
                    Right to request deletion of the data - Request to delete
                  </li>
                  <li>
                    You can ask for the deletion of your personal information. If
                    you ask us to delete your personal information, we will
                    respect your request and delete your personal information,
                    subject to certain exceptions provided by law, such as (but
                    not limited to) the exercise by another consumer of his or her
                    right to free speech, our compliance requirements resulting
                    from a legal obligation or any processing that may be required
                    to protect against illegal activities.
                  </li>
                  <li>Right to be informed - Request to know</li>
                  <li>
                    Depending on the circumstances, you have a right to know:
                  </li>
                  <li>whether we collect and use your personal information;</li>
                  <li>the categories of personal information that we collect;</li>
                  <li>
                    the purposes for which the collected personal information is
                    used;
                  </li>
                  <li>
                    whether we sell your personal information to third parties;
                  </li>
                  <li>
                    the categories of personal information that we sold or
                    disclosed for a business purpose;
                  </li>
                  <li>
                    the categories of third parties to whom the personal
                    information was sold or disclosed for a business purpose; and
                  </li>
                  <li>
                    the business or commercial purpose for collecting or selling
                    personal information.
                  </li>
                </ul>
                <ul>
                  <li>
                    In accordance with applicable law, we are not obligated to
                    provide or delete consumer information that is de-identified
                    in response to a consumer request or to re-identify individual
                    data to verify a consumer request.
                  </li>
                  <li>
                    Right to Non-Discrimination for the Exercise of a Consumer's
                    Privacy Rights
                  </li>
                  <li>
                    We will not discriminate against you if you exercise your
                    privacy rights.
                  </li>
                  <li>Verification process</li>
                  <li>
                    You may request for specific pieces of personal information
                    that may have been collected about you in the past 12 months,
                    or request the categories of personal information and how
                    AFISADO used and disclosed your personal information that may
                    have been collected about you in the past 12 months.
                  </li>
                  <li>
                    Upon receiving your request, we will need to verify your
                    identity to determine you are the same person about whom we
                    have the information in our system. These verification efforts
                    require us to ask you to provide information so that we can
                    match it with the information you have previously provided us.
                    For instance, depending on the type of request you submit, we
                    may ask you to provide certain information so that we can
                    match the information you provide with the information we
                    already have on file, or we may contact you through a
                    communication method (e.g. phone or email) that you have
                    previously provided to us. We may also use other verification
                    methods as the circumstances dictate.
                  </li>
                  <li>
                    We will only use personal information provided in your request
                    to verify your identity or authority to make the request. To
                    the extent possible, we will avoid requesting additional
                    information from you for the purposes of verification. If,
                    however, if we cannot verify your identity from the
                    information already maintained by us, we may request that you
                    provide additional information for the purposes of verifying
                    your identity, and for security or fraud-prevention purposes.
                    We will delete such additionally provided information as soon
                    as we finish verifying you.
                  </li>
                </ul>

                <ul>
                  <li>Other privacy rights</li>
                  <li>you may object to the processing of your personal data</li>
                  <li>
                    you may request correction of your personal data if it is
                    incorrect or no longer relevant, or ask to restrict the
                    processing of the data
                  </li>
                  <li>
                    you can designate an authorized agent to make a request under
                    the CCPA on your behalf. We may deny a request from an
                    authorized agent that does not submit proof that they have
                    been validly authorized to act on your behalf in accordance
                    with the CCPA.
                  </li>
                  <li>
                    Upon receiving a request to opt-out, we will act upon the
                    request as soon as feasibly possible.
                  </li>
                  <li>
                    To exercise these rights, you can contact us by email at
                    <a href="mailto:support@afisado.com">support@afisado.com</a>.
                    If you have a complaint about how we handle your data, we
                    would like to hear from you.
                  </li>
                </ul>
              </p>{" "}
            </section>
            <br />{" "}
            <section id="sectionfourteen">
              <h4 className="terms_subheading">
                14. DO WE MAKE UPDATES TO THIS NOTICE?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    In Short: Yes, we will update this notice as necessary to stay
                    compliant with relevant laws.
                  </li>
                  <li>
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated “Revised” date
                    and the updated version will be effective as soon as it is
                    accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                  </li>
                </ul>
              </p>
            </section>
            <br />{" "}
            <section id="sectionfifteen">
              <h4 className="terms_subheading">
                15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </h4>
              <p className="terms_paragraph">
                <ul>
                  <li>
                    Based on the applicable laws of your country, you may have the
                    right to request access to the personal information we collect
                    from you, change that information, or delete it in some
                    circumstances. To request to review, update, or delete your
                    personal information, please submit a request. We will respond
                    to your request within 30 days. If you have questions or
                    comments about this notice, you may email us at
                    <a href="mailto:support@afisado.com"> support@afisado.com</a>
                  </li>
                </ul>
              </p>
            </section>
            <br />
            {/* </div> */}
          </div>
        </div>
        <Footer />
      </div>



    </>
  )
}

export default Policy
