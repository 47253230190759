import React, { useState } from 'react'
// import {features} from "./Smallcard"
import "./plancard.scss"
import yes from "./image/yes.svg"
import no from "./image/no.svg"

const Activecard = (props) => {
    const [showfeature, setShowfeature] = useState(false);
    const { data, description, setPlanid } = props
    //   console.log("desc active",description)
    // console.log("data active",data)
    return (
        <>
            <div className='center flex-column'>

                <div className="Plan-Card active-card d-flex align-items-center pointer" onClick={() => { props.setAddonplan(true); setPlanid(data.id) }}>
                    <p className="card-title">{data?.heading}</p>
                    <span className='card-description'
                        style={{ marginTop: (data?.heading)?.length > 15 ? "9px" : "32px" }}
                    >{data?.title}</span>
                    <p className="crad-price mt-3" >${data?.price}</p>

                    {/* Showing card features  */}
                    <div className="d-flex flex-column" style={{ gap: '15px', marginTop: '28px' }}>

                        {/* showing some features */}
                        {(data?.services?.slice(0, 3))?.map((item, index) =>
                            <div className="d-flex" style={{ gap: '17px' }} key={index} >
                                <img className="featureIcon" src={item?.status ? yes : no} alt="" srcSet="" />
                                <p className="features" style={{ color: item?.status ? null : '#8C98CA' }}>{item?.lable}</p>
                            </div>
                        )}

                        {/* showing All features */}
                        {showfeature && (data?.services?.slice(3))?.map((item, index) =>
                            <div className="d-flex" style={{ gap: '17px' }} key={index} >
                                <img className="featureIcon" src={item?.status ? yes : no} alt="" srcSet="" />
                                <p className="features" style={{ color: item?.status ? null : '#8C98CA' }}>{item?.lable}</p>
                            </div>
                        )}
                    </div>

                    {/* see all plan btn */}
                    <a href='#PlanTable' className="d-none d-lg-flex center see-all-btn "
                        onClick={(e) => { e.stopPropagation(); }}
                    ><p>SEE ALL FEATURES</p></a>

                    {/* <div className="d-flex d-lg-none center see-all-btn" >
                        <p onClick={() => setShowfeature(!showfeature)}> {showfeature ? "HIDE" : "SEE"} ALL FEATURES</p>
                    </div> */}
                </div>
                <div className="center card-bottom-btn"
                    onClick={() => { props.setAddonplan(true); setPlanid(data.id) }}
                ><p >Choose Plan</p></div>
            </div>
        </>
    )
}

export default Activecard
