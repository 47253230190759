import React from 'react';
import AdvNavBar from '../AdvComponent/AdNavBar/AdvNavBar';
import AdvMainSecontionOne from '../AdvComponent/AdvMainBody/AdvMainSecontionOne';
const AdvertisingMain = () => {
  return (
    <div>
      <AdvNavBar/>
      <AdvMainSecontionOne/>
      {/* <AdvMainSecontionOne/> */}
    </div>
  )
}

export default AdvertisingMain
