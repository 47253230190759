import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Rectangle_2 from '../ImgAdv/Rectangle_2.png'
import dowt from '../ImgAdv/dowt.png'
import "./AdvHomePage.scss"
// import Navbar from '../../homepage/components/navbar/Navbar'
import { FaLongArrowAltRight } from 'react-icons/fa';
import Adv_StunningDesign from './Stunning_Page/Adv_StunningDesign';
import Adv_Quality from './Quality_Page/Adv_Quality';
import Adv_EstimateDesign from './Estimate_Page/Adv_EstimateDesign';
import Adv_How_it_works from './How_Work_Page/Adv_How_it_works';
import Adv_Dream_Home from './Dream_Page/Adv_Dream_Home';
import Adv_Why_Home from './Why_Home_Page/Adv_Why_Home'
import Saying from './Saying_Page/Saying';
import { Fade} from 'react-awesome-reveal';
import Aos from 'aos'
import 'aos/dist/aos.css'
import AdvNavBar from '../../../Advertising-Marketing-Pages/AdvComponent/AdNavBar/AdvNavBar';
// import 


const AdvertisementHomePage = () => {
  useEffect(()=> {
    Aos.init();
  },[])
  return (
    <>
      <AdvNavBar/>
      <section  className='Advertise mb-5' data-aos = "fade-up">
        <div className='Adv-main-container'>
          <div className="">
           <div className='text-content'>
              <h1 className="">Lorem Ipsum is Simply Simply Dummy Text of of The Printing and Typesetting Industry.
                Lorem Ipsum Has Been The Industry's Standard, Lorem Ipusm Has Been</h1>
                  <p className="mt-5 First_text">Lorem ipsum is Simply Dummy Text Of The Printing And Typesetting indutry.
                Lorem Ipsum Has Been The Industry's Standard
                Lorem ipsum is Simply Dummy Text Of The Printing And Typesetting indutry.
                Lorem Ipsum Has Been The Industry's Standard
                Lorem ipsum is Simply Dummy Text Of The Printing And Typesetting indutry.
                Lorem ipsum is Simply Dummy Text Of The Printing And Typesetting indutry.
                Lorem Ipsum Has Been The Industry's Standard
                Lorem ipsum is Simply Dummy Text Of The Printing And Typesetting indutry.
                Lorem Ipsum Has Been The Industry's Standard</p>
              <Button size="lg" className="mt-5 app-blue-btn" active>
                Get an Estimate
                <FaLongArrowAltRight className='mx-3' />
              </Button>
            </div>
          </div>
        </div>
        <img src={Rectangle_2} className='home' alt="#" />
        <img src={dowt} className='dowtImg1' alt="#" />
      </section>

      <Fade direction="left"> 
      <Adv_StunningDesign
        title="Stunning Designs"
        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
        buttonTitle="Explore Design"/>
      </Fade>

      <Fade direction="right"> 
      <Adv_Quality
        title="Quality Meterials"
        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
        buttonTitle="Learn More"
      />

      </Fade>
      <Fade direction="left" > 
      <Adv_StunningDesign
        title="Elevated Construction"
        description="anc Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
        buttonTitle="Learn More"
      /></Fade>
      <Fade direction="right" > 
      <Adv_Quality
        title="Smart Technology"
        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
        buttonTitle="Learn More"
      />
      </Fade>

      <Fade direction="left" > <Adv_How_it_works /></Fade>
      <Fade direction="right" ><Adv_EstimateDesign /> </Fade>
      <Fade direction="left" > <Adv_Dream_Home /></Fade>
      <Fade direction="right" ><Adv_Why_Home /></Fade>
      <Fade direction="left" > <Saying /></Fade>
      

    </>
  )
}

export default AdvertisementHomePage 
