import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import "./paymentHistory.scss"
import pdf from "./image/pdf.svg"
import { BillingHistory } from '../../server'
import moment from 'moment'

const PaymentHistory = () => {
    const [BillPaymentHistory, setBillPaymentHistory] = useState()
    useEffect(() => {
        BillingHistory((success) => { setBillPaymentHistory(success); })
    }, [])

    return (
        <>

            <div className='current_listing_page paymentTable' >
                <Table className='table-borderless' >
                    <thead>
                        <tr>
                            <th >Payment Date</th>
                            <th>Plan Name</th>
                            <th>Neighborhood Specialist</th>
                            <th>Amount</th>
                            <th>Statment</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className='hide-scrollbar pt-1'>

                        {BillPaymentHistory?.map((payment, index) =>
                            <tr className='align-middle table_row_border' key={`payments${index}`}>
                                <th >{moment.utc(payment.payment_date).local().format("MM-DD-YYYY")}</th>
                                <th>{payment.Plan_Name}</th>
                                <th>{payment.Neighborhood}</th>
                                <th>$ {payment.Amount}</th>
                                <th><div className='left_side'><button className='paid-btn' >PAID</button></div></th>
                                <th><div className='statement'><img src={pdf} alt="" /> </div></th>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default PaymentHistory
