import { Modal } from "react-bootstrap"
import "./OPenHouseTimeModal.scss"
import { useFormik } from "formik";
import * as Yup from "yup";
// import { getToken } from "../../../../../appVariable/variable";
import { AllDays } from "../../../profile/components/minute/Minute";
import { useEffect, useState } from "react";
import SlotBook from "../../../profile/components/minute/SlotBook";

const OPenHouseTimeModal = (props) => {
    const { openHouseModal, setOpenHouseModal, setOpenHouseData, openHouseData } = props
    const [currentDay, setCurrentDay] = useState("sun")
    function checkEmptyArrays(obj) { return Object.values(obj).every(value => Array.isArray(value) && value.length === 0); }
    let timeValidation = {
        start_time: Yup.string().required('start time required').nullable(),
        end_time: Yup.string().required('End time required').test('timeCheck', 'End time must be greater than start time', function (endTime) {
            const startTime = this.parent.start_time; // Access the value of startTime in the parent object
            if (!startTime || !endTime) return true; // Skip validation if either value is empty
            const startTimeObj = new Date(`1970-01-01T${startTime}`);
            const endTimeObj = new Date(`1970-01-01T${endTime}`);
            // Compare the time values
            return startTimeObj < endTimeObj;
        }).nullable()
    }
    const formik = useFormik({
        initialValues: {
            sunday_open_house: [],
            monday_open_house: [],
            tuesday_open_house: [],
            wednesday_open_house: [],
            thursday_open_house: [],
            friday_open_house: [],
            saturday_open_house: [],
        },
        validateOnChange: false, validateOnBlur: false,
        validationSchema: Yup.object({
            sunday_open_house: Yup.array(Yup.object(timeValidation)),
            monday_open_house: Yup.array(Yup.object(timeValidation)),
            tuesday_open_house: Yup.array(Yup.object(timeValidation)),
            wednesday_open_house: Yup.array(Yup.object(timeValidation)),
            thursday_open_house: Yup.array(Yup.object(timeValidation)),
            friday_open_house: Yup.array(Yup.object(timeValidation)),
            saturday_open_house: Yup.array(Yup.object(timeValidation))
        }),
        onSubmit: async (values, action) => {
            setOpenHouseData(values)
            setOpenHouseModal(false)
        },
    });
    const handleaddclick = (day) => {
        switch (day) {
            case "sun":
                formik.setFieldValue("sunday_open_house", [...formik.values.sunday_open_house, { start_time: '', end_time: '' }]);
                break;
            case "mon":
                formik.setFieldValue("monday_open_house", [...formik.values.monday_open_house,
                { start_time: '', end_time: '' }]);
                break;
            case "tue":
                formik.setFieldValue("tuesday_open_house", [...formik.values.tuesday_open_house,
                { start_time: '', end_time: '' }]);

                break;
            case "wed":
                formik.setFieldValue("wednesday_open_house", [...formik.values.wednesday_open_house,
                { start_time: '', end_time: '' }]);
                break;
            case "thu":
                formik.setFieldValue("thursday_open_house", [...formik.values.thursday_open_house,
                { start_time: '', end_time: '' }]);
                break;
            case "fri":
                formik.setFieldValue("friday_open_house", [...formik.values.friday_open_house,
                { start_time: '', end_time: '' }]);
                break;
            case "sat":
                formik.setFieldValue("saturday_open_house", [...formik.values.saturday_open_house,
                { start_time: '', end_time: '' }]);
                break;
            default: console.log("object")
        }

    }
    const handleremove = (currentObj, day) => {
        switch (day) {
            case "sun":
                {
                    let filterTime = formik.values.sunday_open_house.filter(x => x.start_time !== currentObj.start_time && x.end_time !== currentObj.end_time)
                    formik.setFieldValue("sunday_open_house", filterTime.length ? filterTime : []);
                }
                break;
            case "mon":
                {
                    let filterTime = formik.values.monday_open_house.filter(x => x.start_time !== currentObj.start_time && x.end_time !== currentObj.end_time)
                    formik.setFieldValue("monday_open_house", filterTime.length ? filterTime : []);
                }
                break;
            case "tue":
                {
                    let filterTime = formik.values.tuesday_open_house.filter(x => x.start_time !== currentObj.start_time && x.end_time !== currentObj.end_time)
                    formik.setFieldValue("tuesday_open_house", filterTime.length ? filterTime : []);
                }

                break;
            case "wed":
                {
                    let filterTime = formik.values.wednesday_open_house.filter(x => x.start_time !== currentObj.start_time && x.end_time !== currentObj.end_time)
                    formik.setFieldValue("wednesday_open_house", filterTime.length ? filterTime : []);
                }
                break;
            case "thu":
                {
                    let filterTime = formik.values.thursday_open_house.filter(x => x.start_time !== currentObj.start_time && x.end_time !== currentObj.end_time)
                    formik.setFieldValue("thursday_open_house", filterTime.length ? filterTime : []);
                }
                break;
            case "fri":
                {
                    let filterTime = formik.values.friday_open_house.filter(x => x.start_time !== currentObj.start_time && x.end_time !== currentObj.end_time)
                    formik.setFieldValue("friday_open_house", filterTime.length ? filterTime : []);
                }
                break;
            case "sat":
                {
                    let filterTime = formik.values.saturday_open_house.filter(x => x.start_time !== currentObj.start_time && x.end_time !== currentObj.end_time)
                    formik.setFieldValue("saturday_open_house", filterTime.length ? filterTime : []);
                }
                break;
            default: alert("select day")
        }
    }

    useEffect(() => {
        formik.setFieldValue("sunday_open_house", openHouseData.Sunday ? openHouseData.Sunday : [])
        formik.setFieldValue("monday_open_house", openHouseData.Monday ? openHouseData.Monday : [])
        formik.setFieldValue("tuesday_open_house", openHouseData.Tuesday ? openHouseData.Tuesday : [])
        formik.setFieldValue("wednesday_open_house", openHouseData.Wednesday ? openHouseData.Wednesday : [])
        formik.setFieldValue("thursday_open_house", openHouseData.Thursday ? openHouseData.Thursday : [])
        formik.setFieldValue("friday_open_house", openHouseData.Friday ? openHouseData.Friday : [])
        formik.setFieldValue("saturday_open_house", openHouseData.Saturday ? openHouseData.Saturday : [])

        // return () => {
        //     console.log(" cancled")
        // }
    }, [])


    return (
        <>
            <Modal size="lg" show={openHouseModal} centered aria-labelledby="contained-modal-title-vcenter"
                backdrop="static" keyboard={false}
                onHide={() => setOpenHouseModal(false)}
            >
                <Modal.Header className="bsModal_header" closeButton> <span className=' text-center w-100 heading'>Add Open House Timing</span> </Modal.Header >
                <Modal.Body className="openHouseBody minute_pagpading">
                    <div className=' d-flex gap-2 justify-content-between'>
                        {AllDays.map((oneday, index) =>
                            <button type="" id={`actinel${index}`}
                                className={`btn blue__btn text-capitalize ${currentDay === oneday.day ? "activeDay" : ""}`}
                                key={`actine${oneday.day}`}
                                onClick={() => { setCurrentDay(oneday.day) }}
                            > {oneday.label}</button>
                        )}
                    </div>

                    <div className=" mt-3">
                        <>
                            {currentDay === "sun" &&
                                formik.values.sunday_open_house?.map((time, index) =>
                                    <SlotBook handleChange={formik.handleChange}
                                        key={`sunday_open_house${index}`}
                                        uniquName={`sunday_open_house.${index}`} time={time}
                                        last={formik.values.sunday_open_house.length - 1 === index}
                                        error={formik.errors.sunday_open_house?.[index]}
                                        handleaddclick={handleaddclick} day="sun" index={index} handleremove={handleremove}
                                    />
                                )
                            }
                            {(formik.values.sunday_open_house?.length === 0 && currentDay === "sun")
                                && <div className="center">
                                    <button className="save_btn" onClick={() => handleaddclick("sun")} >Add time Slot</button>
                                </div>
                            }
                        </>
                        <>
                            {currentDay === "mon" &&
                                formik.values.monday_open_house?.map((time, index) =>
                                    <SlotBook
                                        key={`monday_open_house${index}`}
                                        handleChange={formik.handleChange}
                                        uniquName={`monday_open_house.${index}`} time={time}
                                        handleaddclick={handleaddclick} day="mon" index={index}
                                        last={formik.values.monday_open_house.length - 1 === index}
                                        error={formik.errors.monday_open_house?.[index]}
                                        handleremove={handleremove}
                                    />
                                )
                            }
                            {(formik.values.monday_open_house?.length === 0 && currentDay === "mon")
                                && <div className="center">
                                    <button className="save_btn" onClick={() => handleaddclick("mon")} >Add time Slot</button>
                                </div>
                            }
                        </>
                        <>
                            {currentDay === "tue" &&
                                formik.values.tuesday_open_house?.map((time, index) =>
                                    <SlotBook handleChange={formik.handleChange} key={`tuesday_open_house${index}`}
                                        uniquName={`tuesday_open_house.${index}`} time={time} handleaddclick={handleaddclick} day="tue"
                                        last={formik.values.tuesday_open_house.length - 1 === index}
                                        error={formik.errors.tuesday_open_house?.[index]}
                                        index={index} handleremove={handleremove} />
                                )
                            }
                            {(formik.values.tuesday_open_house?.length === 0 && currentDay === "tue")
                                && <div className="center">
                                    <button className="save_btn" onClick={() => handleaddclick("tue")} >Add time Slot</button>
                                </div>
                            }

                        </>
                        <>
                            {currentDay === "wed" &&
                                formik.values.wednesday_open_house?.map((time, index) =>
                                    <SlotBook handleChange={formik.handleChange} key={`wednesday_open_house${index}`}
                                        uniquName={`wednesday_open_house.${index}`} time={time}
                                        handleaddclick={handleaddclick} day="wed" index={index}
                                        last={formik.values.wednesday_open_house.length - 1 === index}
                                        error={formik.errors.wednesday_open_house?.[index]}
                                        handleremove={handleremove} />
                                )
                            }
                            {(formik.values.wednesday_open_house?.length === 0 && currentDay === "wed")
                                && <div className="center">
                                    <button className="save_btn" onClick={() => handleaddclick("wed")} >Add time Slot</button>
                                </div>
                            }
                        </>
                        <>
                            {currentDay === "thu" &&
                                formik.values.thursday_open_house?.map((time, index) =>
                                    <SlotBook handleChange={formik.handleChange} key={`thursday_open_house${index}`}
                                        uniquName={`thursday_open_house.${index}`} time={time} handleaddclick={handleaddclick} day="thu"
                                        last={formik.values.thursday_open_house.length - 1 === index}
                                        error={formik.errors.thursday_open_house?.[index]}
                                        index={index} handleremove={handleremove} />
                                )
                            }
                            {(formik.values.thursday_open_house?.length === 0 && currentDay === "thu")
                                && <div className="center">
                                    <button className="save_btn" onClick={() => handleaddclick("thu")} >Add time Slot</button>
                                </div>
                            }
                        </>
                        <>
                            {currentDay === "fri" &&
                                formik.values.friday_open_house?.map((time, index) =>
                                    <SlotBook handleChange={formik.handleChange} key={`friday_open_house${index}`}
                                        uniquName={`friday_open_house.${index}`} time={time} handleaddclick={handleaddclick} day="fri"
                                        last={formik.values.friday_open_house.length - 1 === index}
                                        error={formik.errors.friday_open_house?.[index]}
                                        index={index} handleremove={handleremove} />
                                )
                            }
                            {(formik.values.friday_open_house?.length === 0 && currentDay === "fri")
                                && <div className="center">
                                    <button className="save_btn" onClick={() => handleaddclick("fri")} >Add time Slot</button>
                                </div>
                            }
                        </>
                        <>
                            {currentDay === "sat" &&
                                formik.values.saturday_open_house?.map((time, index) =>
                                    <SlotBook handleChange={formik.handleChange} key={`saturday_open_house${index}`}
                                        uniquName={`saturday_open_house.${index}`} time={time}
                                        handleaddclick={handleaddclick} day="sat"
                                        last={formik.values.saturday_open_house.length - 1 === index}
                                        error={formik.errors.saturday_open_house?.[index]}
                                        index={index} handleremove={handleremove} />
                                )
                            }
                            {(formik.values.saturday_open_house?.length === 0 && currentDay === "sat")
                                && <div className="center">
                                    <button className="save_btn" onClick={() => handleaddclick("sat")} >Add time Slot</button>
                                </div>
                            }
                        </>

                        <div className="col-sm-12 d-flex justify-content-end media_pading flex-column align-items-end">
                            {!(Object.keys(formik?.errors)?.length === 0) &&
                                <span className='validation-text text-center '>Error occurs in{" "}
                                    {Object.keys(formik?.errors).map((key) => <span key={key}>{`${key.split("_")[0]} `}</span>)}
                                    time
                                </span>}

                            <button className="save_btn mt-1" type="submit"
                                onClick={formik.handleSubmit}
                                disabled={checkEmptyArrays(formik.values)}
                            > Submit </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OPenHouseTimeModal